// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import invariant from 'invariant';

import type {
  GetClassTasks,
  GetClassTasks_me_account_class_subject as InputSubject,
  GetClassTasks_me_account_class_tasks_edges_node as InputTask,
  GetClassTasksVariables,
} from 'src/graphql/types/generated/GetClassTasks';
import { getTaskUrl } from 'src/utils/routes';
import type { DateString } from 'src/types';

import type { TaskAttachment } from '../types';
import {
  attachmentTransformer,
  contentTransformer,
  type ClassTaskContent,
  type TaskStatus,
} from './classTasksTransformer';
import GET_CLASS_TASKS from './GetClassTasks.graphql';

export type { ClassTaskContent, TaskStatus };

type Props = {|
  subscriptionId: string,
  updatePageLocation: (query: { after?: string, before?: string }) => void,
  variables: GetClassTasksVariables,
|};

export type CurrentClass = {|
  id: string,
  name: string,
  studentCount: number,
  subject: {
    code: string,
    color: string,
    groupCode: string,
    levels: $ReadOnlyArray<string>,
  },
|};

export type ClassTask = {|
  attachments: $ReadOnlyArray<TaskAttachment>,
  autoComplete: boolean,
  body: ?string,
  contents: $ReadOnlyArray<ClassTaskContent>,
  countComplete: number,
  countInProgress: number,
  countStudents: number,
  dueDate: string,
  id: string,
  scheduledFor: ?DateString,
  status: TaskStatus,
  studentIds: $ReadOnlyArray<string>,
  subject: {
    color: string,
    groupCode: string,
  },
  title: string,
  url: string,
|};

export type TaskCounts = {|
  draft: number,
  published: number,
  scheduled: number,
|};

type Output = {|
  data: ?{|
    class: CurrentClass,
    counts: TaskCounts,
    isFreePlan: boolean,
    region: string,
    tasks: $ReadOnlyArray<ClassTask>,
  |},
  error: ?ApolloError,
  goToNextPage?: () => void,
  goToPreviousPage?: () => void,
  loading: boolean,
  refetch: () => mixed,
|};

function classTaskTransformer({
  classId,
  subject,
  subscriptionId,
  task,
}: {
  classId: string,
  subject: InputSubject,
  subscriptionId: string,
  task: InputTask,
}): ClassTask {
  return {
    attachments: task.attachments.map(attachmentTransformer),
    autoComplete: task.isAutocomplete,
    body: task.body,
    contents: task.contents
      .filter(({ __typename }) => !__typename.includes('Locked'))
      .map((content) =>
        contentTransformer({
          content,
          countAssignedStudents: task.students.length,
          subscriptionId,
          taskId: task.id,
        })
      ),
    countStudents: task.progress.total,
    countComplete: task.progress.completed,
    countInProgress: task.progress.inProgress,
    dueDate: task.dueAt,
    id: task.id,
    scheduledFor: task.scheduledFor,
    status: task.status,
    studentIds: task.students.map((student) => student.id),
    subject: {
      color: subject.color,
      groupCode: subject.groupCode,
    },
    title: task.title || 'Untitled',
    url: getTaskUrl(subscriptionId, classId, task.id),
  };
}

export default function useGetClassTasks(props: Props): Output {
  const { subscriptionId, updatePageLocation, variables } = props;

  const { data, loading, error, refetch } = useQuery<GetClassTasks, GetClassTasksVariables>(GET_CLASS_TASKS, {
    variables,
    refetchWritePolicy: 'merge', // don't invalidate the apollo cache when refetching data
  });

  if (error) {
    return {
      data: null,
      error,
      loading: false,
      refetch,
    };
  }

  if (loading && !data) {
    return {
      data: null,
      error: null,
      loading: true,
      refetch,
    };
  }

  const accountData = data?.me?.account;
  const currentClass = accountData?.class;

  invariant(accountData && currentClass, 'useGetClassTasks: Expected class data to be present');

  const { endCursor, hasNextPage, hasPreviousPage, startCursor } = currentClass.tasks.pageInfo;
  const goToNextPage =
    hasNextPage && endCursor ? () => updatePageLocation({ after: endCursor, before: undefined }) : undefined;
  const goToPreviousPage =
    hasPreviousPage && startCursor ? () => updatePageLocation({ after: undefined, before: startCursor }) : undefined;

  invariant(currentClass.metrics.__typename === 'ClassMetrics', 'useGetClassTasks: Expected metrics to be present');
  const taskMetrics = currentClass.metrics.tasks;

  return {
    data: {
      class: {
        id: currentClass.id,
        name: currentClass.name,
        studentCount: currentClass.students.length,
        subject: {
          code: currentClass.subject.code,
          color: currentClass.subject.color,
          groupCode: currentClass.subject.groupCode,
          levels: currentClass.subject.levels.map(({ id }) => id),
        },
      },
      isFreePlan: accountData.plan.isFree,
      region: accountData.region.code,
      tasks: currentClass.tasks.edges.map(({ node }) =>
        classTaskTransformer({
          subscriptionId,
          classId: currentClass.id,
          subject: currentClass.subject,
          task: node,
        })
      ),
      counts: {
        draft: taskMetrics.draftCount,
        published: taskMetrics.publishedCount,
        scheduled: taskMetrics.scheduledCount,
      },
    },
    error: null,
    goToNextPage,
    goToPreviousPage,
    loading: false,
    refetch,
  };
}
