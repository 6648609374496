// @flow
import { connect } from 'react-redux';
import { Box, Container, Modal, Heading, Stack, Text, Divider, useId, useToast } from '@getatomi/neon';

import type { ReduxState, BoundAction } from 'src/types';
import Logger from 'src/utils/Logger';
import CardDetails from 'src/components/CardDetails/CardDetails';
import CardTerms from 'src/components/CardDetails/CardTerms/CardTerms';
import ModalActions from 'src/components/deprecated/ModalActions/ModalActions';
import { loadCurrentPlanRenewalEstimate } from 'src/actions/estimates';
import {
  clearReactivateSubscriptionError,
  createPaymentIntent,
  reactivateSubscription,
  setReactivateSubscriptionStatus,
} from 'src/actions/subscriptions';
import { getRenewalEstimate, isLoadingRenewalEstimate } from 'src/reducers/estimates';
import { getPrimaryPaymentSource, isLoadingPaymentSources } from 'src/reducers/paymentSources';
import { getActiveSubscriptionPlan, getPlanPaymentFrequency } from 'src/reducers/plans';
import {
  getReactivateSubscriptionError,
  isFamilyPlan as isFamilyPlanSelector,
  isLoggedInAsParent as isLoggedInAsParentSelector,
  isReactivatingSubscription as isReactivatingSubscriptionSelector,
} from 'src/reducers/subscriptions';
import PaymentSourceForm from 'src/components/PaymentSourceForm/PaymentSourceForm';

import ActiveUserPaymentDescription from '../ActiveUserPaymentDescription/ActiveUserPaymentDescription';

const log = new Logger('domains/Settings/Billing/TurnOnAutoRenewalDialog');

const mapStateToProps = (state: ReduxState) => {
  const plan = getActiveSubscriptionPlan(state);

  return {
    currency: plan.pricing.currency,
    estimate: getRenewalEstimate(state),
    paymentSource: getPrimaryPaymentSource(state),
    isLoggedInAsParent: isLoggedInAsParentSelector(state),
    isFamilyPlan: isFamilyPlanSelector(state),
    isReactivatingSubscription:
      isReactivatingSubscriptionSelector(state) || isLoadingRenewalEstimate(state) || isLoadingPaymentSources(state),
    paymentFrequency: getPlanPaymentFrequency(state),
    reactivateSubscriptionError: getReactivateSubscriptionError(state),
    taxCode: plan.tax_code,
  };
};

type Props = {|
  isVisible: boolean,
  onClose: () => mixed,
  taxCode?: string,
|};

type InjectedProps = Props & {
  ...$Call<typeof mapStateToProps, ReduxState>,
  clearReactivateSubscriptionErrorAction: BoundAction<typeof clearReactivateSubscriptionError>,
  createPaymentIntentAction: BoundAction<typeof createPaymentIntent>,
  isFamilyPlan: boolean,
  isLoggedInAsParent: boolean,
  loadCurrentPlanRenewalEstimateAction: BoundAction<typeof loadCurrentPlanRenewalEstimate>,
  reactivateSubscriptionAction: BoundAction<typeof reactivateSubscription>,
  setReactivateSubscriptionStatusAction: BoundAction<typeof setReactivateSubscriptionStatus>,
};

function TurnOnAutoRenewalDialog(props: InjectedProps) {
  const {
    clearReactivateSubscriptionErrorAction,
    createPaymentIntentAction,
    currency,
    estimate,
    isLoggedInAsParent,
    isFamilyPlan,
    isVisible,
    isReactivatingSubscription,
    loadCurrentPlanRenewalEstimateAction,
    onClose,
    paymentFrequency,
    paymentSource,
    reactivateSubscriptionAction,
    reactivateSubscriptionError,
    setReactivateSubscriptionStatusAction,
    taxCode,
  } = props;
  const toast = useToast();

  const headingId = useId();

  const onCloseHandler = () => {
    if (reactivateSubscriptionError) {
      clearReactivateSubscriptionErrorAction();
    }
    onClose();
  };

  const onPaymentSuccess = async (paymentIntentId?: string) => {
    try {
      await reactivateSubscriptionAction(paymentIntentId);
      loadCurrentPlanRenewalEstimateAction();
      onCloseHandler();
    } catch (error) {
      log.error('Form submission: turn on automatic renewal failed', error);
      if (!error.output) {
        onCloseHandler();
        toast.error('There was an error turning on automatic-renewal. Please try again.');
      }
    }
  };

  if (!currency || !estimate) return null;

  const submitButtonLabel = 'I understand, turn it on';
  const PaymentForm = paymentSource ? PaymentSourceForm : CardDetails;
  const formProps =
    PaymentForm === PaymentSourceForm
      ? { paymentSource, hidePaymentSourceSelection: true, shouldCreateIntent: () => estimate.amount_due > 0 }
      : {};

  return (
    <Modal ariaLabelledBy={headingId} size="fullscreen" variant="view" onClose={onClose} isOpen={isVisible}>
      <Stack spacing="spacingLarge3X">
        <Container maxWidth="sizeContainerRoot" textAlign="center">
          <Stack spacing="spacingLarge">
            <Heading as="h1" id={headingId}>
              Turn on automatic-renewal
            </Heading>
            <Text variant="lead" as="p">
              By turning on automatic renewal your Unlimited {isFamilyPlan && 'Family '}
              {paymentFrequency === 'year' ? 'Yearly ' : 'Monthly '}
              subscription will continue and you will have full access to Atomi.
            </Text>
          </Stack>
        </Container>
        <Container maxWidth="sizeContainerRoot" textAlign="center">
          <Box marginBottom="spacingLarge5X">
            <Divider />
          </Box>
          <Box marginBottom="spacingLarge5X">
            <ActiveUserPaymentDescription currency={currency} estimate={estimate} frequency={paymentFrequency} />
          </Box>

          <Box marginBottom="spacingLarge">
            <PaymentForm
              {...formProps}
              error={reactivateSubscriptionError}
              createIntent={(paymentMethodId, other) =>
                createPaymentIntentAction({
                  paymentMethodId,
                  amount: estimate.amount_due,
                  currencyCode: currency,
                  ...other,
                })
              }
              onRequest={() => setReactivateSubscriptionStatusAction(true)}
              onError={() => setReactivateSubscriptionStatusAction(false)}
              onSuccess={onPaymentSuccess}
            >
              <Box marginTop="spacingLarge">
                <ModalActions
                  cancelLabel="No, thanks"
                  isLoading={isReactivatingSubscription}
                  onCancel={onCloseHandler}
                  submitLabel={submitButtonLabel}
                  submitVariant="success"
                  testHook="turn-on-auto-renewal-modal-actions"
                />
              </Box>
            </PaymentForm>
          </Box>
          <CardTerms
            isLoggedInAsParent={isLoggedInAsParent}
            currency={currency}
            taxCode={taxCode}
            buttonLabel={submitButtonLabel}
          />
        </Container>
      </Stack>
    </Modal>
  );
}

export default (connect(mapStateToProps, {
  clearReactivateSubscriptionErrorAction: clearReactivateSubscriptionError,
  loadCurrentPlanRenewalEstimateAction: loadCurrentPlanRenewalEstimate,
  createPaymentIntentAction: createPaymentIntent,
  reactivateSubscriptionAction: reactivateSubscription,
  setReactivateSubscriptionStatusAction: setReactivateSubscriptionStatus,
})(TurnOnAutoRenewalDialog): React.AbstractComponent<Props>);
