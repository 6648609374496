// @flow
import { useQuery, ApolloError } from '@apollo/client';
import invariant from 'invariant';

import type {
  GetRevision,
  GetRevisionVariables,
  GetRevision_me_account_class as GqlClass,
} from 'src/graphql/types/generated/GetRevision';

import GET_REVISION from './GetRevision.graphql';

export type Level = {|
  id: string,
  name: string,
|};

export type Subject = {|
  code: string,
  color: string,
  groupCode: string,
  levels: $ReadOnlyArray<Level>,
  name: string,
  shortName: string,
|};

export type UserClass = {|
  id: string,
  levelId: string | null,
  name: string,
  subject: Subject,
|};

export function transformUserClass(gqlClass: GqlClass): UserClass {
  const { id, level, name, subject } = gqlClass;
  const { code, color, groupCode, name: subjectName, shortName } = subject;

  const levelId = level?.id ?? null;

  const levels: Level[] = subject.levels.map((subjectLevel) => ({
    id: subjectLevel.id,
    name: subjectLevel.name,
  }));

  const userClass: UserClass = {
    id,
    levelId,
    name,
    subject: {
      code,
      color,
      groupCode,
      levels,
      shortName,
      name: subjectName,
    },
  };

  return userClass;
}

export type UseGetRevisionArgs = {|
  accountId: string,
  classId: string,
  isStudent: boolean,
  revisionId: string,
  taskId?: string,
|};

export type Task = { id: string, title: ?string } | null;

export type RevisionData = {
  duration: number,
  isChallengeInProgress: boolean,
  isChallengeResumeEnabled: boolean,
  isStudentRevision: boolean,
  questionsCount: number,
  task: Task,
  timeLimitInSeconds?: number,
  title: string,
  totalMarks: number,
  userClass: UserClass,
};

export type UseGetRevisionResult = {
  data: RevisionData | null,
  error: ApolloError | null,
  loading: boolean,
};

export function useGetRevision({
  accountId,
  classId,
  isStudent,
  revisionId,
  taskId,
}: UseGetRevisionArgs): UseGetRevisionResult {
  const { data, loading, error } = useQuery<GetRevision, GetRevisionVariables>(GET_REVISION, {
    variables: {
      accountId,
      classId,
      includeTaskForNonStudent: !isStudent && Boolean(taskId),
      includeTaskForStudent: isStudent && Boolean(taskId),
      revisionId,
      taskIds: taskId ? [taskId] : [],
    },
  });

  if (loading && !data) {
    const loadingResult: UseGetRevisionResult = {
      data: null,
      error: null,
      loading: true,
    };
    return loadingResult;
  }

  if (error) {
    const errorResult: UseGetRevisionResult = {
      data: null,
      error,
      loading: false,
    };
    return errorResult;
  }

  const gqlAccount = data?.me?.account;

  invariant(gqlAccount, 'Account data should be defined');

  const gqlClass = gqlAccount.class;

  invariant(gqlClass, 'Class data should be defined');

  const gqlRevision = gqlClass.revision;

  invariant(gqlRevision, 'Revision data should be defined');

  const taskData = isStudent ? gqlAccount.tasks?.edges[0]?.node : gqlClass.tasks?.edges[0]?.node;

  invariant(taskId ? taskData : true, 'Task data should be defined');

  const { title, duration } = gqlRevision;

  const userClass = transformUserClass(gqlClass);

  const { questionsCount, timeLimitInSeconds, totalMarks } = gqlRevision;

  const isChallengeResumeEnabled = gqlAccount.enabledFeatures.includes('CHALLENGE_RESUME');

  const assessmentSession = gqlClass?.assessmentSession;
  const isChallengeInProgress = Boolean(assessmentSession);
  // If we don't have a taskId, we are viewing a student-created revision.
  const isStudentRevision = isStudent && !taskId;

  const revisionData: RevisionData = {
    duration,
    isChallengeInProgress,
    isChallengeResumeEnabled,
    isStudentRevision,
    title,
    userClass,
    questionsCount,
    timeLimitInSeconds: timeLimitInSeconds ?? undefined,
    totalMarks,
    task: taskData ? { id: taskData.id, title: taskData.title } : null,
  };

  const result: UseGetRevisionResult = {
    loading: false,
    error: null,
    data: revisionData,
  };

  return result;
}
