// @flow
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import type { ContextRouter } from 'react-router';
import { Box, Container } from '@getatomi/neon';
import invariant from 'invariant';

import postTypes from 'src/constants/postTypes';
import { getPostUrl as getPostUrlImported, getLtiClassModuleLessonUrl } from 'src/utils/routes';
import type { CompleteOnboardingItemCallback } from 'src/domains/Onboarding/useOnboardingItems/useOnboardingItems';
import { subscribeToClassProgress } from 'src/utils/pusher';

import PostActions from '../PostActions/PostActions';
import PostPagination from '../PostPagination/PostPagination';
import EmbedButtonPortal from '../EmbedButtonPortal/EmbedButtonPortal';
import type { Lesson, CurrentClass } from '../useGetModuleLesson';
import TextLesson from './TextLesson';
import VideoLesson from './VideoLesson';
import ChallengeLesson from './ChallengeLesson';
import CrossSubjectAlert from './CrossSubjectAlert';

type LessonContainerProps = {|
  completeOnboardingItem: CompleteOnboardingItemCallback | null,
  currentClass: CurrentClass,
  hasResultsSplat: boolean,
  isChallengeInProgress: boolean,
  isChallengeResumeEnabled: boolean,
  isCrossSubject: boolean,
  isFreePlan: boolean,
  isLoggedInAsChild: boolean,
  isLoggedInAsStudent: boolean,
  isLti?: boolean,
  isOnboardingAvailable?: boolean,
  isPostEmbedded?: boolean,
  isSchoolPlan: boolean,
  lesson: Lesson,
  moduleId: string,
  nextLesson: ?Lesson,
  previousLesson: ?Lesson,
  refetch: () => Promise<void>,
  region: string,
  router: ContextRouter,
  showNavigation: boolean,
  subscriptionId: string,
  taskId?: string,
|};

export default function LessonContainer(props: LessonContainerProps) {
  const {
    lesson,
    isCrossSubject,
    refetch,
    region,
    nextLesson,
    previousLesson,
    currentClass,
    hasResultsSplat,
    isFreePlan,
    isSchoolPlan,
    isLoggedInAsChild,
    isLoggedInAsStudent,
    moduleId,
    router,
    showNavigation,
    isLti = false,
    isPostEmbedded = false,
    isChallengeResumeEnabled,
    completeOnboardingItem,
    isChallengeInProgress,
    isOnboardingAvailable = false,
    subscriptionId,
    taskId,
  } = props;

  const isTextPost = lesson.type === postTypes.text;
  const isVideoPost = lesson.type === postTypes.video;
  const isChallenge = lesson.type === postTypes.challenge;

  const showPostActions = !isPostEmbedded && !isLti && !isLoggedInAsStudent;

  const getSiblingLessonUrl = (targetLesson: Lesson): string => {
    if (isLti) {
      return getLtiClassModuleLessonUrl(subscriptionId, currentClass.id, moduleId, targetLesson.id);
    }
    return getPostUrlImported(subscriptionId, currentClass.id, moduleId, targetLesson.id);
  };

  const onShareLesson = (
    onShareLessonParams?: {| shouldPersistOnFullCompletion: boolean |} = { shouldPersistOnFullCompletion: false }
  ) => {
    const { shouldPersistOnFullCompletion } = onShareLessonParams;

    if (isOnboardingAvailable) {
      completeOnboardingItem?.({ itemToComplete: 'SHARE_LESSON', shouldPersistOnFullCompletion });
    }
  };

  useEffect(() => {
    // Subscribe to class progress updates so the completion count in PostActions is kept up to date
    if (!isLoggedInAsStudent) {
      return subscribeToClassProgress(currentClass.id, () => refetch());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isOnboardingAvailable) {
      completeOnboardingItem?.({ itemToComplete: 'DISCOVER_LESSON' });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const actions = showPostActions ? (
    <>
      {isCrossSubject &&
        !isLoggedInAsStudent &&
        (isChallenge ? (
          <Container maxWidth="sizeContainerSmall" marginBottom="spacingRoot">
            <CrossSubjectAlert />
          </Container>
        ) : (
          <Box marginBottom="spacingRoot">
            <CrossSubjectAlert />
          </Box>
        ))}

      <PostActions
        onShareLesson={onShareLesson}
        isCrossSubject={isCrossSubject}
        isFreePlan={isFreePlan}
        subscriptionId={subscriptionId}
        currentClass={currentClass}
        moduleId={moduleId}
        lesson={lesson}
        progressCount={lesson.completionCount}
        onSubmitTask={() => refetch()}
        onViewResponses={(url) => router.push(url)}
        onViewTasks={(url) => router.push(url)}
        region={region}
        taskId={taskId}
      />
    </>
  ) : null;

  const pagination = showNavigation ? (
    <PostPagination
      getLessonUrl={getSiblingLessonUrl}
      isInline={isVideoPost}
      nextLesson={nextLesson}
      prevLesson={previousLesson}
      upgradePromptProps={{ isSchoolPlan, isLoggedInAsChild, subscriptionId }}
      testHook="post-pagination"
    />
  ) : null;

  // Moodle doesn't respect the max-width of our embed iframe, so we need to limit the size of
  // the container to make things fit.
  const embedWidth = 700;
  const maximumContainerSize = isPostEmbedded ? embedWidth : 'sizeContainerRoot';

  let content: React.Node = null;
  if (isTextPost) {
    content = (
      <TextLesson
        actions={actions}
        currentClass={currentClass}
        isLoggedInAsStudent={isLoggedInAsStudent}
        lesson={lesson}
        maximumContainerSize={maximumContainerSize}
        pagination={pagination}
        subscriptionId={subscriptionId}
      />
    );
  } else if (isVideoPost) {
    content = (
      <VideoLesson
        actions={actions}
        currentClass={currentClass}
        embedWidth={embedWidth}
        isLti={isLti}
        isPostEmbedded={isPostEmbedded}
        lesson={lesson}
        maximumContainerSize={maximumContainerSize}
        pagination={pagination}
        subscriptionId={subscriptionId}
      />
    );
  } else if (isChallenge) {
    content = (
      <ChallengeLesson
        actions={actions}
        classId={currentClass.id}
        getSiblingLessonUrl={getSiblingLessonUrl}
        hasResultsSplat={hasResultsSplat}
        isChallengeInProgress={isChallengeInProgress}
        isChallengeResumeEnabled={isChallengeResumeEnabled}
        isLoggedInAsChild={isLoggedInAsChild}
        isLoggedInAsStudent={isLoggedInAsStudent}
        isLti={isLti}
        isPostEmbedded={isPostEmbedded}
        isSchoolPlan={isSchoolPlan}
        lesson={lesson}
        moduleId={moduleId}
        taskId={taskId}
        nextLesson={nextLesson}
        pagination={pagination}
        subscriptionId={subscriptionId}
      />
    );
  }

  invariant(content, `Unknown lesson type: ${lesson.type}`);

  return (
    <>
      <Helmet>
        <title>{lesson.name}</title>
      </Helmet>

      {content}

      {isLti && <EmbedButtonPortal postId={lesson.id} postName={lesson.name} />}
    </>
  );
}
