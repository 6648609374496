// @flow
import invariant from 'invariant';
import { useQuery, ApolloError } from '@apollo/client';

import type {
  GetChallengeOverview,
  GetChallengeOverviewVariables,
} from 'src/graphql/types/generated/GetChallengeOverview';
import type { ChallengeAverageMark } from 'src/domains/ViewingQuizResults/Overview/AverageMarkMetric/AverageMarkMetric';
import type { ChallengeTier } from 'src/types';
import type { ChallengingQuestion, OverviewStudent } from 'src/domains/ViewingQuizResults/types';

import GET_CHALLENGE_OVERVIEW from './GetChallengeOverview.graphql';
import { getChallengeQuestionUrlFactory } from './utilities/getQuestionUrlFactory';
import { prepareAttempts, prepareStudents } from './utilities/prepareOverviewData/prepareOverviewData';
import calculateChallengingQuestions from './utilities/prepareOverviewData/calculateChallengingQuestions';
import calculateMarkDeltas from './utilities/prepareOverviewData/calculateMarkDeltas';

type Props = {|
  accountId: string,
  classId: string,
  lessonId: string,
  taskId?: string,
|};

export type PreparedOverviewData = {|
  challengingQuestions: $ReadOnlyArray<ChallengingQuestion>,
  classData: {|
    id: string,
    name: string,
    subject: {|
      color: string,
      groupCode: string,
      name: string,
    |},
  |},
  lesson: {|
    averageMark: ChallengeAverageMark,
    completionRate: {|
      total: number,
      value: number,
    |},
    moduleName: string,
    name: string,
    tier: ChallengeTier,
  |},
  students: $ReadOnlyArray<OverviewStudent>,
  task: {|
    id: string,
    title: string,
  |} | null,
|};

type Output = {|
  data: ?PreparedOverviewData,
  error: ?ApolloError,
  loading: boolean,
|};

export function useGetChallengeOverview(props: Props): Output {
  const { accountId, classId, lessonId, taskId } = props;

  const { data, loading, error } = useQuery<GetChallengeOverview, GetChallengeOverviewVariables>(
    GET_CHALLENGE_OVERVIEW,
    {
      variables: {
        accountId,
        classId,
        lessonId,
        includeTask: Boolean(taskId),
        taskIds: taskId ? [taskId] : [],
      },
    }
  );

  if (error || (loading && !data)) {
    return { data: undefined, error, loading };
  }

  invariant(data, 'Query data should be defined');

  const classData = data.me?.account?.class;
  invariant(
    classData && classData.lesson && classData.lesson.__typename === 'ClassChallengeLesson',
    'Class challenge lesson should be defined'
  );
  const { lesson, subject } = classData;

  const taskData = classData.tasks?.edges[0]?.node;
  invariant(taskId ? taskData : true, 'Task data should be defined');

  const students = prepareStudents(classData.students, lesson.metrics.assessment.students);
  const attempts = prepareAttempts(lesson.latestAttempts);

  const getChallengeQuestionUrl = getChallengeQuestionUrlFactory({
    accountId,
    classId,
    moduleId: lesson.categories[0].id,
    postId: lessonId,
    returnTo: `${window.location.pathname}${window.location.search}`,
  });

  return {
    data: {
      challengingQuestions: calculateChallengingQuestions(lesson.challenge.items, attempts, getChallengeQuestionUrl),
      classData: {
        id: classData.id,
        name: classData.name,
        subject: {
          color: subject.color,
          groupCode: subject.groupCode,
          name: subject.name,
        },
      },
      lesson: {
        name: lesson.name,
        tier: lesson.tier,
        moduleName: lesson.categories[0].name,
        completionRate: {
          total: students.length,
          value: lesson.metrics.progress.engagedStudentCount,
        },
        averageMark: calculateMarkDeltas(lesson.metrics.assessment),
      },
      students,
      task: taskData ? { id: taskData.id, title: taskData.title ?? '' } : null,
    },
    loading,
    error,
  };
}
