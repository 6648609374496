// @flow
import { Card, Container, Flex, Heading, ProgressCircle, Text, useMediaQuery } from '@getatomi/neon';
import invariant from 'invariant';

import Button from 'src/components/Button/Button';
import { getGettingStartedUrl } from 'src/utils/routes';

import useOnboardingItems from './useOnboardingItems/useOnboardingItems';

type Props = {|
  accountId: string,
  isParentLoading: boolean,
|};

export default function OnboardingCallout(props: Props) {
  const { accountId, isParentLoading } = props;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const { data, loading, error } = useOnboardingItems({ accountId });

  if (isParentLoading || loading || error) {
    return null;
  }

  invariant(data, 'Onboarding callout data should be defined');

  const { countCompleted, countItems, items } = data;

  const nextActionableItem = items.find((item) => !item.isCompleted);
  if (!nextActionableItem) {
    return null;
  }

  const progressValue = countCompleted / countItems;
  const progressLabel = `${countCompleted} of ${countItems} completed`;

  return (
    <Container marginBottom={{ base: 'spacingLarge3X', tablet: 'spacingLarge5X' }}>
      <Card variant="callout" padding="spacingLarge1X" withHover={false}>
        <Flex alignItems={{ base: 'flex-start', tablet: 'center' }} gap="spacingRoot">
          <ProgressCircle as="meter" value={progressValue} size="small2X" />

          <Flex flex={1} justifyContent="space-between" alignItems="center" gap="spacingLarge1X">
            <Flex
              direction={{ base: 'column', tablet: 'row' }}
              gap={{ base: 'spacingSmall1X', tablet: 'spacingSmall' }}
            >
              <Heading as="h2" variant="medium">
                Getting started
              </Heading>
              <Heading as="p" variant="small">
                {nextActionableItem.title}
              </Heading>
            </Flex>

            <Flex alignItems={{ base: 'flex-start', tablet: 'center' }} gap="spacingLarge1X">
              <Text variant="bodySmall" color="colorTextSubtle" display={{ base: 'none', tablet: 'block' }}>
                {progressLabel}
              </Text>
              <Button to={getGettingStartedUrl(accountId)} variant="tertiary">
                {isMobile ? 'View' : 'View checklist'}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </Container>
  );
}
