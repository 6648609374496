// @flow
import type {
  GetStudentTask_me_account_tasks_edges_node_author as GetStudentTaskAuthor,
  GetStudentTask_me_account_tasks_edges_node_contents as GetStudentTaskLessons,
  GetStudentTask_me_account_tasks_edges_node_contents_StudentChallengeLesson_metrics as GetStudentChallengeLessonMetrics,
  GetStudentTask_me_account_tasks_edges_node_contents_StudentTextLesson_metrics as GetStudentTextLessonMetrics,
  GetStudentTask_me_account_tasks_edges_node_contents_StudentVideoLesson_metrics as GetStudentVideoLessonMetrics,
  GetStudentTask_me_account_tasks_edges_node_contents_StudentRevision_metrics as GetStudentRevisionMetrics,
} from 'src/graphql/types/generated/GetStudentTask';
import type { ChallengeTier, LessonProgress, PostType, UserAccountStatusType } from 'src/types';
import type { GetClassTask_me_account_class_tasks_edges_node_author as GetClassTaskAuthor } from 'src/graphql/types/generated/GetClassTask';
import { getRevisionUrl, getTaskLessonUrl } from 'src/utils/routes';
import userAccountStatuses from 'src/constants/userAccountStatuses';
import postTypes from 'src/constants/postTypes';

export type Author = {|
  accountStatus: UserAccountStatusType,
  avatar: ?string,
  color: number,
  email: string,
  firstName: ?string,
  lastName: ?string,
|};

export type TaskContent = {
  duration: number,
  id: string,
  moduleId?: string,
  name: string,
  progress?: ?LessonProgress,
  tier?: ChallengeTier,
  type: PostType,
  url: string,
  viewer: 'student',
};

type ExpectedClassLessonMetrics =
  | GetStudentChallengeLessonMetrics
  | GetStudentTextLessonMetrics
  | GetStudentVideoLessonMetrics
  | GetStudentRevisionMetrics;

export function prepareAuthorData(author: GetStudentTaskAuthor | GetClassTaskAuthor): Author {
  return {
    // We can't query `accountStatus` for authors, but it's required for Avatar.
    // We can assume that an author should always have an active status.
    accountStatus: userAccountStatuses.ACTIVE,
    avatar: author.avatar,
    color: author.color,
    email: author.email,
    firstName: author.firstName,
    lastName: author.lastName,
  };
}

export function prepareLessonProgressData(metrics: ?$ReadOnly<ExpectedClassLessonMetrics>): ?LessonProgress {
  if (!metrics) return null;

  // The PostStrength component directly accesses the `__typename` field and
  // expects it to be of value "Progress".
  switch (metrics.__typename) {
    case 'StudentChallengeLessonMetrics':
      if (metrics.assessment.mark === null) return null;
      return {
        __typename: 'Progress',
        strength: metrics.assessment.strength,
        mark: metrics.assessment.mark,
        classAverageMark: metrics.assessment?.classAverageMark,
        postAverageMark: metrics.assessment?.regionAverageMark,
        subscriptionAverageMark: metrics.assessment?.schoolAverageMark,
      };
    case 'StudentTextLessonMetrics':
    case 'StudentVideoLessonMetrics':
    case 'StudentRevisionMetrics':
      if (!metrics.progress.cumulativeCompletionCount) return null;
      return { __typename: 'Progress', completions: metrics.progress.cumulativeCompletionCount };
    default:
      return null;
  }
}

export function contentTransformer({
  classId,
  content,
  subscriptionId,
  taskId,
}: {|
  classId: string,
  content: GetStudentTaskLessons,
  subscriptionId: string,
  taskId: string,
|}): TaskContent {
  const transformedTypename = content.__typename.toLowerCase().replace('student', '').replace('lesson', '');
  const type: PostType = postTypes[transformedTypename];

  const formattedContent = {
    viewer: 'student',
    duration: content.duration,
    id: content.id,
    type,
  };

  switch (content.__typename) {
    case 'StudentRevision': {
      return {
        ...formattedContent,
        name: content.title,
        progress: prepareLessonProgressData(content.metrics),
        url: getRevisionUrl(subscriptionId, classId, content.id, taskId),
        duration: content.timeLimitInSeconds ? content.timeLimitInSeconds : formattedContent.duration,
      };
    }
    case 'StudentChallengeLesson': {
      const moduleId = content.categories[0]?.id;
      return {
        ...formattedContent,
        name: content.name,
        moduleId,
        progress: prepareLessonProgressData(content.metrics),
        tier: content.tier,
        url: getTaskLessonUrl(subscriptionId, classId, taskId, content.id),
      };
    }
    default: {
      const moduleId = content.categories[0]?.id;
      let progress = null;

      if (content.__typename === 'StudentVideoLesson' || content.__typename === 'StudentTextLesson') {
        progress = prepareLessonProgressData(content.metrics);
      }

      return {
        ...formattedContent,
        name: content.name,
        moduleId,
        progress,
        url: getTaskLessonUrl(subscriptionId, classId, taskId, content.id),
      };
    }
  }
}
