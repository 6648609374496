// @flow
import { Box, Button, Card, Flex, Heading, IconDiamond, Text } from '@getatomi/neon';

import useUpgradePrompt from 'src/hooks/components/useUpgradePrompt';

type Props = {|
  accountId: string,
|};

export default function UpgradeCallout(props: Props) {
  const { accountId } = props;

  const [upgradeModal, createShowModal] = useUpgradePrompt({
    shouldRequestUpgrade: true,
    subscriptionId: accountId,
  });

  const showModal = createShowModal();

  return (
    <>
      <Card as="aside" variant="callout" padding="spacingLarge1X" withHover={false}>
        <Flex alignItems={{ base: 'center', tablet: 'flex-start' }} gap="spacingRoot">
          <Box marginTop="spacingSmall2X">
            <IconDiamond color="colorIconBrandBold" size="sizeIconRoot" />
          </Box>

          <Flex flex={1} justifyContent="space-between" alignItems="center" gap="spacingLarge1X">
            <Flex direction="column" gap="spacingSmall">
              <Heading as="h2" variant="small">
                Unlock the full potential of Atomi
              </Heading>

              <Text as="p" variant="bodySmall" color="colorTextSubtle" display={{ base: 'none', tablet: 'block' }}>
                Get full access to Atomi’s comprehensive content and powerful features, including progress reports, the
                mark book and class insights.
              </Text>
            </Flex>

            <Button onClick={() => showModal()}>Upgrade</Button>
          </Flex>
        </Flex>
      </Card>

      {upgradeModal}
    </>
  );
}
