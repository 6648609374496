// @flow
import { Container, Skeleton } from '@getatomi/neon';
import Helmet from 'react-helmet';
import invariant from 'invariant';

import {
  getChallengeMarksUrl,
  getChallengeProgressReportUrl,
  getClassModuleUrl,
  getPostUrl,
  getClassTasksUrl,
  getTaskUrl,
  getTaskLessonUrl,
} from 'src/utils/routes';
import ClassBreadcrumbs, { BreadcrumbsItem } from 'src/domains/Class/ClassBreadcrumbs/ClassBreadcrumbs';

import OverviewLoader from './Overview/OverviewLoader/OverviewLoader';
import Overview from './Overview/Overview';
import { useGetChallengeOverview } from './useGetChallengeOverview';

export type ChallengeOverviewContainerProps = {|
  params: {|
    classId: string,
    moduleId: string,
    postId: string,
    subscriptionId: string,
    taskId?: string,
  |},
|};

export function ChallengeOverviewContainer(props: ChallengeOverviewContainerProps) {
  const { params } = props;
  const { classId, postId: lessonId, moduleId, subscriptionId: accountId, taskId } = params;

  const lessonUrl = getPostUrl(accountId, classId, moduleId, lessonId);
  const moduleUrl = getClassModuleUrl(accountId, classId, moduleId);
  const marksUrl = getChallengeMarksUrl(accountId, classId, moduleId, lessonId, taskId);
  const overviewUrl = getChallengeProgressReportUrl(accountId, classId, moduleId, lessonId, taskId);

  const { data, loading } = useGetChallengeOverview({ accountId, classId, lessonId, taskId });

  if (loading && !data) {
    return (
      <>
        <Container marginBottom="spacingLarge3X">
          <Skeleton height={28} width={300} />
        </Container>
        <OverviewLoader heading="How’s everyone going with this quiz?" marksUrl={marksUrl} overviewUrl={overviewUrl} />
      </>
    );
  }

  invariant(data, 'VQR overview data should be defined');
  const { challengingQuestions, classData, lesson, students, task } = data;

  return (
    <>
      <Helmet>
        <title>
          {lesson.name} | {classData.subject.name}
        </title>
      </Helmet>
      <Container marginBottom="spacingLarge3X">
        <ClassBreadcrumbs
          currentClass={classData}
          // Retail plans don't access VQR
          isRetailPlan={false}
          subject={classData.subject}
          subscriptionId={accountId}
        >
          {task && <BreadcrumbsItem href={getClassTasksUrl(accountId, classData.id)}>Tasks</BreadcrumbsItem>}
          {task && <BreadcrumbsItem href={getTaskUrl(accountId, classData.id, task.id)}>{task.title}</BreadcrumbsItem>}
          {task && (
            <BreadcrumbsItem href={getTaskLessonUrl(accountId, classData.id, task.id, lessonId)}>
              {lesson.name}
            </BreadcrumbsItem>
          )}
          {!task && <BreadcrumbsItem href={moduleUrl}>{lesson.moduleName}</BreadcrumbsItem>}
          {!task && <BreadcrumbsItem href={lessonUrl}>{lesson.name}</BreadcrumbsItem>}
        </ClassBreadcrumbs>
      </Container>
      <Overview
        averageMark={lesson.averageMark}
        challengeTier={lesson.tier}
        challengingQuestions={challengingQuestions}
        completionRate={lesson.completionRate}
        marksUrl={marksUrl}
        overviewUrl={overviewUrl}
        students={students}
      />
    </>
  );
}

export default ChallengeOverviewContainer;
