// @flow
import { Flex, Box, useHover } from '@getatomi/neon';

import { PostListItemContent } from 'src/components/PostList/PostListItemLink/PostListItemLink';
import { UnstyledLink } from 'src/components/Link/Link';
import postTypes from 'src/constants/postTypes';
import draftColorRange from 'src/domains/Tasks/draftColorRange';
import type { ClassTaskContent, TaskStatus } from 'src/domains/Tasks/ClassTasks/classTasksTransformer';
import type { TaskContent as StudentTaskContent } from 'src/domains/Tasks/Task/taskTransformer';

type ClassTaskCardContentListProps = {|
  contents: $ReadOnlyArray<ClassTaskContent>,
  onStudentCountClick: (content: ClassTaskContent, taskId: string) => mixed,
  studentCount: number,
  subjectRootColor: string,
  taskId: string,
  taskStatus: ?TaskStatus,
|};

type StudentTaskCardContentListProps = {|
  contents: $ReadOnlyArray<StudentTaskContent>,
  subjectRootColor: string,
|};

type TaskContentWrapperProps = {|
  children: React.Node,
  contentType: string,
  shouldShowDivider: boolean,
|};

function checkShouldShowDivider(contentIndex: number, lastContentIndex: number): boolean {
  return contentIndex < lastContentIndex;
}

function TaskContentWrapper(props: TaskContentWrapperProps) {
  const { children, contentType, shouldShowDivider } = props;

  const { hoverProps, isHovered } = useHover({});

  return (
    <Box
      as="li"
      backgroundColor={isHovered ? 'colorBackgroundHovered' : undefined}
      fontFamily="fontFamilySystem"
      marginInline="spacingNegativeRoot"
      paddingBlock="spacingSmall"
      paddingInline="spacingRoot"
      position="relative"
      {...hoverProps}
      _before={
        shouldShowDivider && {
          borderBottomWidth: 'borderWidthRoot',
          borderBottomStyle: 'solid',
          borderBottomColor: 'colorBorder',
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 'spacingRoot',
          right: 'spacingRoot',
        }
      }
    >
      <Box flexGrow={1} testHook={`task-list-link-${contentType}`}>
        {children}
      </Box>
    </Box>
  );
}

export function ClassTaskCardContentList(props: ClassTaskCardContentListProps) {
  const { contents, subjectRootColor, studentCount, taskId, taskStatus, onStudentCountClick } = props;

  const lastContentIndex = contents.length - 1;

  return (
    <Box as="ul" borderTopWidth="borderWidthRoot" borderTopStyle="solid" borderTopColor="colorBorder">
      {contents.map((content, contentIndex) => {
        const progressCount = taskStatus !== 'draft' ? content.countInProgressStudents : undefined;
        const shouldShowDivider = checkShouldShowDivider(contentIndex, lastContentIndex);

        return (
          <TaskContentWrapper key={content.id} contentType={content.type} shouldShowDivider={shouldShowDivider}>
            <PostListItemContent
              wrapper={
                <Flex
                  grow={1}
                  as={UnstyledLink}
                  to={{ pathname: content.url, state: { useRouterGoBack: true } }}
                  testHook="task-list-link"
                />
              }
              type={content.type}
              challengeTier={content.tier}
              color={taskStatus === 'draft' ? draftColorRange.icon : subjectRootColor}
              name={content.name}
              duration={content.duration}
              progressCount={progressCount}
              studentsCount={studentCount}
              onProgressClick={() => onStudentCountClick(content, taskId)}
              isLocked={false}
              testHook="task-list-link-content"
            />
          </TaskContentWrapper>
        );
      })}
    </Box>
  );
}

export function StudentTaskCardContentList(props: StudentTaskCardContentListProps) {
  const { contents, subjectRootColor } = props;

  const lastContentIndex = contents.length - 1;

  return (
    <Box as="ul" borderTopWidth="borderWidthRoot" borderTopStyle="solid" borderTopColor="colorBorder">
      {contents.map((content, contentIndex) => {
        const shouldShowDivider = checkShouldShowDivider(contentIndex, lastContentIndex);

        return (
          <TaskContentWrapper key={content.id} contentType={content.type} shouldShowDivider={shouldShowDivider}>
            <PostListItemContent
              wrapper={
                <Flex
                  grow={1}
                  as={UnstyledLink}
                  to={{ pathname: content.url, state: { useRouterGoBack: true } }}
                  testHook={`task-list-link-${content.type}`}
                />
              }
              type={content.type}
              challengeTier={content.tier}
              color={subjectRootColor}
              name={content.name}
              duration={content.duration}
              progress={content.progress}
              isCompleted={Boolean(content.progress)}
              isLocked={false}
              withStrengthIndicator={content.type === postTypes.challenge}
              testHook="task-list-link-content"
            />
          </TaskContentWrapper>
        );
      })}
    </Box>
  );
}
