// @flow
import { useEffect, useState } from 'react';
import { withCookies } from 'react-cookie';
import type Cookies from 'universal-cookie';
import { Avatar, Box, Checkbox, Flex, Heading, IconSchool, IconUser, TextField } from '@getatomi/neon';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Controller } from 'react-hook-form';

import useRenderKey from 'src/hooks/useRenderKey';
import { clearLoginError, login, loadAuthStrategies, loginWithSaml } from 'src/actions/auth';
import ReduxAvatarContainer from 'src/components/ReduxAvatarContainer/ReduxAvatarContainer';
import Button, { trackButtonClick } from 'src/components/Button/Button';
import SocialLoginButton from 'src/domains/Login/SocialLoginButton/SocialLoginButton';
import { clearSocialLoginError } from 'src/actions/socialLogins';
import ErrorTypeMessage from 'src/components/ErrorTypeMessage/ErrorTypeMessage';
import FormMessage from 'src/components/FormMessage/FormMessage';
import Link from 'src/components/Link/Link';
import UserName from 'src/components/UserName/UserName';
import { DropTransition, TransitionGroup } from 'src/components/UITransition/UITransition';
import { trackingCtas } from 'src/constants/tracking';
import socialLogins from 'src/constants/socialLogins';
import { getLoginError, getAuthStrategiesError, getAuthStrategies } from 'src/reducers/auth';
import { getSocialLoginError, isLoggedInWithSocialAccount, isLoggingInWith } from 'src/reducers/socialLogins';
import Auth from 'src/utils/Auth';
import { getSubscriptionIdFromUrl } from 'src/utils/routes';
import { formatName } from 'src/utils/userFullName';
import { authStrategyTypes } from 'src/constants/authStrategyTypes';
import authCustomerTypes from 'src/constants/authCustomerTypes';
import useIsClientLoaded, { clientRequiredAttribute, loadingMessage } from 'src/hooks/useIsClientLoaded';
import type { ApiError, BoundAction, ReduxState, AuthStrategy } from 'src/types';
import Choice from 'src/components/Choice/Choice';

import useLoginForm, { type OutboundFields } from './useLoginForm';

export type LoginFormProps = {
  authStrategies: Array<AuthStrategy>,
  clearLoginErrorAction: BoundAction<typeof clearLoginError>,
  clearSocialLoginErrorAction: BoundAction<typeof clearSocialLoginError>,
  cookies: Cookies,
  isLoggingInWithSocial: boolean,
  loadAuthStrategiesAction: BoundAction<typeof loadAuthStrategies>,
  loginAction: BoundAction<typeof login>,
  loginError?: ApiError,
  loginWithSamlAction: BoundAction<typeof loginWithSaml>,
  redirectUrl: ?string,
  samlErrorMessage: ?string,
};

const mapStateToProps = (state: ReduxState) => ({
  authStrategies: getAuthStrategies(state),
  isLoggingInWithSocial:
    Object.keys(socialLogins).some((provider) => isLoggingInWith(state, provider)) ||
    isLoggedInWithSocialAccount(state),
  loginError: getLoginError(state) || getSocialLoginError(state) || getAuthStrategiesError(state),
});

function LoginForm(props: LoginFormProps) {
  const {
    authStrategies,
    clearLoginErrorAction,
    clearSocialLoginErrorAction,
    cookies,
    isLoggingInWithSocial,
    loadAuthStrategiesAction,
    loginAction,
    loginError,
    loginWithSamlAction,
    redirectUrl,
    samlErrorMessage: initialSamlErrorMessage,
  } = props;

  const auth = new Auth(cookies);
  const user = auth.getUserDetails();
  const reAuthUserEmail = auth.getReAuthUser();
  const isRememberMeLayout = !!user;

  const [key, updateKey] = useRenderKey();
  const [isAuthStrategyListVisible, setIsAuthStrategyListVisible] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [isLoggingInWithPassword, setIsLoggingInWithPassword] = useState<boolean>(
    isRememberMeLayout || !!reAuthUserEmail
  );
  const [samlErrorMessage, setSamlErrorMessage] = useState<?string>(initialSamlErrorMessage);
  const [selectedAuthStrategy, setSelectedAuthStrategy] = useState<?AuthStrategy>(null);
  const isClientLoaded = useIsClientLoaded();

  useEffect(() => {
    setIsLoggingIn(false);
  }, [loginError]);

  useEffect(() => {
    const unlistenHistory = browserHistory.listen(() => {
      clearLoginErrorAction();
      clearSocialLoginErrorAction();
    });
    return () => unlistenHistory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (data: OutboundFields) => {
    setSamlErrorMessage(null);
    setIsLoggingIn(true);

    if (isLoggingInWithPassword) {
      auth.removeReAuthUserEmail();

      return loginAction(
        data.email || user.email,
        data.password,
        data.rememberMe,
        redirectUrl,
        selectedAuthStrategy?.subscription.id
      );
    }

    const res = await loadAuthStrategiesAction(data.email);

    const subscriptionIdInRedirectUrl = redirectUrl && getSubscriptionIdFromUrl(redirectUrl);

    if (subscriptionIdInRedirectUrl) {
      const redirectUrlDerivedAuthStrategy = res.find((authStrategy) => {
        return authStrategy.subscription?.id === subscriptionIdInRedirectUrl;
      });

      if (redirectUrlDerivedAuthStrategy) {
        if (redirectUrlDerivedAuthStrategy.type === authStrategyTypes.saml) {
          loginWithSamlAction({
            email: data.email,
            redirectUrl,
            subscriptionId: subscriptionIdInRedirectUrl,
          });
        } else {
          setSelectedAuthStrategy(redirectUrlDerivedAuthStrategy);
          setIsLoggingInWithPassword(true);
          setIsLoggingIn(false);
        }
        return;
      }
    }

    const samlAuthStrategies = res.filter(({ type }) => type === authStrategyTypes.saml);
    if (res.length === 1 && samlAuthStrategies.length === 1) {
      loginWithSamlAction({
        email: data.email,
        redirectUrl,
        subscriptionId: samlAuthStrategies[0].subscription.id,
      });
    } else if (samlAuthStrategies.length > 0) {
      setIsAuthStrategyListVisible(true);
      setIsLoggingIn(false);
    } else {
      setIsLoggingInWithPassword(true);
      setIsLoggingIn(false);
    }
  };

  const { control, fields, form, reset, watch } = useLoginForm({
    defaultValues: {
      email: reAuthUserEmail ?? user?.email ?? '',
      password: '',
      rememberMe: false,
    },
    onSubmitSuccess: (data: OutboundFields) => {
      onSubmit(data);
    },
    context: {
      isPasswordFieldPresent: isLoggingInWithPassword,
    },
  });

  const selectAuthStrategy = (authStrategy: AuthStrategy) => {
    setSelectedAuthStrategy(authStrategy);
    if (authStrategy.type === authStrategyTypes.saml) {
      loginWithSamlAction({
        email: watch('email'),
        redirectUrl,
        subscriptionId: authStrategy.subscription.id,
      });
    } else {
      setIsLoggingInWithPassword(true);
      setIsAuthStrategyListVisible(false);
    }
  };

  const clearUser = () => {
    reset();
    setIsAuthStrategyListVisible(false);
    setIsLoggingInWithPassword(false);
    clearLoginErrorAction();
    auth.removeReAuthUserEmail();
    auth.removeUserDetails();

    // force the uncontrolled inputs to re-render with their new (reset) values
    updateKey();
  };

  return (
    <Box textAlign="center">
      <Heading as="h1" color="colorTextInverted" variant="large" marginBottom="spacingLarge3X">
        {isRememberMeLayout
          ? `Hi ${formatName(user.first_name)}! You’re looking great today.`
          : 'Log in to your account'}
      </Heading>

      {isRememberMeLayout ? (
        <>
          <Box marginBottom="spacingRoot">
            <ReduxAvatarContainer user={user} size="sizeAvatarLarge1X" display="inline-block" />
          </Box>
          <Heading as="p" color="colorTextInverted" marginBottom="spacingSmall2X" variant="small">
            <UserName firstName={user.first_name} lastName={user.last_name} />
          </Heading>
        </>
      ) : (
        Object.keys(socialLogins).map((provider) => (
          <Box key={provider} marginBottom="spacingRoot">
            <SocialLoginButton provider={provider} />
          </Box>
        ))
      )}

      <Box marginTop="spacingLarge3X">
        {/* key used to force the uncontrolled inputs to re-render on demand */}
        <form {...form} key={key} {...clientRequiredAttribute}>
          {loginError && (
            <FormMessage>
              <ErrorTypeMessage error={loginError} />
            </FormMessage>
          )}

          {samlErrorMessage && (
            <FormMessage>
              <ErrorTypeMessage error={{ message: samlErrorMessage }} useAPIMessage />
            </FormMessage>
          )}

          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                {...field}
                {...fields.email}
                withValidationIcon={false}
                insertAfter={
                  isLoggingInWithPassword || isAuthStrategyListVisible ? (
                    <Box marginRight="spacingNegativeSmall2X">
                      <Button
                        type="reset"
                        onClick={() => {
                          trackButtonClick({ children: 'Change' });
                          clearUser();
                        }}
                        testHook="login-form-change-email-button"
                        size="small1X"
                      >
                        Change
                      </Button>
                    </Box>
                  ) : undefined
                }
                isDisabled={isLoggingInWithSocial}
                isReadOnly={isLoggingInWithPassword || isAuthStrategyListVisible}
                variant="inverted"
              />
            )}
          />

          <TransitionGroup>
            {isAuthStrategyListVisible && (
              <DropTransition>
                <Box as="ul" marginTop="spacingRoot" testHook="login-form-auth-strategy-list">
                  {authStrategies.map((authStrategy, i) => (
                    <Choice
                      key={i}
                      isLoading={
                        authStrategy.subscription.id === selectedAuthStrategy?.subscription.id &&
                        selectedAuthStrategy?.type === authStrategyTypes.saml
                      }
                      testHook="login-form-auth-strategy-list-item"
                      title={authStrategy.subscription.name}
                      onClick={() => selectAuthStrategy(authStrategy)}
                      {...(authStrategy.subscription.is_invited
                        ? {
                            tags: [
                              {
                                children: 'Activation pending',
                                color: 'colorTextDanger',
                                variant: 'dashed',
                                tooltip:
                                  'Click on this account to accept your pending invitation to join your school on Atomi.',
                              },
                            ],
                          }
                        : {})}
                      avatar={
                        <Avatar
                          backgroundColor="colorBackgroundBrandSubtle"
                          size="sizeAvatarLarge"
                          icon={
                            authStrategy.subscription.plan_customer_type === authCustomerTypes.school ? (
                              <IconSchool size="sizeIconRoot" strokeWidth="1.5" color="colorIconBrand" />
                            ) : (
                              <IconUser size="sizeIconRoot" strokeWidth="1.5" color="colorIconBrand" />
                            )
                          }
                        />
                      }
                    />
                  ))}
                </Box>
              </DropTransition>
            )}

            {isLoggingInWithPassword && (
              <DropTransition>
                <Box marginTop="spacingRoot">
                  <Controller
                    control={control}
                    name="password"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        {...fields.password}
                        withValidationIcon={false}
                        insertAfter={
                          <Box
                            fontSize="fontSizeSmall2X"
                            // align it with the Change button in the email field above
                            marginRight="spacingSmall2X"
                          >
                            <Link
                              to="/forgot-password"
                              trackingData={{ cta: trackingCtas.navigateToForgotPassword }}
                              onClick={() => clearUser()}
                            >
                              Forgot?
                            </Link>
                          </Box>
                        }
                        isDisabled={isLoggingInWithSocial}
                        variant="inverted"
                      />
                    )}
                  />
                </Box>
              </DropTransition>
            )}

            {!isAuthStrategyListVisible && (
              <DropTransition>
                <Flex
                  direction={{ base: 'column', tablet: 'row' }}
                  alignItems={{ tablet: 'center' }}
                  justifyContent={{ tablet: isRememberMeLayout ? 'center' : 'space-between' }}
                  gap="spacingLarge1X"
                  marginTop="spacingLarge"
                >
                  {!isRememberMeLayout && (
                    <Controller
                      control={control}
                      name="rememberMe"
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          {...fields.rememberMe}
                          isSelected={field.value}
                          isDisabled={isLoggingInWithSocial}
                          variant="inverted"
                        />
                      )}
                    />
                  )}
                  <Button
                    testHook="login-form-submit-button"
                    isLoading={isLoggingIn}
                    variant="ghost"
                    type="submit"
                    isDisabled={!isClientLoaded || isLoggingInWithSocial}
                    title={isClientLoaded ? undefined : loadingMessage}
                  >
                    {isLoggingInWithPassword ? 'Log In' : 'Continue'}
                  </Button>
                </Flex>
              </DropTransition>
            )}
          </TransitionGroup>
        </form>
      </Box>
    </Box>
  );
}

export default withCookies(
  (connect(mapStateToProps, {
    clearLoginErrorAction: clearLoginError,
    clearSocialLoginErrorAction: clearSocialLoginError,
    loadAuthStrategiesAction: loadAuthStrategies,
    loginWithSamlAction: loginWithSaml,
    loginAction: login,
  })(LoginForm): React.AbstractComponent<LoginFormProps>)
);
