// @flow
import { useCallback, useMemo, useState } from 'react';
import type Cookies from 'universal-cookie';
import { Box, Flex, InfoTip, Text } from '@getatomi/neon';
import _ from 'lodash';

import { loginWithSaml, loadAuthStrategies } from 'src/actions/auth';
import RequestCallbackDialog from 'src/components/RequestCallbackDialog/RequestCallbackDialog';
import SubscriptionName from 'src/components/SubscriptionName/SubscriptionName';
import userAccountStatuses from 'src/constants/userAccountStatuses';
import Auth from 'src/utils/Auth';
import { getDashboardUrl, getSchoolAccountUrl, getSettingsUrl, getUpgradeUrl } from 'src/utils/routes';
import type { Subscription, UserType, BoundAction } from 'src/types';

const maxSubscriptionShown = 3;

type AccountMenuItem = {
  href?: string,
  isDisabled?: boolean,
  key: string,
  label: React.Node,
  textValue?: string,
};
type AccountMenuSection = { children: $ReadOnlyArray<AccountMenuItem>, key: string, title?: string };
type AccountMenuItems = $ReadOnlyArray<AccountMenuItem | ?AccountMenuSection>;

export type AccountMenuItemsProps = {|
  cookies: Cookies,
  isFreeFamilyPlan: boolean,
  isFreeSchoolPlan: boolean,
  isFreeStudentPlan: boolean,
  isLoggedInAsAccountManager: boolean,
  isLoggedInAsChild: boolean,
  isLoggedInAsParent: boolean,
  isLoggedInAsSuperAdmin: boolean,
  loadAuthStrategiesAction: BoundAction<typeof loadAuthStrategies>,
  loginWithSamlAction: BoundAction<typeof loginWithSaml>,
  otherSubscriptions: Array<Subscription>,
  subscription: Subscription,
  user: UserType,
  withUpgradeCta?: boolean,
|};

type Output = {
  accountSection: ?AccountMenuSection,
  disabledKeys: Array<string>,
  handleAction: (key: string) => void,
  items: AccountMenuItems,
  upgradeDialog: ?React.Node,
};

export function useAccountMenuItems(props: AccountMenuItemsProps): Output {
  const {
    cookies,
    isFreeFamilyPlan,
    isFreeSchoolPlan,
    isFreeStudentPlan,
    isLoggedInAsAccountManager,
    isLoggedInAsChild,
    isLoggedInAsParent,
    isLoggedInAsSuperAdmin,
    loginWithSamlAction,
    otherSubscriptions,
    subscription,
    user,
    withUpgradeCta,
  } = props;

  const [isUpgradeDialogOpened, setIsUpgradeDialogOpened] = useState<boolean>(false);

  const auth = useMemo(() => new Auth(cookies), [cookies]);
  const authorizedSubscriptionIds = auth.getAuthorizedSubscriptions().map(({ id }) => id);

  const getDashboardLink = useCallback(
    (subscriptionId: number) => {
      if (isLoggedInAsAccountManager) {
        const selectedSubscription = otherSubscriptions.find((sub) => sub.id === subscriptionId);
        return getDashboardUrl(subscriptionId, {
          customerType: selectedSubscription?.plan_customer_type,
          queryParams: { source: 'all' },
        });
      }

      return getDashboardUrl(subscriptionId);
    },
    [isLoggedInAsAccountManager, otherSubscriptions]
  );

  const isSubscriptionAuthorised = useCallback(
    (subscriptionId: number) => {
      return isLoggedInAsSuperAdmin || authorizedSubscriptionIds.includes(subscriptionId);
    },
    [authorizedSubscriptionIds, isLoggedInAsSuperAdmin]
  );

  const handleAuthorisation = useCallback(
    async (subscriptionId: number) => {
      auth.logout();
      loginWithSamlAction({
        email: user.email,
        redirectUrl: getDashboardLink(subscriptionId),
        subscriptionId,
      });
    },
    [getDashboardLink, loginWithSamlAction, user.email, auth]
  );

  const handleToggleUpgradeDialog = () => {
    setIsUpgradeDialogOpened((isOpen) => !isOpen);
  };

  const handleAction = useCallback(
    (key: string) => {
      if (key === 'logout') {
        auth.logoutAndRedirect();
      }
      if (key === 'request-upgrade') {
        handleToggleUpgradeDialog();
      }
      if (key.includes('account')) {
        const subscriptionId = +key.split('-').pop();
        if (!isSubscriptionAuthorised(subscriptionId)) {
          handleAuthorisation(subscriptionId);
        }
      }
    },
    [handleAuthorisation, isSubscriptionAuthorised, auth]
  );

  const disabledKeys: Array<string> = [];
  const canRequestUpgrade = isFreeSchoolPlan || (isFreeFamilyPlan && isLoggedInAsChild);
  const canUpgrade = isFreeStudentPlan || (isFreeFamilyPlan && isLoggedInAsParent);
  const showUpgradeMenuItem = withUpgradeCta && (canUpgrade || canRequestUpgrade);
  const hasMultipleAccounts = otherSubscriptions && otherSubscriptions.length > 0;
  const showAllAccountsMenuItem =
    otherSubscriptions && (otherSubscriptions.length > maxSubscriptionShown || isLoggedInAsSuperAdmin);

  let accountSection: AccountMenuSection;
  const upgradeItems: Array<AccountMenuItem> = [];

  if (hasMultipleAccounts) {
    accountSection = {
      title: 'Accounts',
      key: 'accounts',
      children: [
        ..._.take(otherSubscriptions, maxSubscriptionShown).map((sub) => {
          const isPendingConfirmation = sub.user.subscription_status === userAccountStatuses.legacy.invited;
          const isAuthorised = isSubscriptionAuthorised(sub.id);
          const key = `account-${sub.id}`;

          if (isPendingConfirmation) {
            disabledKeys.push(key);
          }

          return {
            key,
            isDisabled: isPendingConfirmation,
            href: isAuthorised && !isPendingConfirmation ? getDashboardLink(sub.id) : undefined,
            // use target="_parent" to prevent the Neon RouterProvider from using SPA navigation
            // and force a full page refresh
            target: isAuthorised ? '_parent' : undefined,
            label: (
              <Box
                // overwrite the default disabled item colour as this is a special use case of a
                // disabled menu item where we want the text to be readable but not actionnable.
                color={isPendingConfirmation ? 'colorText' : undefined}
              >
                <SubscriptionName
                  enforceDefaultName={isLoggedInAsAccountManager}
                  name={sub.name}
                  planType={sub.plan_customer_type}
                />
                {isPendingConfirmation ? (
                  <Flex alignItems="center">
                    <Text as="span" variant="bodySmall2X" color="colorTextSubtler">
                      Activation pending
                    </Text>
                    <InfoTip
                      content="You need to accept the email invitation to your school account. Search your email for “Atomi” to find it, or ask your school to resend your invite."
                      iconSize="sizeIconSmall2X"
                    />
                  </Flex>
                ) : (
                  !isAuthorised && (
                    <Text variant="bodySmall2X" color="colorTextSubtler">
                      Logged out
                    </Text>
                  )
                )}
              </Box>
            ),
            textValue: sub.name,
          };
        }),
        showAllAccountsMenuItem ? { key: 'view-all', href: '/subscriptions', label: 'View all accounts' } : null,
      ].filter(Boolean),
    };
  }

  if (showUpgradeMenuItem) {
    const textValue = isLoggedInAsChild ? 'Ask to upgrade' : 'Upgrade';
    const label = (
      <Box as="span" color="colorTextLink">
        {textValue}
      </Box>
    );
    if (canUpgrade) {
      upgradeItems.push({
        key: 'upgrade',
        href: getUpgradeUrl(subscription.id),
        label,
        textValue,
      });
    } else {
      upgradeItems.push({
        key: 'request-upgrade',
        label,
        textValue,
      });
    }
  }

  const items: AccountMenuItems = [
    {
      key: 'general',
      children: [
        {
          key: 'settings',
          label: 'Settings',
          href: isLoggedInAsAccountManager ? getSchoolAccountUrl(subscription.id) : getSettingsUrl(subscription.id),
        },
        ...upgradeItems,
      ],
    },
    accountSection,
    {
      key: 'logout',
      label: 'Log out',
    },
  ];

  const upgradeDialog = withUpgradeCta && canRequestUpgrade && (
    <RequestCallbackDialog isOpen={isUpgradeDialogOpened} onClose={handleToggleUpgradeDialog} />
  );

  return { disabledKeys, handleAction, items, accountSection, upgradeDialog };
}
