// @flow
import { type ApolloError, useQuery } from '@apollo/client';
import invariant from 'invariant';

import type { OnboardingId } from 'src/constants/onboardingIds';
import type { GetOnboardingItems, GetOnboardingItemsVariables } from 'src/graphql/types/generated/GetOnboardingItems';

import { ONBOARDING_ITEMS, type StaticOnboardingItem } from './onboardingItems';
import useCompleteOnboardingItem from './useCompleteOnboardingItem';
import GET_ONBOARDING_ITEMS from './GetOnboardingItems.graphql';

export type OnboardingItem = {|
  actions: $ReadOnlyArray<{
    href?: string,
    isExternal?: boolean,
    label: string,
    to?: string,
  }> | null,
  body: string | null,
  id: OnboardingId,
  isCompleted: boolean,
  isDisabled: boolean,
  title: string,
|};

type Props = {|
  accountId: string,
  isSkipped?: boolean,
|};

type CompleteOnboardingItemCallbackParams = {|
  itemToComplete: OnboardingId,
  shouldPersistOnFullCompletion?: boolean,
|};

export type CompleteOnboardingItemCallback = (params: CompleteOnboardingItemCallbackParams) => Promise<void>;

export type Output = {|
  completeOnboardingItem: CompleteOnboardingItemCallback | null,
  data: {|
    countCompleted: number,
    countItems: number,
    isFreeSchoolOwner: boolean,
    items: $ReadOnlyArray<OnboardingItem>,
  |} | null,
  error: ApolloError | null,
  loading: boolean,
|};

// Helper function to find static item with error handling
function findStaticItem(id: string): StaticOnboardingItem {
  const item = ONBOARDING_ITEMS.find((onboardingItem) => onboardingItem.id === id);
  invariant(item, `No static item found for onboarding item with ID: ${id}`);

  return item;
}

// Transform static actions to dynamic actions, e.g. to generate a URL based on the subscription id
function transformActions(
  actions: $PropertyType<StaticOnboardingItem, 'actions'>,
  accountId: string
): $PropertyType<OnboardingItem, 'actions'> {
  if (!actions) return null;

  return actions.map(({ to, ...rest }) => ({
    ...rest,
    to: typeof to === 'function' ? to(accountId) : to,
  }));
}

export default function useOnboardingItems(props: Props): Output {
  const { accountId, isSkipped = false } = props;

  const completeOnboardingItem = useCompleteOnboardingItem({
    accountId,
  });

  const { data, error, loading } = useQuery<GetOnboardingItems, GetOnboardingItemsVariables>(GET_ONBOARDING_ITEMS, {
    skip: isSkipped,
    variables: {
      accountId,
    },
  });

  if (isSkipped) {
    return {
      completeOnboardingItem: null,
      loading: false,
      error: null,
      data: null,
    };
  }

  if (error) {
    return {
      completeOnboardingItem: null,
      error,
      loading: false,
      data: null,
    };
  }

  if (loading && !data) {
    return {
      completeOnboardingItem: null,
      loading: true,
      error: null,
      data: null,
    };
  }

  const accountData = data?.me?.account;

  invariant(accountData, 'Account data should be defined');

  const { plan, role } = accountData;
  const isPaidSchoolTeacher = !plan.isFree && role === 'TEACHER';
  const isFreeSchoolOwner = plan.isFree && role === 'ACCOUNT_OWNER';
  const isFreeSchoolOwnerOnboardingFeatureEnabled =
    process.env.NEXT_PUBLIC_FEATURE_FREE_SCHOOL_OWNER_ONBOARDING === 'true';
  const isOnboardingEnabled = isPaidSchoolTeacher || (isFreeSchoolOwner && isFreeSchoolOwnerOnboardingFeatureEnabled);

  let items: $ReadOnlyArray<OnboardingItem> = [];

  if (isOnboardingEnabled) {
    items = accountData.onboardingItems.map((item) => {
      const staticItem = findStaticItem(item.id);
      const { actions, body, id, title } = staticItem;
      return {
        id,
        isCompleted: item.status === 'COMPLETE',
        isDisabled: item.status === 'BLOCKED',
        title,
        body,
        actions: transformActions(actions, accountId),
      };
    });
  }

  const countItems = items.length;
  const countCompleted = items.filter((item) => item.isCompleted).length;

  const completeOnboardingItemWrapper = async ({
    itemToComplete,
    shouldPersistOnFullCompletion,
  }: CompleteOnboardingItemCallbackParams) => {
    // Retain reference to `items` by wrapping this in another function
    await completeOnboardingItem({
      itemToComplete,
      items,
      shouldPersistOnFullCompletion,
    });
  };

  return {
    error: null,
    loading: false,
    completeOnboardingItem: completeOnboardingItemWrapper,
    data: {
      countItems,
      countCompleted,
      isFreeSchoolOwner,
      items,
    },
  };
}
