// @flow
import { Container, EmptyState, Illustration } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import type { UserGroupType } from 'src/types';

type EmptyStateClassUsersProps = {|
  onAddClick?: () => mixed,
  userType: UserGroupType,
|};

export default function EmptyStateClassUsers(props: EmptyStateClassUsersProps) {
  const { userType, onAddClick } = props;

  let heading;
  let description;
  let ctaLabel;
  let ctaOnClick;

  if (onAddClick) {
    heading = `There are no ${userType} in this class, yet.`;
    description =
      userType === 'teachers'
        ? `You'll need to add teachers to the class so they can view our awesome lessons and collaborate with their students!`
        : `You'll need to add students to your class so they can view our awesome lessons!`;
    ctaLabel = `Add ${userType}`;
    ctaOnClick = onAddClick;
  } else {
    description = `There are no ${userType} in this class, yet. You can ask your school to start adding them!`;
  }

  return (
    <Container marginTop="spacingLarge7X">
      <EmptyState
        media={<Illustration name="emptystate-add-users" />}
        heading={heading}
        description={description}
        primaryAction={
          onAddClick && (
            <Button onClick={ctaOnClick} testHook="empty-state-class-users-button" data-intercom="add-students">
              {ctaLabel}
            </Button>
          )
        }
      />
    </Container>
  );
}
