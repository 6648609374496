// @flow
import { Alert } from '@getatomi/neon';

import Link from 'src/components/Link/Link';

export default function CrossSubjectAlert() {
  return (
    <Alert variant="info">
      This lesson lives outside your class. You can share it via tasks but results won’t appear in mark book or class
      insights.{' '}
      <Link
        isExternal
        variant="monochrome"
        href="http://support.getatomi.com/en/articles/9206833-sharing-content-across-subjects-and-levels"
      >
        Find out more
      </Link>
    </Alert>
  );
}
