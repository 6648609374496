// @flow
import { Button, ButtonLoader, IconButton, IconPlus, Skeleton, useMediaQuery } from '@getatomi/neon';

import type { TrackingDataType } from 'src/types';
import { trackButtonClick } from 'src/components/Button/Button';

export type ButtonAddProps = {|
  'data-intercom'?: string,
  assistiveText: string,
  children?: React.Node,
  isLoading?: boolean,
  mobileVariant?: string,
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => mixed,
  testHook?: string,
  trackingData?: TrackingDataType,
|};

function ButtonAdd(props: ButtonAddProps) {
  const { assistiveText, children, isLoading, mobileVariant = 'primary', onClick, testHook, trackingData } = props;
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const onClickHandler = (e: SyntheticEvent<HTMLButtonElement>) => {
    trackButtonClick({ children, trackingData, variant: 'secondary' });
    onClick && onClick(e);
  };

  const buttonProps = {
    'data-intercom': props['data-intercom'],
    onClick: onClickHandler,
    isLoading,
    testHook,
  };

  return isMobile ? (
    <IconButton {...buttonProps} assistiveText={assistiveText} variant={mobileVariant}>
      <IconPlus strokeWidth="2" />
    </IconButton>
  ) : (
    <Button {...buttonProps} size="small" variant="secondary">
      {children}
    </Button>
  );
}

export function ButtonAddLoader() {
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  return isMobile ? <Skeleton borderRadius="borderRadiusRoot" size={40} /> : <ButtonLoader size="small" />;
}

export default ButtonAdd;
