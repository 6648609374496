// @flow
import Helmet from 'react-helmet';
import { Container, Heading, ProgressBar, Stack } from '@getatomi/neon';

import GraphQLError from 'src/components/GraphQLError/GraphQLError';

import OnboardingList from './OnboardingList';
import OnboardingLoader from './OnboardingLoader';
import UpgradeCallout from './UpgradeCallout';
import useOnboardingItems from './useOnboardingItems/useOnboardingItems';

type Params = {
  subscriptionId: string,
};

type RouterProps = {
  params: Params,
};

export default function Onboarding(props: RouterProps) {
  const {
    params: { subscriptionId },
  } = props;

  const { data, error, loading } = useOnboardingItems({ accountId: subscriptionId });

  const shouldShowUpgradeCallout = data && data.countCompleted === data.countItems && data.isFreeSchoolOwner;

  return (
    <>
      <Helmet>
        <title>Getting started</title>
      </Helmet>
      <Container maxWidth="sizeContainerRoot">
        <Stack spacing="spacingLarge3X">
          {!error && <Heading as="h1">Getting started with Atomi</Heading>}
          {loading && <OnboardingLoader />}
          {data && (
            <>
              <ProgressBar
                ariaLabel="Progress made so far"
                variant="chunky"
                withLabel
                renderLabel={() => `${data.countCompleted} of ${data.countItems}`}
                value={(data.countCompleted / data.countItems) * 100}
              />
              <Stack spacing="spacingLarge8X">
                <OnboardingList items={data.items} />
                {shouldShowUpgradeCallout && <UpgradeCallout accountId={subscriptionId} />}
              </Stack>
            </>
          )}
          {error && <GraphQLError error={error} description="We couldn’t load your onboarding items." />}
        </Stack>
      </Container>
    </>
  );
}
