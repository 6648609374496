// @flow
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Controller } from 'react-hook-form';
import { Container, Heading, Stack, TextField } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import type { BoundAction, ReduxState } from 'src/types';
import PasswordField from 'src/components/PasswordField/PasswordField';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout/LogoOnlyLayout';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import {
  getResetPasswordError,
  getResetPasswordValidationErrors,
  isResetingPassword as isResetingPasswordSelector,
} from 'src/reducers/auth';
import { redirectToDashboard, resetPassword } from 'src/actions/auth';

import ForgotPasswordHelp from '../ForgotPasswordHelp/ForgotPasswordHelp';
import useResetPasswordForm, { type OutboundFields } from './useResetPasswordForm';

const mapStateToProps = (state: ReduxState) => ({
  isResetingPassword: isResetingPasswordSelector(state),
  error: getResetPasswordError(state),
  serverErrors: getResetPasswordValidationErrors(state),
});

type Props = {
  location: {
    query: {
      user_id: number,
    },
  },
  params: {
    token: string,
  },
};

export type InjectedProps = Props & {
  isResetingPassword: boolean,
  redirectToDashboardAction: BoundAction<typeof redirectToDashboard>,
  resetPasswordAction: BoundAction<typeof resetPassword>,
};

function ResetPassword(props: InjectedProps) {
  const { isResetingPassword, location, params, redirectToDashboardAction, resetPasswordAction } = props;

  const { control, fields, form, serverError, submitButton } = useResetPasswordForm({
    onSubmitSuccess: async (data: OutboundFields) => {
      await resetPasswordAction(params.token, location.query.user_id, data.password, data.passwordConfirmation);
      redirectToDashboardAction();
    },
  });

  return (
    <LogoOnlyLayout>
      <Helmet>
        <title>Reset your password</title>
      </Helmet>
      <Container maxWidth="sizeContainerSmall1X">
        <Heading as="h1" variant="large" color="colorTextInverted" marginBottom="spacingLarge4X">
          Reset your password
        </Heading>
        <form {...form}>
          <Stack spacing="spacingLarge1X">
            {serverError && <ValidationErrors errors={serverError.message} />}
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <PasswordField {...field} {...fields.password} autoComplete="new-password" variant="inverted" />
              )}
            />
            <Controller
              control={control}
              name="passwordConfirmation"
              render={({ field }) => (
                <TextField {...field} {...fields.passwordConfirmation} autoComplete="new-password" variant="inverted" />
              )}
            />
            <Button {...submitButton} isFullWidth isLoading={isResetingPassword} variant="ghost">
              Set new password
            </Button>
          </Stack>
        </form>
      </Container>
      <ForgotPasswordHelp />
    </LogoOnlyLayout>
  );
}

export default (connect(mapStateToProps, {
  redirectToDashboardAction: redirectToDashboard,
  resetPasswordAction: resetPassword,
})(ResetPassword): React.AbstractComponent<Props>);
