// @flow
import {
  Box,
  Flex,
  IconAlertGeneral,
  IconBookOpen,
  IconBriefcase,
  IconCircleHelp,
  IconGraduationHat,
  IconLifeBuoy,
  IconRecommend,
  IconVideoRecorder,
  Text,
} from '@getatomi/neon';

import { Item, Menu } from 'src/components/Menu/Menu';
import links from 'src/constants/links';
import { getGettingStartedUrl } from 'src/utils/routes';

type Items = $ReadOnlyArray<{|
  icon: React.Node,
  label: string,
  url: string,
|}>;

type CreateItemsParams = {|
  accountId: string,
  isFreeSchoolPlan: boolean,
  isLoggedInAsParent: boolean,
  isLoggedInAsStudent: boolean,
  isLoggedInAsTeacher: boolean,
|};

type HelpMenuProps = CreateItemsParams;

function createItems(params: CreateItemsParams): Items {
  const { accountId, isFreeSchoolPlan, isLoggedInAsParent, isLoggedInAsStudent, isLoggedInAsTeacher } = params;

  const isFreeSchoolOnboardingEnabled = process.env.NEXT_PUBLIC_FEATURE_FREE_SCHOOL_OWNER_ONBOARDING === 'true';

  const gettingStarted = {
    label: 'Getting started',
    icon: <IconAlertGeneral size="sizeIconSmall1X" color="colorIcon" />,
    url: getGettingStartedUrl(accountId),
  };

  const staffroomBlog = {
    label: 'The Staffroom blog',
    icon: <IconBriefcase size="sizeIconSmall1X" color="colorIcon" />,
    url: links.support.staffroomBlog,
  };

  const resourceHub = {
    label: 'Resource hub',
    icon: <IconBookOpen size="sizeIconSmall1X" color="colorIcon" />,
    url: links.support.resourceHub,
  };

  const teacherWebinars = {
    label: 'Atomi webinars',
    icon: <IconVideoRecorder size="sizeIconSmall1X" color="colorIcon" />,
    url: links.support.webinars.teachers,
  };

  const studentWebinars = {
    label: 'Atomi webinars',
    icon: <IconVideoRecorder size="sizeIconSmall1X" color="colorIcon" />,
    url: links.support.webinars.students,
  };

  const parentWebinars = {
    label: 'Atomi webinars',
    icon: <IconVideoRecorder size="sizeIconSmall1X" color="colorIcon" />,
    url: links.support.webinars.parents,
  };

  const helpCentre = {
    label: 'Help Centre',
    icon: <IconLifeBuoy size="sizeIconSmall1X" color="colorIcon" />,
    url: links.support.home,
  };

  const partnerStories = {
    label: 'Partner school stories',
    icon: <IconRecommend size="sizeIconSmall1X" color="colorIcon" />,
    url: links.support.partnerStories,
  };

  const studentBlog = {
    label: 'Student blog',
    icon: <IconGraduationHat size="sizeIconSmall1X" color="colorIcon" />,
    url: links.support.studentBlog,
  };

  if (isLoggedInAsParent) {
    return [parentWebinars, helpCentre];
  }

  if (isLoggedInAsStudent) {
    return [studentBlog, studentWebinars, helpCentre];
  }

  if (isFreeSchoolPlan) {
    return [
      isFreeSchoolOnboardingEnabled ? gettingStarted : null,
      staffroomBlog,
      resourceHub,
      partnerStories,
      teacherWebinars,
      helpCentre,
    ].filter(Boolean);
  }

  return [isLoggedInAsTeacher ? gettingStarted : null, staffroomBlog, resourceHub, teacherWebinars, helpCentre].filter(
    Boolean
  );
}

export default function HelpMenu(props: HelpMenuProps) {
  const { accountId, isFreeSchoolPlan, isLoggedInAsParent, isLoggedInAsStudent, isLoggedInAsTeacher } = props;

  const items = createItems({
    accountId,
    isFreeSchoolPlan,
    isLoggedInAsParent,
    isLoggedInAsStudent,
    isLoggedInAsTeacher,
  });

  return (
    <Menu
      ariaLabel="Help menu"
      placement="bottom middle"
      renderTrigger={({ triggerProps, ref }) => (
        <Box as="button" type="button" ref={ref} paddingBlock="spacingSmall1X" {...triggerProps}>
          {/* Set height to 36px so that the popover top border overlaps the header's bottom border */}
          <Flex alignItems="center" gap="spacingSmall1X" height={36}>
            <IconCircleHelp size="sizeIconSmall1X" color="colorIcon" />
            <Text variant="bodySmall2X">Help</Text>
          </Flex>
        </Box>
      )}
      width={280}
    >
      {items.map((item) => (
        <Item
          key={item.label}
          href={item.url}
          target={item.url.startsWith('https') ? '_blank' : undefined}
          textValue={item.label}
        >
          {item.icon}
          {item.label}
        </Item>
      ))}
    </Menu>
  );
}
