// @flow
import { useMutation } from '@apollo/client';
import { Text, useToast } from '@getatomi/neon';

import onboardingIds, { type OnboardingId } from 'src/constants/onboardingIds';
import Link from 'src/components/Link/Link';
import { getGettingStartedUrl } from 'src/utils/routes';
import type {
  CompleteOnboardingItem,
  CompleteOnboardingItemVariables,
} from 'src/graphql/types/generated/CompleteOnboardingItem';
import Logger from 'src/utils/Logger';

import { type OnboardingItem } from './useOnboardingItems';
import COMPLETE_ONBOARDING_ITEM from './CompleteOnboardingItem.graphql';
import GET_ONBOARDING_ITEMS from './GetOnboardingItems.graphql';

export type Params = {|
  accountId: string,
|};

export type CompleteOnboardingItemParams = {|
  items: $ReadOnlyArray<OnboardingItem>,
  itemToComplete: OnboardingId,
  shouldPersistOnFullCompletion?: boolean,
|};

const toastMessages: { [key: OnboardingId]: string } = {
  [onboardingIds.ADD_STUDENTS_TO_CLASS]: 'You’ve added students to this class!',
  [onboardingIds.CREATE_CLASS]: 'You’ve added a class!',
  [onboardingIds.DISCOVER_LESSON]: 'You’ve discovered a lesson!',
  [onboardingIds.SHARE_LESSON]: 'You’ve shared a lesson!',
};

const log = new Logger('src/domains/Onboarding/useOnboardingItems/useCompleteOnboardingItem.js');

function useSuccessToast({ accountId }: {| accountId: string |}) {
  const toast = useToast();

  const showSuccessToast = ({
    itemToComplete,
    items,
    shouldPersistOnFullCompletion = false,
  }: CompleteOnboardingItemParams) => {
    const otherActionableItems = items.filter((item) => !item.isCompleted && item.id !== itemToComplete);
    const nextActionableItem = otherActionableItems[0];

    if (nextActionableItem) {
      toast.success(
        <>
          <Text as="p" variant="bodySmall" lineHeight="lineHeightSmall1X" marginBottom="spacingSmall2X">
            {toastMessages[itemToComplete]}
          </Text>
          <Link to={getGettingStartedUrl(accountId)} onClick={() => toast.hide()}>
            Up next: {nextActionableItem.title}
          </Link>
        </>,
        {
          duration: Infinity,
        }
      );
    } else {
      toast.success(
        <>
          <Text
            as="p"
            variant="bodySmall"
            fontWeight="fontWeightBold"
            lineHeight="lineHeightSmall1X"
            marginBottom="spacingSmall2X"
          >
            You’re all done!
          </Text>
          <Text as="p" variant="bodySmall" lineHeight="lineHeightSmall1X">
            Now that you’re a pro, start engaging your students with Atomi lessons today.
          </Text>
        </>,
        {
          duration: shouldPersistOnFullCompletion ? Infinity : 10000,
        }
      );
    }
  };

  return showSuccessToast;
}

export default function useCompleteOnboardingItem(
  params: Params
): (params: CompleteOnboardingItemParams) => Promise<void> {
  const { accountId } = params;

  const [completeItemMutation] = useMutation<CompleteOnboardingItem, CompleteOnboardingItemVariables>(
    COMPLETE_ONBOARDING_ITEM,
    {
      refetchQueries: [GET_ONBOARDING_ITEMS],
      awaitRefetchQueries: true,
    }
  );

  const showSuccessToast = useSuccessToast({ accountId });

  const completeOnboardingItem = async ({
    items,
    itemToComplete,
    shouldPersistOnFullCompletion,
  }: CompleteOnboardingItemParams) => {
    const currentItem = items.find((item) => item.id === itemToComplete);
    if (!currentItem || currentItem.isCompleted) {
      return;
    }

    try {
      await completeItemMutation({
        variables: {
          input: {
            id: itemToComplete,
          },
        },
      });
      showSuccessToast({ itemToComplete, items, shouldPersistOnFullCompletion });
    } catch (error) {
      // Retry is handled by the `retryLink`
      log.error(`Error completing the "${itemToComplete}" onboarding item`, error);
    }
  };

  return completeOnboardingItem;
}
