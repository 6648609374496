// @flow
import { useMemo, useRef, useState } from 'react';
import pluralize from 'pluralize';
import { union } from 'lodash';
import {
  Box,
  Container,
  Divider,
  EmptyState,
  Flex,
  Heading,
  HelpInfo,
  Item,
  ItemDescription,
  ItemLabel,
  Illustration,
  Modal,
  PickList,
  Skeleton,
  Stack,
  Text,
  TextField,
  TextLoader,
  useMediaQuery,
  useToast,
} from '@getatomi/neon';
import invariant from 'invariant';

import Avatar from 'src/components/Avatar/Avatar';
import Button from 'src/components/Button/Button';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import ModalActions from 'src/components/deprecated/ModalActions/ModalActions';
import Link from 'src/components/Link/Link';
import useOnboardingItems from 'src/domains/Onboarding/useOnboardingItems/useOnboardingItems';
import isValidEmailsList from 'src/utils/isValidEmailsList';
import userFullName from 'src/utils/userFullName';
import { trackingCtas } from 'src/constants/tracking';
import links from 'src/constants/links';
import userAccountStatuses from 'src/constants/userAccountStatuses';
import type { UserGroupType } from 'src/types';

import BulkAddStudentsToClassForm from './BulkAddStudentsToClassForm/BulkAddStudentsToClassForm';
import useAddClassUsers from './useAddClassUsers/useAddClassUsers';
import useGetAddUsersDialog, { type ClassUser } from './useGetAddUsersDialog/useGetAddUsersDialog';

export type AddUsersDialogBodyProps = {|
  allUsersEmails: $ReadOnlyArray<string>,
  canManageUsers: boolean,
  classId: string,
  className: string,
  isBulkAdding: boolean,
  isFreePlan: boolean,
  isSetupModeActive: boolean,
  onClose: () => mixed,
  subscriptionId: string,
  toggleBulkAddForm: (SyntheticMouseEvent<HTMLButtonElement>) => void,
  usersNotInClass: $ReadOnlyArray<ClassUser>,
  userType: UserGroupType,
|};

export type AddUsersDialogQueryProps = {|
  canManageUsers: boolean,
  classId: string,
  className: string,
  isBulkAdding: boolean,
  isFreePlan: boolean,
  onClose: () => mixed,
  subscriptionId: string,
  toggleBulkAddForm: (SyntheticMouseEvent<HTMLButtonElement>) => void,
  userType: UserGroupType,
|};

export type AddUsersDialogContainerProps = {|
  canManageUsers: boolean,
  classId: string,
  className: string,
  isFreePlan: boolean,
  onClose: () => mixed,
  showModal: boolean,
  subscriptionId: string,
  userType: UserGroupType,
|};

type SelectedUsers = Set<string>;

export function getDialogHeading({
  userType,
  className,
  isBulkAdding,
}: {|
  className: string,
  isBulkAdding: boolean,
  userType: UserGroupType,
|}): string {
  return isBulkAdding ? `Bulk add ${userType} to ${className}` : `Add ${userType} to ${className}`;
}

function Layout(props: {|
  apiErrors: React.Node,
  bulkAddUsers: React.Node,
  dialogActions: React.Node,
  heading: React.Node,
  inviteByEmail: React.Node,
  subheading: React.Node,
  userList: React.Node,
|}) {
  const { heading, subheading, userList, apiErrors, inviteByEmail, dialogActions, bulkAddUsers } = props;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  return (
    <Box as="form" testHook="add-users-modal-form" paddingBottom="spacingLarge4X">
      <Stack spacing="spacingLarge3X">
        <Container maxWidth="sizeContainerRoot" textAlign="center">
          <Stack spacing="spacingLarge2X">
            {heading}

            {!isMobile && subheading}
          </Stack>
        </Container>

        <Container maxWidth="sizeContainerSmall">
          <Stack spacing="spacingLarge3X">
            {!isMobile && <Divider />}

            {userList}

            {apiErrors}

            {inviteByEmail}
          </Stack>
        </Container>

        {dialogActions}

        {bulkAddUsers}
      </Stack>
    </Box>
  );
}

function Loader() {
  return (
    <Layout
      apiErrors={null}
      bulkAddUsers={null}
      dialogActions={null}
      heading={<Skeleton height={40} width="70%" animation="wave" margin="0 auto" />}
      subheading={
        <Stack>
          <TextLoader height={20} width="80%" animation="wave" margin="0 auto" />
          <TextLoader height={20} width="40%" animation="wave" margin="0 auto" />
        </Stack>
      }
      inviteByEmail={null}
      userList={
        <Stack spacing="spacingLarge">
          <Skeleton height={40} animation="wave" />
          <Stack as="ul" spacing="spacingNegativeSmall2X">
            {Array.from({ length: 5 }).map((index) => (
              <Flex alignItems="center" gap="spacingSmall" key={index} padding="spacingSmall">
                <Skeleton size={36} animation="wave" borderRadius="borderRadiusCircle" />
                <Skeleton height={18} width="50%" animation="wave" />
              </Flex>
            ))}
          </Stack>
        </Stack>
      }
    />
  );
}

function AddUsersDialogBody(props: AddUsersDialogBodyProps) {
  const {
    allUsersEmails,
    canManageUsers = false,
    classId,
    className,
    isFreePlan,
    userType,
    onClose,
    usersNotInClass,
    isSetupModeActive,
    isBulkAdding,
    toggleBulkAddForm,
    subscriptionId,
  } = props;
  const [selectedUsers, setSelectedUsers] = useState<SelectedUsers>(new Set([]));
  const [unlistedUsers, setUnlistedUsers] = useState<Array<string>>([]);
  const [unlistedUsersAreValid, setUnlistedUsersAreValid] = useState<boolean>(true);
  const [apiErrors, setApiErrors] = useState<$ReadOnlyArray<string>>([]);

  const { addClassUsers, isAddingClassUsers } = useAddClassUsers(userType);
  const { completeOnboardingItem } = useOnboardingItems({
    accountId: subscriptionId,
    isSkipped: !isFreePlan || userType !== 'students',
  });

  const toast = useToast();

  const handleSelectionChange = (selectedUserValues: SelectedUsers) => setSelectedUsers(selectedUserValues);

  const extractEmails = (emails: string) => {
    return emails.replace(/\s+/g, '').split(',').filter(Boolean);
  };

  const handleUnlistedUserChange = (value: string) => {
    const unlistedUserEmails = extractEmails(value.trim());
    setUnlistedUsers(unlistedUserEmails);
    setUnlistedUsersAreValid(true);
    setApiErrors([]);
  };

  const handleUnlistedUserBlur = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value.trim();
    const emails = extractEmails(value);

    if (isValidEmailsList(emails)) {
      setUnlistedUsers(emails);
      setUnlistedUsersAreValid(true);
    } else {
      setUnlistedUsers([]);
      setUnlistedUsersAreValid(false);
    }
  };

  const notifySuccess = (countUsers: number, countNewUsers?: number) => {
    let toastMessage = `You’ve added ${countUsers} ${pluralize(userType, countUsers)} to this class`;

    if (countNewUsers && countNewUsers > 0) {
      toastMessage = toastMessage.concat(
        ` and sent ${countNewUsers} ${pluralize('invite', countNewUsers)} to the school account`
      );
    }

    if (isSetupModeActive) {
      const toastId = toast.success(
        <Stack spacing="spacingSmall2X">
          <p>{toastMessage}, however because your account is in setup mode invitations have not been sent yet.</p>
          <Link href={links.support.setupMode} onClick={() => toast.hide(toastId)} isExternal>
            Learn more
          </Link>
        </Stack>,
        { duration: Infinity }
      );
    } else {
      toast.success(`${toastMessage}.`);
    }
  };

  const completeAddUsersOnboardingItem = () => {
    completeOnboardingItem?.({ itemToComplete: 'ADD_STUDENTS_TO_CLASS' });
  };

  const handleSubmit = async (e: SyntheticEvent<>) => {
    e.preventDefault();
    if (!unlistedUsersAreValid) return;

    const selectedUsersEmails = usersNotInClass
      .filter((user) => Array.from(selectedUsers).includes(user.id))
      .map((user) => user.email);

    const users = union(selectedUsersEmails, unlistedUsers);
    const countUsers = users.length;

    try {
      // $FlowIgnore addClassUsers isn't typed properly by Flow
      const { errors } = await addClassUsers({
        classId,
        userEmails: users,
      });
      if (errors) {
        return setApiErrors(errors.map((gqlError) => gqlError.message));
      }
      notifySuccess(countUsers);
      completeAddUsersOnboardingItem();
      onClose();
    } catch (error) {
      onClose();
      return toast.error('There was an error adding users to your class. Please try again.');
    }
  };

  const handleBulkAddSuccess = (countUsers: number, countNewUsers?: number) => {
    notifySuccess(countUsers, countNewUsers);
    completeAddUsersOnboardingItem();
  };

  const availableUsers = useMemo(() => {
    return usersNotInClass.map((classUser) => ({
      ...classUser,
      name: userFullName(classUser.firstName, classUser.lastName) ?? classUser.email,
    }));
  }, [usersNotInClass]);

  const hasUsersToSelect = usersNotInClass.length > 0;
  const countUsersSelected = selectedUsers.size + unlistedUsers.length;
  const canSubmitForm = unlistedUsersAreValid && countUsersSelected > 0;
  const buttonLabel =
    countUsersSelected > 1 ? `Add ${countUsersSelected} ${userType}` : `Add ${pluralize.singular(userType)}`;

  const unlistedUsersErrorProps = !unlistedUsersAreValid
    ? {
        errorVariant: 'error',
        validationText: 'Oops! Please enter a valid email address. Separate multiple email addresses with a comma.',
      }
    : {};

  if (isBulkAdding) {
    return (
      <BulkAddStudentsToClassForm
        allUsersEmails={allUsersEmails}
        classId={classId}
        className={className}
        isAddingUsers={isAddingClassUsers}
        bulkAddUsers={addClassUsers}
        onClose={onClose}
        onSuccess={handleBulkAddSuccess}
        onGoBack={toggleBulkAddForm}
      />
    );
  }

  return (
    <Layout
      apiErrors={apiErrors.length > 0 && <ValidationErrors errors={apiErrors} />}
      bulkAddUsers={
        canManageUsers ? (
          userType === 'students' && (
            <Text as="p" variant="bodySmall1X" color="colorTextSubtler" textAlign="center">
              Need to add many more?{' '}
              <Button variant="text" size="small" onClick={toggleBulkAddForm}>
                Bulk add {userType} instead.
              </Button>
            </Text>
          )
        ) : (
          <Box marginTop="spacingLarge2X">
            <HelpInfo>
              If you need to{' '}
              <Link href={links.support.addUsersToAccount} isExternal variant="monochrome">
                invite new {userType} to your account,
              </Link>{' '}
              please get in touch with your school admin.
            </HelpInfo>
          </Box>
        )
      }
      dialogActions={
        <ModalActions
          submitLabel={buttonLabel}
          isLoading={isAddingClassUsers}
          isDisabled={!canSubmitForm}
          onSubmit={handleSubmit}
          onCancel={onClose}
          testHook="add-users-modal-actions"
          trackingData={{
            classId,
            cta: trackingCtas.classAddUsers,
            count: countUsersSelected,
            userType,
          }}
        />
      }
      heading={<Heading as="h1">{getDialogHeading({ userType, className, isBulkAdding: false })}</Heading>}
      subheading={
        <Text variant="lead" as="p">
          Add {userType} to this class to start collaborating!
          {canManageUsers &&
            ` New ${userType} will be sent invites to join your
      school account and added to the class, all in one go!`}
        </Text>
      }
      inviteByEmail={
        canManageUsers ? (
          <TextField
            name="unlistedUsers"
            label={
              hasUsersToSelect
                ? `Not listed above? Invite new ${userType} by email address`
                : `Invite new ${userType} by email address`
            }
            helpText="Enter email addresses separated by commas."
            onBlur={handleUnlistedUserBlur}
            onChange={handleUnlistedUserChange}
            autoCapitalize="off"
            {...unlistedUsersErrorProps}
          />
        ) : null
      }
      userList={
        hasUsersToSelect ? (
          <PickList
            ariaLabel={userType}
            items={availableUsers}
            selectedKeys={selectedUsers}
            onSelectionChange={handleSelectionChange}
            filter={(item, text) => {
              const searchText = text.toLowerCase().trim();
              return item.name.toLowerCase().includes(searchText) || item.email.toLowerCase().includes(searchText);
            }}
            inputPlaceholder="Search by name or email"
            emptyState={`None of the ${userType} match your search.`}
          >
            {(item) => {
              const isInvitationPending = item.accountStatus === userAccountStatuses.INVITED;
              const hasName = item.name !== item.email;
              return (
                <Item textValue={item.name}>
                  <Avatar user={item} />
                  <Box marginLeft="spacingRoot">
                    <ItemLabel>
                      <Text fontFamily="fontFamilySystem" fontSize="fontSizeSmall" color="colorText">
                        {item.name}
                      </Text>
                    </ItemLabel>
                    {(hasName || isInvitationPending) && (
                      <ItemDescription>
                        {hasName && (
                          <Text variant="bodySmall1X" color="colorTextSubtle">
                            {hasName && `${item.email}`}
                            {item.level && `, ${item.level.name}`}
                          </Text>
                        )}
                        {isInvitationPending && (
                          <Text variant="bodySmall1X" color="colorTextSubtler">
                            Invitation pending
                          </Text>
                        )}
                      </ItemDescription>
                    )}
                  </Box>
                </Item>
              );
            }}
          </PickList>
        ) : (
          <EmptyState
            media={<Illustration name="emptystate-add-users" />}
            description={
              <>
                You have no {userType} on your school account to add.{' '}
                {canManageUsers && ` You can invite new ${userType} below.`}
              </>
            }
          />
        )
      }
    />
  );
}

function AddUsersDialogQuery(props: AddUsersDialogQueryProps) {
  const {
    canManageUsers,
    classId,
    className,
    isBulkAdding,
    isFreePlan,
    toggleBulkAddForm,
    subscriptionId,
    onClose,
    userType,
  } = props;

  const { data, loading, error } = useGetAddUsersDialog({ classId, accountId: subscriptionId, userType });

  if (error) {
    return <GraphQLError error={error} description={`We couldn’t load your ${userType}.`} />;
  }

  if (loading) {
    return <Loader />;
  }

  invariant(data, 'Add users dialog data should be defined');

  return (
    <AddUsersDialogBody
      canManageUsers={canManageUsers}
      classId={classId}
      className={className}
      isBulkAdding={isBulkAdding}
      toggleBulkAddForm={toggleBulkAddForm}
      onClose={onClose}
      userType={userType}
      isSetupModeActive={data.isSetupModeActive}
      usersNotInClass={data.usersNotInClass}
      allUsersEmails={data.allUsersEmails}
      subscriptionId={subscriptionId}
      isFreePlan={isFreePlan}
    />
  );
}

export default function AddUsersDialogContainer(props: AddUsersDialogContainerProps) {
  const { canManageUsers, classId, className, isFreePlan, subscriptionId, onClose, showModal, userType } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isBulkAdding, setIsBulkAdding] = useState<boolean>(false);
  const toggleBulkAddForm = (event: SyntheticMouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // scroll to the top of the modal when switching between forms
    if (containerRef.current) {
      containerRef.current.scrollIntoView();
    }
    setIsBulkAdding((prevIsBulkAdding) => !prevIsBulkAdding);
  };

  const handleClose = () => {
    setIsBulkAdding(false);
    onClose();
  };

  return (
    <Modal
      ariaLabel={getDialogHeading({ userType, className, isBulkAdding })}
      isOpen={showModal}
      variant="view"
      size="fullscreen"
      onClose={handleClose}
    >
      <Box ref={containerRef}>
        {showModal && (
          <AddUsersDialogQuery
            canManageUsers={canManageUsers}
            classId={classId}
            className={className}
            isBulkAdding={isBulkAdding}
            onClose={handleClose}
            toggleBulkAddForm={toggleBulkAddForm}
            subscriptionId={subscriptionId}
            userType={userType}
            isFreePlan={isFreePlan}
          />
        )}
      </Box>
    </Modal>
  );
}
