// @flow

import Cookies, { type CookieSetOptions } from 'universal-cookie';
import moment from 'moment';

import HttpClient from 'src/utils/httpClient';
import type { UserType } from 'src/types';
import Logger from 'src/utils/Logger';
import { decodeAtomiAccessToken } from 'src/utils/jwt';
import { getCookieOptions } from 'src/utils/cookies';
import * as trackingUtils from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';
import { type TracingClient, type TracingHeaders, makeTraceId, makeTracingHeaders } from 'src/utils/tracing';
import { PROXIED_USER_ENDPOINT } from 'src/api/endpoints';
import {
  ACCESS_TOKEN_COOKIE,
  CLASSES_SOURCE_COOKIE,
  REFRESH_TOKEN_COOKIE,
  SAML_USER_COOKIE_MAX_AGE,
  SAML_USER_COOKIE,
  RE_AUTH_USER_EMAIL_COOKIE_MAX_AGE,
  RE_AUTH_USER_EMAIL_COOKIE,
  USER_DETAILS_COOKIE,
} from 'src/constants/cookies';

export type RefreshResponse = {
  meta: {
    auth: {
      access_token: string,
      expires_in: number,
      expiry: string,
      refresh_token: string,
      token_type: string,
    },
  },
};

export type VerifyServerAccessTokenHeaders = {|
  ...TracingHeaders,
  cookie: string,
|};

export type VerifyServerAccessTokenArgs = { path: string };

const log = new Logger('utils/Auth');

let pendingAccessTokenRefresh: Promise<RefreshResponse> | null;

function getSubscriptionIdFromPath(path: string): string | null {
  const parts: string[] = path.split('/');
  const subscriptionsIndex = parts.indexOf('subscriptions');
  const subscriptionId = subscriptionsIndex >= 0 ? parts[subscriptionsIndex + 1] ?? null : null;
  return subscriptionId;
}

function getWindowLocation(): string | null {
  // This should only be called on the client, but being defensive just in case.
  return typeof window !== 'undefined' ? window.location.pathname : null;
}

function getSubscriptionIdFromWindowLocation(): string | null {
  const path = getWindowLocation();
  const subscriptionId = path ? getSubscriptionIdFromPath(path) : null;
  return subscriptionId;
}

function getUserIdFromAccessToken(token: string): string {
  const decoded = decodeAtomiAccessToken(token);
  const userId = decoded.sub;
  return userId;
}

export default class Authentication {
  cookies: Cookies;

  traceId: string | null;

  options: CookieSetOptions;

  // Note about server vs client usage.
  //
  // Server usage:
  // * The Cookies instance is provided.
  // * The traceId is provided.
  //
  // Client usage:
  // * The Cookies instance is created here and cookies are extracted from the browser.
  // * The traceId must never be provided (we don't want multiple requests to use the same traceId)
  //
  constructor(cookies?: Cookies, traceId?: string | null) {
    this.cookies = cookies || new Cookies();
    this.traceId = traceId ?? null;
    this.options = getCookieOptions();
  }

  saveAccessToken(token: string) {
    this.cookies.set(ACCESS_TOKEN_COOKIE, token, this.options);
  }

  /**
   * @deprecated in favour of `getFreshAccessToken`
   */
  getAccessToken() {
    // In Cypress cookies are not set using Auth (Cookies) so this.cookies is not up-to-date when
    // checking if the access token is not expired
    if (__CLIENT__ && navigator.userAgent.includes('Cypress')) {
      this.cookies = new Cookies();
    }
    return this.cookies.get(ACCESS_TOKEN_COOKIE);
  }

  // Note: `getFreshAccessToken()` is called on the client only.
  async getFreshAccessToken(): Promise<?string> {
    const accessToken = this.getAccessToken();

    if (accessToken && this.isTokenExpired(accessToken)) {
      return this.refreshAccessToken();
    }

    return accessToken;
  }

  // Note: `refreshAccessToken()` is called on both client and server (during SSR).
  async refreshAccessToken(): Promise<?string> {
    if (__CLIENT__ && pendingAccessTokenRefresh) {
      const response = await pendingAccessTokenRefresh;
      const { access_token: accessToken } = response.meta.auth;
      return accessToken;
    }

    const currentAccessToken = this.getAccessToken();

    const accountId = __CLIENT__ ? getSubscriptionIdFromWindowLocation() ?? '' : '';

    const userId = currentAccessToken ? getUserIdFromAccessToken(currentAccessToken) : '';

    const client: TracingClient = __CLIENT__ ? '@app/carbon/client' : '@app/carbon/server';

    const path = __CLIENT__ ? getWindowLocation() ?? '' : '';

    // On the client always construct a new traceId.
    // On the server always use the server provided traceId passed into the constructor.
    const traceId = __CLIENT__ ? makeTraceId() : this.traceId ?? '';

    const headers = makeTracingHeaders({
      traceId,
      client,
      accountId,
      userId,
      path,
    });

    const response = await this.requestRefreshToken(headers);

    const accessToken = response.meta.auth.access_token;

    this.saveAccessToken(accessToken);

    return accessToken;
  }

  hasRefreshTokenCookie() {
    return this.cookies.get(REFRESH_TOKEN_COOKIE) !== undefined;
  }

  // Note: `requestRefreshToken()` is called on both the client and the server.
  // It is called on the client when the access token is expired and we are navigating to a new page.
  // It is called on the server when the access token is expired and we are executing middleware or performing SSR.
  // eslint-disable-next-line class-methods-use-this
  async requestRefreshToken(headers?: { [string]: string }): Promise<RefreshResponse> {
    // credentials cookies from a client request are automatically sent with this request
    // server requests need to provide the cookies inside the headers parameter
    const payload = { grant_type: 'refresh_token' };

    const newAccessTokenRefresh: Promise<RefreshResponse> = HttpClient.post(
      `${PROXIED_USER_ENDPOINT}/refresh`,
      payload,
      headers
    );

    // Note: `pendingAccessTokenRefresh` only appears to be used for client side usage.
    pendingAccessTokenRefresh = newAccessTokenRefresh;

    try {
      const response = await newAccessTokenRefresh;
      return response;
    } catch (error) {
      // If the refresh request returns a 401 status it means the refresh token was invalid or expired.
      // In this case we remove both the access token and refresh token cookies.
      // This prevents other code from also attempting to refresh the access token.
      // Note: `instanceof HttpError` does not work, so using a name check instead.
      if (error.name === 'HttpError' && error.status === 401) {
        this.logout();
      }
      throw error;
    } finally {
      pendingAccessTokenRefresh = null;
    }
  }

  /**
   * This method is called from the main server middleware to ensure we have a non-expired accessToken before to perform the SSR
   * We only attempt to refresh the accessToken if we have a refreshToken cookie, and the existing accessToken is expired
   * If there is no access token, we do not attempt to get a new one because it means the user is explicitly logged out
   * This method is non-blocking and does not throw errors
   * It returns the new accessToken and refreshToken so we can send the refreshToken back to the client in the response
   */
  async verifyServerAccessToken({ path }: VerifyServerAccessTokenArgs) {
    const accessToken = this.getAccessToken();

    if (!accessToken || !this.isTokenExpired(accessToken)) {
      return;
    }

    if (!this.hasRefreshTokenCookie()) {
      return;
    }

    const accountId = getSubscriptionIdFromPath(path) ?? '';

    const userId = getUserIdFromAccessToken(accessToken);

    const client: TracingClient = '@app/carbon/server';

    const traceId = this.traceId ?? '';

    const tracingHeaders = makeTracingHeaders({
      traceId,
      client,
      accountId,
      userId,
      path,
    });

    const headers: VerifyServerAccessTokenHeaders = {
      ...tracingHeaders,
      cookie: `refresh_token=${this.cookies.get(REFRESH_TOKEN_COOKIE)}`,
    };

    const response = await this.requestRefreshToken(headers).catch(() => {
      log.info('SSR was not able to refresh access token, non-blocking');
    });

    if (!response) {
      return;
    }

    const { access_token: newAccessToken, refresh_token: newRefreshToken } = response.meta.auth;

    this.saveAccessToken(newAccessToken);
    return { refreshToken: newRefreshToken, accessToken: newAccessToken };
  }

  getUserId(): string | null {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return null;
    }

    return getUserIdFromAccessToken(accessToken);
  }

  getAuthorizedSubscriptions() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return [];
    }

    const decoded = decodeAtomiAccessToken(accessToken);

    return decoded.subscriptions ?? [];
  }

  getIsAdmin() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return false;
    }

    const decoded = decodeAtomiAccessToken(accessToken);

    return Boolean(decoded.is_admin);
  }

  removeAccessToken() {
    this.cookies.remove(ACCESS_TOKEN_COOKIE, this.options);
  }

  removeRefreshToken() {
    this.cookies.remove(REFRESH_TOKEN_COOKIE, this.options);
  }

  isTokenExpired(token?: string) {
    const decoded = decodeAtomiAccessToken(token || this.getAccessToken());
    const tokenExpiry = moment.unix(decoded.exp).utc();
    const diffInSeconds = tokenExpiry.diff(Date.now(), 'seconds');
    const isExpired = diffInSeconds < 60;

    log.info(
      `Token ${isExpired ? 'expires' : 'will expire'} at ${tokenExpiry.utc().format()} in ${diffInSeconds} seconds`
    );

    return isExpired;
  }

  saveUserDetails(user: UserType) {
    this.cookies.set(
      USER_DETAILS_COOKIE,
      `${user.email};${user.first_name};${user.last_name};${user.color}`,
      this.options
    );
  }

  getUserDetails() {
    const user = this.cookies.get(USER_DETAILS_COOKIE);
    if (!user) return null;

    const [email, firstName, lastName, color] = user.split(';');
    return {
      email,
      first_name: firstName,
      last_name: lastName,
      color,
    };
  }

  removeUserDetails() {
    this.cookies.remove(USER_DETAILS_COOKIE, this.options);
  }

  logout(redirect?: boolean, redirectUrl?: string) {
    this.removeAccessToken();
    this.removeRefreshToken();
    this.cookies.remove(CLASSES_SOURCE_COOKIE, this.options);

    if (__CLIENT__) {
      trackingUtils.trackEvent(trackingEvents.userLoggedOut);
      // reset Segment user (clear cookies/localStorage)
      // https://segment.com/docs/sources/website/analytics.js/#reset-logout
      trackingUtils.reset();
      // remove session id from logged out user
      localStorage.removeItem('previous_analytics_session_id');
      // Intercom must be instructed to shutdown on logout to prevent the user being remembered by the widget
      // https://developers.intercom.com/installing-intercom/web/installation#ending-a-session
      const { Intercom } = window;
      if (Intercom) {
        Intercom('shutdown');
      }
      if (redirect) {
        window.location.replace(redirectUrl || '/');
      }
    }
  }

  logoutAndRedirect(redirectUrl: string = '/') {
    this.logout(true, redirectUrl);
  }

  clearUserData() {
    this.removeAccessToken();
    this.removeRefreshToken();
    this.removeUserDetails();
  }

  setSAMLLogin(args: { email: string, redirectUrl: string, subscriptionId: number }) {
    this.cookies.set(SAML_USER_COOKIE, JSON.stringify(args), {
      ...this.options,
      maxAge: SAML_USER_COOKIE_MAX_AGE,
    });
  }

  setReAuthUser(email: string) {
    this.cookies.set(RE_AUTH_USER_EMAIL_COOKIE, email, {
      ...this.options,
      maxAge: RE_AUTH_USER_EMAIL_COOKIE_MAX_AGE,
    });
  }

  getReAuthUser() {
    return this.cookies.get(RE_AUTH_USER_EMAIL_COOKIE);
  }

  removeReAuthUserEmail() {
    this.cookies.remove(RE_AUTH_USER_EMAIL_COOKIE);
  }
}
