// @flow
import { useEffect, useState } from 'react';
import { Box, Flex, IconLinkExternal, Text } from '@getatomi/neon';
import { browserHistory } from 'react-router';

import Link from 'src/components/Link/Link';

const getCurrentUrl = () => {
  const trackingParams = new URLSearchParams({
    utm_source: 'atomi',
    utm_campaign: 'embed',
  });
  return `${window.location.origin}${window.location.pathname}?${trackingParams.toString()}`;
};

export default function EmbedButton() {
  const [currentUrl, setCurrentUrl] = useState<?string>(null);

  // useEffect is only called on the client so we can retrieve the current URL there to prevent a
  // server rendering issue
  useEffect(() => {
    setCurrentUrl(getCurrentUrl());
  }, []);

  // update currentUrl when the route changes
  useEffect(() => {
    const unlistenHistory = browserHistory.listen(() => {
      setCurrentUrl(getCurrentUrl());
    });
    return () => unlistenHistory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentUrl) return null;

  return (
    <Box alignSelf="center" paddingInline="spacingRoot">
      <Text variant="bodySmall1X">
        <Link variant="monochrome" href={currentUrl} isExternal testHook="header-embed-referrer-button">
          <Flex as="span" alignItems="center" gap="spacingSmall1X">
            Open in Atomi
            <IconLinkExternal size="sizeIconSmall2X" />
          </Flex>
        </Link>
      </Text>
    </Box>
  );
}
