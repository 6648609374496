// @flow
import cx from 'classnames';
import { Truncate } from '@getatomi/neon';

import PostDuration from 'src/components/PostDuration/PostDuration';
import PostName from 'src/components/PostName/PostName';
import BackLink from 'src/components/BackLink/BackLink';
import PostUpgradePrompt from 'src/components/PostUpgradePrompt/PostUpgradePrompt';

import type { Lesson } from '../useGetModuleLesson';
import styles from './PostPagination.module.scss';

type PaginationLinkProps = {|
  isNext: boolean,
  nextDuration?: React.Node,
  onClick?: () => mixed, // set by PostUpgradePrompt React.clone()
  testHook?: string,
  text: React.Node,
  title: string,
  url?: string,
|};

export type PostPaginationProps = {|
  getLessonUrl: (lesson: Lesson) => string,
  isInline?: boolean,
  nextLesson: ?Lesson,
  prevLesson: ?Lesson,
  testHook?: string,
  upgradePromptProps?: React.ElementProps<typeof PostUpgradePrompt>,
|};

function PaginationLink(props: PaginationLinkProps) {
  const { isNext, nextDuration, testHook, onClick, text, title, url } = props;

  return (
    <BackLink
      isBlock
      isDirectionSwitched={isNext}
      onClick={onClick}
      testHook={testHook ? `${testHook}-${url ? 'link' : 'button'}` : undefined}
      to={url}
    >
      <span
        className={cx(styles.linkContainer, {
          [styles.isPrev]: !isNext,
          [styles.isNext]: isNext,
        })}
      >
        <span className={styles.linkLabel}>{isNext ? 'Up next' : 'Prev'}</span>
        <span className={styles.linkText}>
          <span className={styles.linkTruncate}>
            <Truncate title={title}>{text}</Truncate>
          </span>
        </span>
        {isNext && nextDuration && <span className={styles.linkDuration}>{nextDuration}</span>}
      </span>
    </BackLink>
  );
}

export default function PostPagination(props: PostPaginationProps) {
  const { getLessonUrl, isInline = false, nextLesson, prevLesson, testHook, upgradePromptProps } = props;

  const isNextLocked = Boolean(nextLesson?.isLocked);

  if (!nextLesson && !prevLesson) {
    return null;
  }

  let prevLessonNode = null;
  if (prevLesson) {
    const isPrevLocked = Boolean(prevLesson?.isLocked);
    const prevText = prevLesson && (
      <PostName challengeTier={prevLesson.tier} name={prevLesson.name} type={prevLesson.type} withPrefix />
    );

    if (isPrevLocked) {
      prevLessonNode = (
        <PostUpgradePrompt {...upgradePromptProps}>
          <PaginationLink isNext={false} testHook={testHook} text={prevText} title={prevLesson.name} />
        </PostUpgradePrompt>
      );
    } else {
      prevLessonNode = (
        <PaginationLink
          isNext={false}
          testHook={testHook}
          text={prevText}
          url={getLessonUrl(prevLesson)}
          title={prevLesson.name}
        />
      );
    }
  }

  let nextLessonNode = null;
  if (nextLesson) {
    const nextText = nextLesson && (
      <PostName challengeTier={nextLesson.tier} name={nextLesson.name} type={nextLesson.type} withPrefixOnMobileOnly />
    );
    const nextDuration = nextLesson && (
      <PostDuration duration={nextLesson.duration} type={nextLesson.type} challengeTier={nextLesson.tier} />
    );

    if (isNextLocked) {
      nextLessonNode = (
        <PostUpgradePrompt {...upgradePromptProps}>
          <PaginationLink
            isNext
            nextDuration={nextDuration}
            testHook={testHook}
            text={nextText}
            title={nextLesson.name}
          />
        </PostUpgradePrompt>
      );
    } else {
      nextLessonNode = (
        <PaginationLink
          isNext
          nextDuration={nextDuration}
          testHook={testHook}
          text={nextText}
          url={getLessonUrl(nextLesson)}
          title={nextLesson.name}
        />
      );
    }
  }

  return (
    <nav
      aria-label="Post pagination"
      className={cx(styles.root, {
        [styles.isNextOnly]: !prevLesson,
        [styles.isInline]: isInline,
      })}
      data-test={testHook}
    >
      {prevLessonNode}
      {nextLessonNode}
    </nav>
  );
}
