// @flow

import { Breadcrumbs, Container, Stack } from '@getatomi/neon';

import BackLink from 'src/components/BackLink/BackLink';
import ClassBreadcrumbs, { BreadcrumbsItem } from 'src/domains/Class/ClassBreadcrumbs/ClassBreadcrumbs';
import { getClassTasksUrl, getRevisionPageUrl, getTaskUrl, getTasksUrl } from 'src/utils/routes';

import type { Task, UserClass } from './useGetRevision';

export type Props = {|
  accountId: string,
  children: React.Node,
  isStudent: boolean,
  navigationType: 'backLink' | 'breadcrumbs',
  task: Task,
  userClass: UserClass,
|};

export function RevisionChallengeLayout(props: Props) {
  const { accountId, children, isStudent, navigationType, task, userClass } = props;

  return (
    <Stack spacing="spacingLarge3X">
      {navigationType === 'breadcrumbs' ? (
        <Container paddingInline="spacingNone">
          {isStudent ? (
            <Breadcrumbs>
              {task && <BreadcrumbsItem href={getTasksUrl(accountId)}>Tasks</BreadcrumbsItem>}
              {task && (
                <BreadcrumbsItem href={getTaskUrl(accountId, userClass.id, task.id)}>{task.title}</BreadcrumbsItem>
              )}
            </Breadcrumbs>
          ) : (
            <ClassBreadcrumbs
              currentClass={userClass}
              isRetailPlan={false}
              subject={userClass.subject}
              subscriptionId={accountId}
            >
              {task && <BreadcrumbsItem href={getClassTasksUrl(accountId, userClass.id)}>Tasks</BreadcrumbsItem>}
              {task && (
                <BreadcrumbsItem href={getTaskUrl(accountId, userClass.id, task.id)}>{task.title}</BreadcrumbsItem>
              )}
            </ClassBreadcrumbs>
          )}
        </Container>
      ) : (
        <Container paddingInline="spacingNone">
          <BackLink to={getRevisionPageUrl(accountId)}>Back to Revision</BackLink>
        </Container>
      )}
      <Container paddingInline="spacingNone" textAlign="center">
        {children}
      </Container>
    </Stack>
  );
}
