// @flow
import { Box, Heading, Text } from '@getatomi/neon';

import type { DateString, Plan } from 'src/types';
import Button from 'src/components/Button/Button';
import RequestCallback from 'src/components/RequestCallback/RequestCallback';
import SubscriptionLink from 'src/components/SubscriptionLink/SubscriptionLink';

import BillingCredits from '../BillingCredits/BillingCredits';
import PlanDescription from '../PlanDescription/PlanDescription';
import RetailBillingEstimate from '../RetailBillingEstimate/RetailBillingEstimate';
import ToggleAutoRenewal from '../ToggleAutoRenewal/ToggleAutoRenewal';

type PlanDetailsProps = {|
  expiryDate: DateString,
  isFamilyPlan: boolean,
  isFreeSchoolPlan: boolean,
  isFreeStudentPlan: boolean,
  isPaidRetailPlan: boolean,
  isPaidSchool: boolean,
  isTrialSubscription: boolean,
  numSeats: number,
  plan: Plan,
  trialEndDate: DateString,
|};

export default function PlanDetails(props: PlanDetailsProps) {
  const {
    plan,
    isFamilyPlan,
    isPaidSchool,
    isFreeSchoolPlan,
    isPaidRetailPlan,
    isFreeStudentPlan,
    isTrialSubscription,
    expiryDate,
    numSeats,
    trialEndDate,
  } = props;
  const isFreeFamilyPlan = isFamilyPlan && !isPaidRetailPlan;

  return (
    <section>
      <Heading as="h3" variant="small1X" marginBottom="spacingLarge1X">
        Your plan
      </Heading>
      <Text
        fontFamily="fontFamilySystem"
        fontSize="fontSizeLarge3X"
        fontWeight="fontWeightLight"
        lineHeight="lineHeightSmall"
        marginBottom="spacingSmall2X"
      >
        {plan.name}
      </Text>

      <Box marginBottom={isPaidRetailPlan ? 'spacingLarge5X' : 'spacingSmall2X'}>
        <PlanDescription
          description={plan.description}
          expiryDate={expiryDate}
          isFamilyPlan={isFamilyPlan}
          isFreeFamilyPlan={isFreeFamilyPlan}
          isPaidSchool={isPaidSchool}
          isTrialSubscription={isTrialSubscription}
          numSeats={numSeats}
          trialEndDate={trialEndDate}
        />
      </Box>

      <Box marginBottom="spacingLarge">
        <BillingCredits />
      </Box>

      {isPaidRetailPlan && (
        <>
          <Box marginBottom="spacingRoot">
            <ToggleAutoRenewal testHook="plan-details-toggle-auto-renewal" />
          </Box>
          <RetailBillingEstimate />
        </>
      )}

      {/** Free retail upgrade link **/}
      {(isFreeStudentPlan || isFreeFamilyPlan) && (
        <SubscriptionLink upgrade>{(url) => <Button to={url}>Upgrade to unlimited</Button>}</SubscriptionLink>
      )}

      {/** Free teacher upgrade button/modal **/}
      {isFreeSchoolPlan && (
        <RequestCallback>{(onClick) => <Button onClick={onClick}>Upgrade to a school account</Button>}</RequestCallback>
      )}
    </section>
  );
}
