// @flow
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Controller } from 'react-hook-form';
import { Container, Flex, HideVisually, Stack, Text, TextField } from '@getatomi/neon';

import type { BoundAction, ReduxState } from 'src/types';
import Button from 'src/components/Button/Button';
import PasswordField from 'src/components/PasswordField/PasswordField';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import { isEnforcingSaml as isEnforcingSamlSelector } from 'src/reducers/subscriptions';
import { changePassword } from 'src/actions/auth';

import useChangePasswordForm from './useChangePasswordForm';

const mapStateToProps = (state: ReduxState) => ({
  isEnforcingSaml: isEnforcingSamlSelector(state),
});

export type ChangePasswordProps = {
  changePasswordAction: BoundAction<typeof changePassword>,
  isEnforcingSaml: boolean,
};

function ChangePassword(props: ChangePasswordProps) {
  const { isEnforcingSaml, changePasswordAction } = props;
  const pageTitle = 'Change your password';

  const { control, fields, form, isLoading, serverError, submitButton } = useChangePasswordForm({
    onSubmitSuccess: async (data) => {
      await changePasswordAction(data.password, data.newPassword, data.newPasswordConfirmation);
    },
  });

  return (
    <Container maxWidth="sizeContainerSmall">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <HideVisually>
        <h2>{pageTitle}</h2>
      </HideVisually>
      {isEnforcingSaml ? (
        <Text as="p" variant="bodySmall" color="colorTextSubtler">
          You can’t manage your password on your Atomi account as your school requires you to log in using your school’s
          identity provider (Single Sign-on). If you need to change or reset your identity provider password, reach out
          to your school’s administrator.
        </Text>
      ) : (
        <form {...form}>
          <Stack spacing="spacingLarge5X">
            <Stack spacing="spacingLarge1X">
              {serverError && <ValidationErrors errors={serverError.message} />}
              <Controller
                control={control}
                name="password"
                render={({ field }) => <TextField {...field} {...fields.password} autoComplete="current-password" />}
              />
              <Controller
                control={control}
                name="newPassword"
                render={({ field }) => <PasswordField {...field} {...fields.newPassword} autoComplete="new-password" />}
              />
              <Controller
                control={control}
                name="newPasswordConfirmation"
                render={({ field }) => (
                  <TextField {...field} {...fields.newPasswordConfirmation} autoComplete="new-password" />
                )}
              />
            </Stack>
            <Flex justifyContent="center">
              <Button {...submitButton} isLoading={isLoading}>
                Update password
              </Button>
            </Flex>
          </Stack>
        </form>
      )}
    </Container>
  );
}

export default (connect(mapStateToProps, { changePasswordAction: changePassword })(
  ChangePassword
): React.AbstractComponent<{}>);
