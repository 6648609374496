// @flow
import { useEffect, useState } from 'react';
import { type ContextRouter, withRouter } from 'react-router';
import { Controller } from 'react-hook-form';
import { Box, Flex, Modal, Stack, StatusLight, TextField, useId, useToast } from '@getatomi/neon';

import DeleteClassDialog from 'src/components/DeleteClassDialog/DeleteClassDialog';
import Button from 'src/components/Button/Button';
import { Combobox, ItemLabel } from 'src/components/Combobox/Combobox';
import { Dropdown, DropdownLoader, Item } from 'src/components/Dropdown/Dropdown';
import FormMessage from 'src/components/FormMessage/FormMessage';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import Logger from 'src/utils/Logger';
import { type SubjectOptions } from 'src/utils/filterSubjectsByLevel';
import { getDashboardUrl } from 'src/utils/routes';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import useClassDialogForm, { type SubmitFields } from 'src/hooks/useClassDialogForm';
import useDeleteClasses from 'src/hooks/useDeleteClasses';

import useGetLevelSubjects from '../useGetLevelSubjects';
import type { ClassData } from '../useGetClass';
import useEditClass from './useEditClass';

const log = new Logger('domains/Class/EditClassDialog');

type Props = {
  currentClass: ClassData,
  onClose: () => mixed,
  showModal: boolean,
  subscriptionId: string,
};

export type InjectedProps = Props & {
  router: ContextRouter,
};

function EditClassDialog(props: InjectedProps) {
  const { currentClass, onClose, router, showModal, subscriptionId } = props;
  const { getLevelSubjects, levels, loading, error } = useGetLevelSubjects({
    subscriptionId,
  });

  const formId = useId();
  const toast = useToast();
  const levelId = currentClass.levels[0].id;
  const initialLevel = (levels && levels.find((level) => level.id === levelId)) || null;
  const [levelSubjects, setLevelSubjects] = useState<SubjectOptions>([]);
  const [hasLevelChanged, setHasLevelChanged] = useState<boolean>(false);
  const [hasSubjectChanged, setHasSubjectChanged] = useState<boolean>(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false);

  const { deleteClasses, isDeletingClasses } = useDeleteClasses();
  const { editClass, isEditingClass } = useEditClass();

  const onLevelChange = (value: string) => {
    const selectedLevel = (levels && levels.find((level) => level.id === value)) || null;
    const filteredSubjects: SubjectOptions =
      (selectedLevel &&
        selectedLevel.subjects.map((subject) => ({
          color: subject.color,
          label: subject.name,
          value: subject.code,
        }))) ||
      [];
    setLevelSubjects(filteredSubjects);
  };

  useEffect(() => {
    showModal && getLevelSubjects();
  }, [getLevelSubjects, showModal]);

  // We load initialLevel and its subjects using a lazy query, so we need to watch
  // for that data to be available before setting the initial value of the subjects combobox.
  useEffect(() => {
    if (initialLevel) {
      onLevelChange(initialLevel.id);
    }
    // Using JSON.stringify allows us to deeply compare objects
  }, [JSON.stringify(initialLevel)]); // eslint-disable-line

  const toggleDeletePrompt = (e?: SyntheticEvent<>) => {
    if (e) e.preventDefault();
    setShowDeletePrompt((prevState) => !prevState);
  };

  const onDeleteClass = async () => {
    toggleDeletePrompt();
    try {
      await deleteClasses({
        accountId: subscriptionId,
        classIds: [currentClass.id],
      });
      toast.success(`${currentClass.name} class has been deleted successfully.`);
      router.replace(getDashboardUrl(subscriptionId));
    } catch (onDeleteError) {
      log.warn('Delete class failed', onDeleteError);
      onClose();
      toast.error('There was an error deleting your class. Please try again.');
    }
  };

  const { control, fields, onSubmit, reset, serverError, watch } = useClassDialogForm({
    defaultValues: {
      levelId,
      subjectCode: currentClass.subject.code,
      name: currentClass.name,
    },
    handleRestErrors: true,
    onSubmitSuccess: async (data: SubmitFields) => {
      await editClass({
        classId: currentClass.id,
        levelId: data.levelId,
        name: data.name,
        subjectCode: data.subjectCode,
      });
      onClose();
      toast.success('Your class has been edited successfully.');
    },
    onSubmitError: () => {
      onClose();
      toast.error('There was an error editing your class. Please try again.');
    },
  });

  const onCancel = () => {
    onClose();
    reset();
  };

  useEffect(() => {
    // toggle the warning if the subject or level has changed
    const subscription = watch((value, { name, type }) => {
      if (name === 'levelId' && type === 'change') {
        setHasLevelChanged(value.levelId !== levelId);
      }
      if (name === 'subjectCode' && type === 'change') {
        setHasSubjectChanged(value.subjectCode !== currentClass.subject.code);
      }
    });
    return () => subscription.unsubscribe();
  }, [currentClass, levelId, watch]);

  if (error) {
    return (
      <Modal heading="Edit your class" isOpen={showModal} onClose={onClose}>
        <GraphQLError error={error} description="There was an error loading the form. Please try again." />
      </Modal>
    );
  }

  return (
    <>
      <Modal
        actions={
          <Flex width="sizeFull" justifyContent={{ base: 'center', tablet: 'space-between' }} gap="spacingLarge">
            <Button variant="tertiary" size="small" isLoading={isDeletingClasses} onClick={toggleDeletePrompt}>
              Delete class
            </Button>
            <Flex gap="spacingSmall1X">
              <Box display={{ base: 'none', tablet: 'inline-flex' }}>
                <Button variant="text" size="small" onClick={onCancel} isDisabled={isEditingClass || isDeletingClasses}>
                  Cancel
                </Button>
              </Box>
              <Button type="submit" form={formId} size="small" isLoading={isEditingClass} onClick={onSubmit}>
                Done
              </Button>
            </Flex>
          </Flex>
        }
        heading="Edit your class"
        isOpen={showModal}
        onClose={onCancel}
      >
        <form id={formId}>
          {(hasLevelChanged || hasSubjectChanged) && (
            <FormMessage variant="warning">
              Changing the year or subject of your class will delete all existing tasks for this class and reset your
              progress tracking. If you want to keep your tasks create a new class instead.
            </FormMessage>
          )}
          <Stack spacing="spacingLarge1X">
            {serverError && <ValidationErrors errors={serverError.message} />}
            <Controller
              control={control}
              name="name"
              render={({ field }) => <TextField {...field} {...fields.name} />}
            />
            <Controller
              control={control}
              name="levelId"
              render={({ field }) =>
                loading ? (
                  <DropdownLoader animation="wave" />
                ) : (
                  <Dropdown
                    {...field}
                    {...fields.levelId}
                    items={levels}
                    selectedKey={field.value ? field.value : null}
                    onSelectionChange={(value) => {
                      field.onChange(value);
                      onLevelChange(value);
                    }}
                  >
                    {(item) => <Item key={item.value}>{item.name}</Item>}
                  </Dropdown>
                )
              }
            />
            <Controller
              control={control}
              name="subjectCode"
              render={({ field }) =>
                loading || !levelSubjects.length ? (
                  <DropdownLoader animation="wave" />
                ) : (
                  <Combobox
                    {...field}
                    {...fields.subjectCode}
                    defaultItems={levelSubjects}
                    selectedKey={field.value ? String(field.value) : null}
                    onSelectionChange={field.onChange}
                    placeholder="Start typing a subject name"
                  >
                    {(item) => (
                      <Item key={item.value} textValue={item.label}>
                        <StatusLight color={item.color} />
                        <ItemLabel>{item.label}</ItemLabel>
                      </Item>
                    )}
                  </Combobox>
                )
              }
            />
          </Stack>
        </form>
      </Modal>
      <DeleteClassDialog isOpen={showDeletePrompt} onConfirm={onDeleteClass} onDismiss={toggleDeletePrompt} />
    </>
  );
}

export default withRouter(EditClassDialog);
