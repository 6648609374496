// @flow
import { useEffect } from 'react';
import { type ContextRouter } from 'react-router';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { CompactPagination, Container } from '@getatomi/neon';

import { trackEvent } from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';
import type { ReduxState, UserType } from 'src/types';
import { isLoggedInAsSuperAdmin as isLoggedInAsSuperAdminSelector } from 'src/reducers/auth';
import {
  isFreePlan as isFreePlanSelector,
  isSchoolPlan as isSchoolPlanSelector,
  isAdminOrAbove as isAdminOrAboveSelector,
  isLoggedInAsStudent as isLoggedInAsStudentSelector,
} from 'src/reducers/subscriptions';
import { subscribeToOwnProgress } from 'src/utils/pusher';
import { getDashboardUrl } from 'src/utils/routes';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import OnboardingCallout from 'src/domains/Onboarding/OnboardingCallout';

import DashboardNonStudent from './DashboardNonStudent/DashboardNonStudent';
import DashboardStudent from './DashboardStudent';
import useGetClasses from './useGetClasses/useGetClasses';
import useClassesSource from './useClassesSource';

type Params = {|
  subscriptionId: string,
|};

export type DashboardProps = {|
  isAdminOrAbove: boolean,
  isFreePlan: boolean,
  isLoggedInAsStudent: boolean,
  isLoggedInAsSuperAdmin: boolean,
  isSchoolPlan: boolean,
  location: {
    query: {
      [string]: string,
    },
  },
  params: Params,
  router: ContextRouter,
  user: UserType,
|};

export const mapStateToProps = (state: ReduxState) => ({
  user: state.auth.user,
  isLoggedInAsStudent: isLoggedInAsStudentSelector(state),
  isFreePlan: isFreePlanSelector(state),
  isSchoolPlan: isSchoolPlanSelector(state),
  isAdminOrAbove: isAdminOrAboveSelector(state),
  isLoggedInAsSuperAdmin: isLoggedInAsSuperAdminSelector(state),
});

function Dashboard(props: DashboardProps) {
  const {
    isLoggedInAsSuperAdmin,
    isAdminOrAbove,
    isFreePlan,
    isLoggedInAsStudent,
    isSchoolPlan,
    location,
    params,
    router,
    user,
  } = props;
  const { after, before, keywords } = location.query;
  const { subscriptionId } = params;

  const { classesSource } = useClassesSource();

  function updatePageLocation(query) {
    router.push({
      pathname: getDashboardUrl(subscriptionId),
      query: {
        ...query,
        source: classesSource,
      },
    });
  }

  const { classes, error, goToNextPage, goToPreviousPage, loading, refetch, userId } = useGetClasses({
    classesSource,
    isLoggedInAsStudent,
    isLoggedInAsSuperAdmin,
    keywords,
    paginationCursor: { after, before },
    subscriptionId,
    updatePageLocation,
  });

  const classCount = classes?.length ?? 0;

  // Handle the subscription as a separate effect, as its a separate side effect with its own
  // dependencies
  useEffect(() => {
    if (isLoggedInAsStudent) {
      return subscribeToOwnProgress(String(user.id), () => refetch());
    }
  }, [isLoggedInAsStudent, refetch, user.id]);

  useEffect(() => {
    if (!loading && !isLoggedInAsStudent && classesSource === 'user') {
      // This event is used to potentially start the Intercom's Product Tour
      trackEvent(trackingEvents.myClassesLoaded, { classCount });
    }
  }, [isLoggedInAsStudent, classCount, loading, classesSource]);

  if (error) {
    return <GraphQLError error={error} description="We couldn’t load your classes." />;
  }

  // Note: loading state is delegated to child components

  return (
    <>
      <Helmet>
        <title>{isSchoolPlan ? 'Classes' : 'Subjects'}</title>
      </Helmet>

      {isLoggedInAsStudent ? (
        <DashboardStudent
          classes={classes}
          isSchoolPlan={isSchoolPlan}
          isLoading={loading}
          subscriptionId={subscriptionId}
        />
      ) : (
        <>
          <OnboardingCallout accountId={subscriptionId} isParentLoading={loading} />
          <DashboardNonStudent
            classes={classes}
            isAdminOrAbove={isAdminOrAbove}
            isFreePlan={isFreePlan}
            isLoading={loading}
            isLoggedInAsSuperAdmin
            searchKeywords={keywords}
            subscriptionId={subscriptionId}
            userId={userId}
          />
        </>
      )}

      {(goToNextPage || goToPreviousPage) && (
        <Container marginTop="spacingLarge6X">
          <CompactPagination onPreviousClick={goToPreviousPage} onNextClick={goToNextPage} />
        </Container>
      )}
    </>
  );
}

export default (connect(mapStateToProps)(Dashboard): React.AbstractComponent<DashboardProps>);
