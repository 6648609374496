// @flow
import { useState } from 'react';
import invariant from 'invariant';
import { browserHistory } from 'react-router';

import type { PostType } from 'src/types';
import { trackingCtas, trackingCtaTypes, trackingEvents, trackingFeatures } from 'src/constants/tracking';
import ProgressReportDialog, {
  ProgressReportDialogLocked,
} from 'src/components/ProgressReportDialog/ProgressReportDialog';
import postTypes from 'src/constants/postTypes';
import { trackEvent } from 'src/utils/tracking';
import { getChallengeProgressReportUrl, getRevisionProgressReportUrl } from 'src/utils/routes';

type Post = {
  id: string,
  name: string,
  type: PostType,
};

export type OpenProgressReportDialogParams = {|
  // Revisions don't sit within a module, so won't have a parent moduleId
  moduleId: ?string,
  post: Post,
  taskId?: string,
|};

type Props = {|
  classId: string,
  isFreePlan?: boolean,
  onClose?: () => mixed,
  region?: string,
  subscriptionId: string,
|};

const shouldRedirect = (lesson) => lesson.type === postTypes.challenge || lesson.type === postTypes.revision;

export default function useProgressReportDialog(props: Props) {
  const { subscriptionId, classId, isFreePlan = false, onClose, region } = props;
  const [post, setPost] = useState<?Post>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLockedOpen, setIsLockedOpen] = useState<boolean>(false);

  const open = ({ post: newPost, moduleId: newModuleId, taskId }: OpenProgressReportDialogParams = {}) => {
    invariant(newPost, 'A post must be provided');
    setPost(newPost);
    if (isFreePlan) {
      trackEvent(trackingEvents.ctaClicked, {
        cta: trackingCtas.lockedFeatureDialog,
        type: trackingCtaTypes.button,
        feature: trackingFeatures.postProgress,
        classId,
        moduleId: newModuleId,
        postId: newPost.id,
      });
      setIsLockedOpen(true);
    } else {
      trackEvent(trackingEvents.ctaClicked, {
        cta: trackingCtas.classProgressOpenDialog,
        type: trackingCtaTypes.button,
        classId,
        moduleId: newModuleId,
        postId: newPost.id,
      });

      if (newPost.type === postTypes.challenge) {
        browserHistory.push(getChallengeProgressReportUrl(subscriptionId, classId, newModuleId, newPost.id, taskId));
      } else if (newPost.type === postTypes.revision) {
        browserHistory.push(getRevisionProgressReportUrl(subscriptionId, classId, newPost.id, taskId));
      } else {
        setIsDialogOpen(true);
      }
    }
  };

  const close = () => {
    setIsDialogOpen(false);
    setIsLockedOpen(false);
    if (onClose) onClose();
  };

  let dialog = null;
  if (post && isDialogOpen && !shouldRedirect(post)) {
    dialog = (
      <ProgressReportDialog
        isOpen
        subscriptionId={subscriptionId}
        classId={classId}
        // $FlowIgnore only non-challenge lesson types below
        lesson={post}
        onClose={close}
      />
    );
  }
  const locked = isLockedOpen && <ProgressReportDialogLocked isOpen onClose={close} region={region} />;

  return [dialog || locked || null, { openProgressReportDialog: open, close }];
}
