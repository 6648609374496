// @flow
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type Fields = {
  email: string,
  password: string,
  rememberMe: boolean,
};

export type OutboundFields = {
  email: string,
  password: string,
  rememberMe: boolean,
};

const labels = {
  email: 'Email',
  password: 'Password',
  rememberMe: 'Remember me',
};

const resolver = yupResolver(
  yup.object().shape({
    email: yup.string().email().required().label(labels.email),
    password: yup.mixed().when('$isPasswordFieldPresent', {
      is: true,
      then: yup.string().required().label(labels.password),
    }),
    rememberMe: yup.boolean().label(labels.rememberMe),
  })
);

export default function useLoginForm({
  context,
  defaultValues,
  onSubmitSuccess,
}: {
  context: {
    isPasswordFieldPresent?: boolean,
  },
  defaultValues: Fields,
  onSubmitSuccess: (data: OutboundFields) => void | Promise<void>,
}) {
  const { control, formState, handleSubmit, reset, watch } = useForm({
    resolver,
    defaultValues,
    context,
  });

  const onSubmit = async (event: Event) => {
    event.preventDefault();
    handleSubmit((data) => onSubmitSuccess(data))(event);
  };

  const emailMessage = formState.errors.email?.message ?? null;
  const passwordMessage = formState.errors.password?.message ?? null;

  return {
    control,
    reset,
    watch,
    form: {
      onSubmit,
      // extra layer of security to prevent submitting sensitive data in URL if the user somehow
      // manages to submit the form when the JS client bundle is not loaded.
      method: 'post',
    },
    fields: {
      email: {
        ariaLabel: labels.email,
        type: 'email',
        autoComplete: 'username',
        autoCorrect: 'off',
        placeholder: 'Email',
        validationText: emailMessage,
        errorVariant: emailMessage && 'error',
        isRequired: true,
      },
      password: {
        ariaLabel: labels.password,
        type: 'password',
        placeholder: 'Password',
        validationText: passwordMessage,
        errorVariant: passwordMessage && 'error',
        isRequired: true,
        autoFocus: true,
      },
      rememberMe: {
        children: labels.rememberMe,
      },
    },
  };
}
