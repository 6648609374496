// @flow
import { AttachmentList, Box, Flex, HelpText, IconDrag, IconLink, PostSummary, useMediaQuery } from '@getatomi/neon';
import React from 'react';

import PostProgress from 'src/components/PostProgress/PostProgress';
import type { AttachedContent, TaskAttachment } from 'src/domains/Tasks/types';
import type { TaskContent } from 'src/domains/Tasks/Task/taskTransformer';
import type { ClassTaskContent } from 'src/domains/Tasks/ClassTasks/classTasksTransformer';
import type { TaskAttachmentWithFreshness } from 'src/components/useTaskDialog/hooks/useAttachments';
import type { OpenProgressReportDialogParams } from 'src/hooks/components/useProgressReportDialog';
import PostDuration from 'src/components/PostDuration/PostDuration';
import PostIcon from 'src/components/PostIcon/PostIcon';
import { UnstyledLink } from 'src/components/Link/Link';
import { externalContent } from 'src/domains/Tasks/components/TaskAttachmentList/TaskAttachmentList';
import postTypes from 'src/constants/postTypes';

function DraggbleContainer({ children, readOnly }: { children: React$Node, readOnly: boolean }) {
  const isTouchDevice = useMediaQuery('(any-pointer: coarse)');
  if (readOnly || isTouchDevice) {
    return children;
  }
  return (
    <Flex alignItems="center">
      <Flex
        alignItems="center"
        color="colorIcon"
        cursor="grab"
        height="sizeIconLarge1X"
        marginLeft="spacingNegativeRoot"
        justifyContent="space-around"
        width="sizeIconLarge1X"
      >
        <IconDrag size="sizeIconSmall1X" strokeWidth={0.5} />
      </Flex>
      <Box flexGrow={1}>{children}</Box>
    </Flex>
  );
}

export function renderLinkAttachment(readOnly: boolean = false) {
  return function render({ url, onRemove }: { onRemove: () => mixed, url: string }) {
    return (
      <DraggbleContainer readOnly={readOnly}>
        <AttachmentList.Attachment removeAssistiveText="Remove link" onRemove={readOnly ? undefined : onRemove}>
          <AttachmentList.ExternalContent
            linkProps={{
              href: url,
              target: '_blank',
            }}
            icon={<IconLink color="colorIcon" />}
            name={url}
            meta="Link"
          />
        </AttachmentList.Attachment>
      </DraggbleContainer>
    );
  };
}

export function renderFileAttachment(
  getAttachment: (id: string) => ?TaskAttachment | ?TaskAttachmentWithFreshness,
  showInfectedPrompt: () => mixed
) {
  return function renderContent(readOnly: boolean = false) {
    return function render({ id, onRemove }: { id: string, onRemove: () => mixed }) {
      const attachment = getAttachment(id);
      if (!attachment) return null;
      const { fileName = '', isInfected, isUploading } = attachment;
      const helpTextId = `${id}-help-text`;

      return (
        <DraggbleContainer readOnly={readOnly}>
          <AttachmentList.Attachment
            removeAssistiveText="Remove file"
            onRemove={readOnly ? undefined : onRemove}
            validationTextId={isInfected ? helpTextId : undefined}
          >
            {showInfectedPrompt &&
              (isUploading ? (
                <AttachmentList.Loader />
              ) : (
                // second param is the onClick event to trigger if the file is infected
                externalContent(attachment, showInfectedPrompt)
              ))}
          </AttachmentList.Attachment>
          {isInfected && (
            <HelpText
              id={helpTextId}
              variant="error"
            >{`The file '${fileName}' did not pass the virus security scan and can’t be downloaded`}</HelpText>
          )}
        </DraggbleContainer>
      );
    };
  };
}

export function renderReadOnlyAtomiContent({
  getAttachedContent,
  isDraft,
  openProgressReportDialog,
  subjectColor,
  taskId,
}: {
  getAttachedContent: (id: string) => ?ClassTaskContent | ?TaskContent,
  isDraft: boolean,
  openProgressReportDialog: (OpenProgressReportDialogParams) => mixed,
  subjectColor: string,
  taskId: string,
}) {
  return function ({ id }: { id: string }) {
    const content = getAttachedContent(id);
    if (!content) return null;

    const { name, type, duration, tier, moduleId } = content;

    let progress;
    let isCompleted = false;
    if (content.viewer === 'teacher') {
      progress = isDraft ? null : (
        <PostProgress
          progressCount={content.countInProgressStudents}
          color={subjectColor}
          studentsCount={content.countAssignedStudents}
          onClick={() => {
            openProgressReportDialog({
              post: { id, type, name },
              moduleId,
              taskId,
            });
          }}
          progress={null}
        />
      );
    } else {
      isCompleted = Boolean(content.progress);
      progress = (
        <PostProgress
          color={subjectColor}
          progress={content.progress}
          withStrengthIndicator={type === postTypes.challenge}
        />
      );
    }

    return (
      <AttachmentList.Attachment isFullWidth isTruncated={false} testHook="post-attachment">
        <PostSummary
          name={name}
          meta={<PostDuration duration={duration} type={type} challengeTier={tier} />}
          icon={
            <PostIcon
              postType={type}
              challengeTier={tier}
              isCompleted={isCompleted}
              color={isDraft ? 'colorIcon' : subjectColor}
            />
          }
          isCompleted={isCompleted}
          wrapper={<Flex grow={1} as={UnstyledLink} testHook="post-attachment-link" to={content.url} isExternal />}
          progress={progress}
        />
      </AttachmentList.Attachment>
    );
  };
}

export function renderEditableAtomiContent({
  getAttachedContent,
  subjectColor,
}: {
  getAttachedContent: (id: string) => ?AttachedContent,
  subjectColor: string,
}) {
  return function ({ id, onRemove }: { id: string, onRemove: () => mixed }) {
    const content = getAttachedContent(id);
    if (!content) return null;

    const { name, type, duration, url, tier } = content;

    return (
      <DraggbleContainer readOnly={false}>
        <AttachmentList.Attachment
          isFullWidth
          removeAssistiveText="Remove Atomi content"
          onRemove={onRemove}
          testHook="post-attachment"
        >
          <PostSummary
            name={name}
            meta={<PostDuration duration={duration} type={type} challengeTier={tier} />}
            icon={<PostIcon postType={type} challengeTier={tier} color={subjectColor} />}
            wrapper={<Flex grow={1} as={UnstyledLink} href={url} isExternal />}
          />
        </AttachmentList.Attachment>
      </DraggbleContainer>
    );
  };
}
