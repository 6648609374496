// @flow
import { useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { type ContextRouter, withRouter } from 'react-router';
import Helmet from 'react-helmet';
import {
  OptionGroup,
  OptionGroupItem,
  Container,
  Flex,
  Heading,
  IconBadge,
  IconBlog,
  IconClass,
  IconCustomers,
  IconHomeschool,
  IconRecommend,
  Label,
  Stack,
  Text,
  useId,
  useToast,
} from '@getatomi/neon';

import type { BoundAction, ReduxState, RegistrationRegionCode } from 'src/types';
import Button from 'src/components/Button/Button';
import NonAuthenticatedLayout from 'src/layouts/NonAuthenticatedLayout/NonAuthenticatedLayout';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import { register, updateRegistrationDetails } from 'src/actions/registration';
import type { RegistrationState } from 'src/reducers/registration';
import { parseValidationErrors } from 'src/api/validation';
import customerTypes from 'src/constants/authCustomerTypes';
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';
import { teacherOrParentReferralItems } from 'src/constants/referral';

import TermsLine from '../TermsLine/TermsLine';
import MarketingPanel from '../MarketingPanel/MarketingPanel';

const mapStateToProps = (state: ReduxState) => ({
  registrationDetails: state.registration,
});

type InjectedProps = {
  params: {
    region: RegistrationRegionCode,
  },
  registerAction: BoundAction<typeof register>,
  registrationDetails?: RegistrationState,
  router: ContextRouter,
  updateRegistrationDetailsAction: BoundAction<typeof updateRegistrationDetails>,
};

function RegistrationParentStep3(props: InjectedProps) {
  const {
    params: { region },
    registerAction,
    registrationDetails,
    router,
    updateRegistrationDetailsAction,
  } = props;
  const [intent, setIntent] = useState<Array<string>>([]);
  const [referral, setReferral] = useState<string | null>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<?Array<string>>(null);
  const optionGroupRef = useRef<null | OptionGroup>(null);
  const optionGroupLabelId = useId();
  const toast = useToast();

  useEffect(() => {
    if (!registrationDetails?.childFirstName) {
      router.replace(`/register/parent/${region}`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectIntent = useCallback((values) => {
    setShowError(false);
    setIntent(values);
  }, []);

  const onSubmit = async () => {
    setServerErrors(null);

    if (intent.length === 0) {
      // set the focus on the OptionGroup so that the validation text is read by screen readers
      if (optionGroupRef.current) {
        optionGroupRef.current.focus();
      }
      return setShowError(true);
    }

    setShowError(false);
    setIsSubmitting(true);
    try {
      updateRegistrationDetailsAction({ intent, referral });
      await registerAction({ region, customerType: customerTypes.family });
    } catch (error) {
      setIsSubmitting(false);
      const errors = parseValidationErrors(error);
      setServerErrors(errors);

      if (errors.length > 0) {
        return window.scrollTo(0, 0);
      }

      toast.error('There was an error submitting your registration. Please try again.');
    }
  };

  return (
    <NonAuthenticatedLayout sidePanel={<MarketingPanel />}>
      <Helmet>
        <title>Parent registration</title>
      </Helmet>
      <Stack spacing="spacingLarge1X">
        <Container textAlign="center" maxWidth="sizeContainerSmall">
          <Stack spacing="spacingLarge5X">
            <Heading as="h1">One last step to get you started!</Heading>

            <Stack>
              <Flex direction="column" alignItems="center">
                {serverErrors && <ValidationErrors errors={serverErrors} />}
                <Label id={optionGroupLabelId} as="p">
                  Why are you trialling Atomi with your child?
                </Label>
                <Text variant="bodySmall1X" color="colorTextSubtler">
                  Select all that apply
                </Text>
              </Flex>
              <OptionGroup
                ref={optionGroupRef}
                ariaLabelledBy={optionGroupLabelId}
                selectionMode="multiple"
                value={intent}
                onChange={!isSubmitting ? onSelectIntent : undefined}
                validationText={showError ? 'Please select at least one option' : undefined}
                errorVariant={showError ? 'error' : undefined}
                variant="square"
              >
                <OptionGroupItem icon={<IconHomeschool />} value="homeschooling">
                  Homeschooling
                </OptionGroupItem>
                <OptionGroupItem icon={<IconBlog />} value="homework help">
                  Homework help
                </OptionGroupItem>
                <OptionGroupItem icon={<IconCustomers />} value="tutoring alternative">
                  Tutoring alternative
                </OptionGroupItem>
                <OptionGroupItem icon={<IconBadge />} value="higher marks">
                  Higher marks
                </OptionGroupItem>
                <OptionGroupItem icon={<IconClass />} value="falling behind">
                  Falling behind
                </OptionGroupItem>
                <OptionGroupItem icon={<IconRecommend />} value="recommendation">
                  Recommendation
                </OptionGroupItem>
              </OptionGroup>
            </Stack>

            <Dropdown
              label="How did you hear about us?"
              secondaryLabel="Optional"
              name="referral"
              items={teacherOrParentReferralItems}
              selectedKey={referral}
              onSelectionChange={(selectedReferral: string) => {
                setReferral(selectedReferral);
              }}
            >
              {(item) => <Item key={item.value}>{item.label}</Item>}
            </Dropdown>

            <Button variant="legacy" isLoading={isSubmitting} onClick={onSubmit}>
              Start using Atomi
            </Button>
          </Stack>
        </Container>
        <Container>
          <TermsLine />
        </Container>
      </Stack>
    </NonAuthenticatedLayout>
  );
}

export default withRouter(
  connect(mapStateToProps, { registerAction: register, updateRegistrationDetailsAction: updateRegistrationDetails })(
    RegistrationParentStep3
  )
);
