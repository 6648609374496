// @flow
import { type LocationShape, type ContextRouter } from 'react-router';
import { useEffect } from 'react';
import invariant from 'invariant';
import Helmet from 'react-helmet';
import { Box, CompactPagination, Container, EmptyState, HelpInfo, Illustration } from '@getatomi/neon';

import useSubscriptions from 'src/hooks/useSubscriptions';
import broadcasting from 'src/constants/broadcasting';
import { useNavigationContext } from 'src/hooks/useNavigationContext';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import useTaskDialog from 'src/components/useTaskDialog/useTaskDialog';
import Button from 'src/components/Button/Button';
import Link from 'src/components/Link/Link';
import links from 'src/constants/links';
import { getClassTasksUrl } from 'src/utils/routes';

import ClassTasks from './ClassTasks';
import TaskList from '../components/TaskList';
import useGetClassTasks, { type TaskStatus } from './useGetClassTasks';
import ClassTasksNav from './ClassTasksNav';

export type Props = {|
  location: LocationShape,
  params: {
    classId: string,
    subscriptionId: string,
  },
  route: {
    status: TaskStatus,
  },
  router: ContextRouter,
|};

function getClassTasksVariables({ classId, paginationCursor, postId, status, subscriptionId }) {
  const variables = {
    accountId: subscriptionId,
    classId,
    ...paginationCursor,
  };

  const reverseChronologicalOrder = {
    column: 'DUE_AT',
    order: 'DESC',
  };

  if (postId) {
    return {
      ...variables,
      filters: {
        lessonId: postId,
      },
      orderBy: [reverseChronologicalOrder],
    };
  }

  return {
    ...variables,
    filters: {
      status,
    },
    // Show scheduled tasks in chronological order
    orderBy: status === 'scheduled' ? undefined : [reverseChronologicalOrder],
  };
}

export function ClassTasksContainer(props: Props) {
  const {
    params: { classId, subscriptionId },
    location: {
      query: { after, before, postId },
    },
    route: { status },
    router,
  } = props;
  const [, setNavigationContext] = useNavigationContext();

  function updatePageLocation(query) {
    router.push({
      pathname: getClassTasksUrl(subscriptionId, classId, status),
      query: {
        ...query,
        postId,
      },
    });
  }

  const queryVariables = getClassTasksVariables({
    classId,
    paginationCursor: { after, before },
    postId,
    status,
    subscriptionId,
  });

  const { loading, data, error, goToNextPage, goToPreviousPage } = useGetClassTasks({
    subscriptionId,
    variables: queryVariables,
    updatePageLocation,
  });

  useSubscriptions([
    { channel: `class.${classId}`, event: broadcasting.events.CLASS_TASKS.CREATED },
    { channel: `class.${classId}`, event: broadcasting.events.CLASS_TASKS.UPDATED },
    { channel: `class.${classId}`, event: broadcasting.events.CLASS_TASKS.DELETED },
    { channel: `class.${classId}`, event: broadcasting.events.CLASS_USER_PROGRESS.UPDATED },
  ]);

  const className = data?.class.name ?? 'Class';

  useEffect(() => {
    setNavigationContext({
      location: 'classTasks',
      metadata: {
        accountId: subscriptionId,
        classId,
        status,
        title: `${className} Tasks`,
      },
    });
  }, [subscriptionId, classId, className, setNavigationContext, status]);

  const [taskDialog, { openTaskDialog }] = useTaskDialog({
    subscriptionId,
    classId,
  });

  const title = <Helmet title="Tasks" />;

  const navigation = postId ? null : (
    <Box marginBottom="spacingLarge3X">
      <ClassTasksNav classId={classId} counts={data?.counts} subscriptionId={subscriptionId} />
    </Box>
  );

  if (loading) {
    return (
      <TaskList.Container>
        {title}
        {navigation}
        <TaskList.Loader />
        {taskDialog}
      </TaskList.Container>
    );
  }

  if (error) {
    return (
      <>
        {title}
        <GraphQLError error={error} description="We couldn’t load the tasks." />
      </>
    );
  }

  // QueryRenderProps makes all fields optional but at this point we should have data
  invariant(data, 'Tasks data should not be null');

  const totalTaskCount = data.counts.draft + data.counts.published + data.counts.scheduled;
  if (totalTaskCount === 0) {
    return (
      <Container>
        <EmptyState
          media={<Illustration name="emptystate-tasks" />}
          heading="You have not created any tasks yet."
          description="When you create a new task or start assigning lessons as tasks, they’ll show up here!"
          primaryAction={<Button onClick={openTaskDialog}>Create task</Button>}
        />
        <Box marginTop="spacingLarge4X">
          <HelpInfo>
            <Link href={links.support.task.creating} isExternal variant="monochrome">
              Need help creating tasks?
            </Link>
          </HelpInfo>
        </Box>
        {taskDialog}
      </Container>
    );
  }

  return (
    <TaskList.Container>
      {title}
      {navigation}
      <ClassTasks
        currentClass={data.class}
        isFreePlan={data.isFreePlan}
        isLessonFilterApplied={Boolean(postId)}
        openTaskDialog={openTaskDialog}
        region={data.region}
        router={router}
        status={status}
        subscriptionId={subscriptionId}
        tasksData={data.tasks}
      />
      {(goToNextPage || goToPreviousPage) && (
        <Container marginTop="spacingLarge6X">
          <CompactPagination onPreviousClick={goToPreviousPage} onNextClick={goToNextPage} />
        </Container>
      )}
      {taskDialog}
    </TaskList.Container>
  );
}

export default ClassTasksContainer;
