// @flow
import cx from 'classnames';
import { Box, Container, useMediaQuery } from '@getatomi/neon';

import type { MasterLayoutSpacingBetweenHeaderAndMainContent } from 'src/types';

import Main from './Main/Main';
import Footer from './Footer/Footer';
import styles from './MasterLayout.module.scss';

export type MasterLayoutProps = {|
  children: React.Node,
  header?: React.Node,
  isMainContentContained?: boolean,
  isMainContentFlexContainer?: boolean,
  isMainContentVerticallyCentered?: boolean,
  removeSpacingBetweenHeaderAndMainContent?: MasterLayoutSpacingBetweenHeaderAndMainContent,
  sidePanel?: React.Node,
  withBrandBackgroundColor?: boolean,
  withFixedHeader?: boolean,
  withFooter?: boolean,
  withSecondaryBackgroundColor?: boolean,
|};

export default function MasterLayout(props: MasterLayoutProps) {
  const {
    children,
    header,
    isMainContentContained = false,
    isMainContentFlexContainer = false,
    isMainContentVerticallyCentered = false,
    removeSpacingBetweenHeaderAndMainContent,
    sidePanel,
    withBrandBackgroundColor = false,
    withFixedHeader = true,
    withFooter = true,
    withSecondaryBackgroundColor = false,
  } = props;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const isTwoColumnsLayout = Boolean(sidePanel) && !isMobile;
  const mainColumnContent = (
    <>
      {header}
      <Main
        isContained={isMainContentContained}
        isFlexContainer={isMainContentFlexContainer}
        isVerticallyCentered={isMainContentVerticallyCentered}
        removeTopPadding={removeSpacingBetweenHeaderAndMainContent}
        withFooter={withFooter}
      >
        {children}
      </Main>
      {withFooter && <Footer />}
    </>
  );

  return (
    <div
      className={cx(styles.root, {
        [styles.withoutFixedHeader]: !withFixedHeader,
        [styles.isTwoColumnsLayout]: isTwoColumnsLayout,
        [styles.withBrandBackgroundColor]: withBrandBackgroundColor,
        [styles.withSecondaryBackgroundColor]: withSecondaryBackgroundColor,
      })}
    >
      {isTwoColumnsLayout ? (
        <Box display="grid" gridTemplateColumns="1fr 2fr" gridGap="spacingRoot">
          <Box
            as="aside"
            backgroundColor="colorBackgroundBrandSubtler"
            flexDirection="column"
            height="sizeFull"
            justifyContent="flexStart"
            paddingTop="spacingLarge9X"
            textAlign="center"
          >
            <Container maxWidth="sizeContainerSmall2X">{sidePanel}</Container>
          </Box>
          <div>{mainColumnContent}</div>
        </Box>
      ) : (
        mainColumnContent
      )}
    </div>
  );
}
