// @flow
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import Link from 'src/components/Link/Link';
import { getActiveSubscriptionId } from 'src/reducers/subscriptions';
import {
  getDashboardUrl,
  getClassUrl,
  getClassModuleUrl,
  getGettingStartedUrl,
  getPostUrl,
  getUpgradeUrl,
  getSubscriptionUsersUrl,
  getBillingUrl,
  getSchoolAccountUrl,
  getTasksUrl,
  getInsightsUrl,
  getRevisionPageUrl,
} from 'src/utils/routes';

const mapStateToProps = (state) => {
  return {
    subscriptionId: getActiveSubscriptionId(state),
  };
};

export type Params = {
  classId?: string | number,
  moduleId?: string | number,
  postId?: string | number,
};

type Props = Params & {
  billing?: boolean,
  children: ((url: string) => React.Node) | React.Node,
  className?: string,
  gettingStarted?: boolean,
  insights?: boolean,
  isUnstyled?: boolean,
  revision?: boolean,
  schoolAccount?: boolean,
  tasks?: boolean,
  testHook?: string,
  upgrade?: boolean,
  upgradeMonthly?: boolean,
  upgradeYearly?: boolean,
  users?: boolean,
};

type InjectedProps = Props & {
  dispatch: Dispatch<*>,
  subscriptionId: string | number,
};

function SubscriptionLink(props: InjectedProps) {
  const {
    classId,
    children,
    dispatch,
    gettingStarted,
    subscriptionId,
    schoolAccount,
    moduleId,
    postId,
    upgrade,
    upgradeMonthly,
    upgradeYearly,
    users,
    billing,
    tasks,
    insights,
    revision,
    testHook,
    ...otherProps
  } = props;

  if (!subscriptionId) return null;

  let to = getDashboardUrl(subscriptionId);

  if (classId && moduleId && postId) {
    to = getPostUrl(subscriptionId, classId, moduleId, postId);
  } else if (classId && moduleId) {
    to = getClassModuleUrl(subscriptionId, classId, moduleId);
  } else if (classId) {
    to = getClassUrl(subscriptionId, classId);
  } else if (upgrade) {
    to = getUpgradeUrl(subscriptionId);
  } else if (upgradeMonthly) {
    to = getUpgradeUrl(subscriptionId, 'month');
  } else if (upgradeYearly) {
    to = getUpgradeUrl(subscriptionId, 'year');
  } else if (users) {
    to = getSubscriptionUsersUrl({ subscriptionId });
  } else if (billing) {
    to = getBillingUrl(subscriptionId);
  } else if (schoolAccount) {
    to = getSchoolAccountUrl(subscriptionId);
  } else if (tasks) {
    to = getTasksUrl(subscriptionId);
  } else if (insights) {
    to = getInsightsUrl(subscriptionId);
  } else if (revision) {
    to = getRevisionPageUrl(subscriptionId);
  } else if (gettingStarted) {
    to = getGettingStartedUrl(subscriptionId);
  }

  if (typeof children === 'function') {
    return children(to);
  }

  return (
    <Link testHook={testHook} to={to} {...otherProps}>
      {children}
    </Link>
  );
}

export default (connect(mapStateToProps)(SubscriptionLink): React.AbstractComponent<Props>);
