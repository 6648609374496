// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import invariant from 'invariant';

import type { GetTaskLesson, GetTaskLessonVariables } from 'src/graphql/types/generated/GetTaskLesson';

import {
  getChallengeSession,
  isChallengeResumeFeatureEnabled,
  isCrossSubjectLesson,
  transformClass,
  transformLesson,
  type CurrentClass,
  type Lesson,
  type Task,
} from './transformers';
import GET_TASK_LESSON from './GetTaskLesson.graphql';

export type Props = {|
  classId: string,
  isStudent: boolean,
  lessonId: string,
  subscriptionId: string,
  taskId: string,
|};

export type Output = {|
  currentClass: ?CurrentClass,
  error: ?ApolloError,
  isChallengeInProgress: boolean,
  isChallengeResumeEnabled: boolean,
  isCrossSubject: boolean,
  lesson: ?Lesson,
  loading: boolean,
  moduleId: ?string,
  nextLesson: ?Lesson,
  previousLesson: ?Lesson,
  refetch: () => Promise<any>,
  region: ?string,
  task: ?Task,
|};

export default function useGetTaskLesson(props: Props): Output {
  const { classId, isStudent, lessonId, subscriptionId, taskId } = props;

  const { data, error, loading, refetch } = useQuery<GetTaskLesson, GetTaskLessonVariables>(GET_TASK_LESSON, {
    variables: {
      accountId: subscriptionId,
      classId,
      isStudent,
      lessonId,
      taskIds: [taskId],
      includeTaskForStudent: isStudent,
      includeTaskForNonStudent: !isStudent,
    },
  });

  if (error) {
    return {
      currentClass: null,
      error,
      isChallengeInProgress: false,
      isChallengeResumeEnabled: false,
      isCrossSubject: false,
      lesson: null,
      loading: false,
      moduleId: null,
      nextLesson: null,
      previousLesson: null,
      refetch,
      region: null,
      task: null,
    };
  }

  if (loading && !data) {
    return {
      currentClass: null,
      error: null,
      isChallengeInProgress: false,
      isChallengeResumeEnabled: false,
      isCrossSubject: false,
      lesson: null,
      loading: true,
      moduleId: null,
      nextLesson: null,
      previousLesson: null,
      refetch,
      region: null,
      task: null,
    };
  }

  const rawAccount = data?.me?.account;
  const rawClass = rawAccount?.class;
  const rawLesson = rawClass?.lesson;
  invariant(rawClass && rawLesson, 'Lesson data is missing');

  const taskData = isStudent ? rawAccount?.tasks?.edges[0]?.node : rawClass.tasks?.edges[0]?.node;
  invariant(taskId ? taskData : true, 'Task data should be defined');
  const task = taskData ? { id: taskData.id, title: taskData.title } : null;

  const lesson = { ...transformLesson(rawLesson), hasTasks: true };
  const currentClass = transformClass(rawClass);

  // TODO: PROD-9041 replace with task nextLesson and previousLesson
  const nextLesson = null;
  const previousLesson = null;

  const isCrossSubject = isCrossSubjectLesson(rawClass, rawLesson);
  const isChallengeResumeEnabled = isChallengeResumeFeatureEnabled(rawAccount);
  const challengeSession = getChallengeSession(rawLesson);
  const isChallengeInProgress = Boolean(challengeSession);
  const region = rawAccount?.region.code;
  const moduleId = lesson.categories[0].id;

  return {
    currentClass,
    error: null,
    isChallengeInProgress,
    isChallengeResumeEnabled,
    isCrossSubject,
    lesson,
    loading: false,
    moduleId,
    nextLesson,
    previousLesson,
    refetch,
    region,
    task,
  };
}
