// @flow
import { Box, Stack } from '@getatomi/neon';

type Props = {
  children: React.Node,
  testHook?: string,
};

export default function PostList(props: Props) {
  const { children, testHook } = props;

  if (!children) return null;

  return (
    <Box data-intercom="lesson-list">
      <Stack as="ol" testHook={testHook} spacing="spacingNegativeSmall4X">
        {children}
      </Stack>
    </Box>
  );
}
