// @flow
import { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { type ContextRouter, withRouter } from 'react-router';
import Helmet from 'react-helmet';
import {
  Box,
  Container,
  Heading,
  IconBadge,
  IconBrowserAdd,
  IconCircleHelp,
  IconFlipLeft,
  IconSubjectsCognitiveVerbs,
  IconUsersLove,
  Label,
  OptionGroup,
  OptionGroupItem,
  Stack,
  Text,
  useId,
  useToast,
} from '@getatomi/neon';

import { parseValidationErrors } from 'src/api/validation';
import { register, updateRegistrationDetails } from 'src/actions/registration';
import customerTypes from 'src/constants/authCustomerTypes';
import Button from 'src/components/Button/Button';
import NonAuthenticatedLayout from 'src/layouts/NonAuthenticatedLayout/NonAuthenticatedLayout';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import type { BoundAction, ReduxState, RegistrationRegionCode } from 'src/types';
import type { RegistrationState } from 'src/reducers/registration';
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';
import { teacherOrParentReferralItems } from 'src/constants/referral';

import TermsLine from '../TermsLine/TermsLine';
import MarketingPanel from '../MarketingPanel/MarketingPanel';

const mapStateToProps = (state: ReduxState) => ({
  registrationDetails: state.registration,
});

type InjectedProps = {
  params: {
    region: RegistrationRegionCode,
  },
  registerAction: BoundAction<typeof register>,
  registrationDetails: RegistrationState,
  router: ContextRouter,
  updateRegistrationDetailsAction: BoundAction<typeof updateRegistrationDetails>,
};

function RegistrationTeacherStep3(props: InjectedProps) {
  const {
    registrationDetails,
    registerAction,
    params: { region },
    router,
    updateRegistrationDetailsAction,
  } = props;

  const [showError, setShowError] = useState<boolean>(false);
  const [intent, setIntent] = useState<Array<string>>([]);
  const [referral, setReferral] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<Array<string> | null>(null);
  const optionGroupRef = useRef<OptionGroup | null>(null);
  const optionGroupLabelId = useId();
  const toast = useToast();

  // Redirect if Teacher didn't go through step 2
  useEffect(() => {
    if (!registrationDetails.accountName) {
      router.replace(`/register/teacher/${region}`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async () => {
    setServerErrors(null);

    if (intent.length === 0) {
      return setShowError(true);
    }

    setIsSubmitting(true);
    try {
      updateRegistrationDetailsAction({ intent, referral });
      await registerAction({ region, customerType: customerTypes.school });
    } catch (error) {
      setIsSubmitting(false);
      const errors = parseValidationErrors(error);
      setServerErrors(errors);

      if (errors.length > 0) {
        return window.scrollTo(0, 0);
      }

      toast.error('There was an error submitting your registration. Please try again.');
    }
  };

  return (
    <NonAuthenticatedLayout sidePanel={<MarketingPanel />}>
      <Helmet>
        <title>Teacher registration</title>
      </Helmet>
      <Stack spacing="spacingLarge1X">
        <Container textAlign="center" maxWidth="sizeContainerSmall">
          <Stack spacing="spacingLarge5X">
            <Heading as="h1">One last step to get you started!</Heading>

            <Stack>
              <Box display="flex" alignItems="center" flexDirection="column">
                {serverErrors && <ValidationErrors errors={serverErrors} />}
                <Label id={optionGroupLabelId} as="p">
                  Why are you trialling Atomi?
                </Label>
                <Text variant="bodySmall1X" color="colorTextSubtler">
                  Select all that apply
                </Text>
              </Box>
              <OptionGroup
                ref={optionGroupRef}
                ariaLabelledBy={optionGroupLabelId}
                selectionMode="multiple"
                value={intent}
                onChange={
                  !isSubmitting
                    ? (values) => {
                        setShowError(false);
                        setIntent(values);
                      }
                    : undefined
                }
                validationText={showError ? 'Please select at least one option' : undefined}
                errorVariant={showError ? 'error' : undefined}
                variant="square"
              >
                <OptionGroupItem icon={<IconUsersLove />} value="student engagement">
                  Student engagement
                </OptionGroupItem>
                <OptionGroupItem icon={<IconBadge />} value="improving results">
                  Improving results
                </OptionGroupItem>
                <OptionGroupItem icon={<IconSubjectsCognitiveVerbs />} value="smarter revision">
                  Smarter revision
                </OptionGroupItem>
                <OptionGroupItem icon={<IconFlipLeft />} value="learning style">
                  Blended or flipped learning
                </OptionGroupItem>
                <OptionGroupItem icon={<IconBrowserAdd />} value="additional resource">
                  Additional resource
                </OptionGroupItem>
                <OptionGroupItem icon={<IconCircleHelp />} value="not sure">
                  Not sure yet
                </OptionGroupItem>
              </OptionGroup>
            </Stack>

            <Dropdown
              label="How did you hear about us?"
              secondaryLabel="Optional"
              name="referral"
              items={teacherOrParentReferralItems}
              selectedKey={referral}
              onSelectionChange={(selectedReferral: string) => {
                setReferral(selectedReferral);
              }}
            >
              {(item) => <Item key={item.value}>{item.label}</Item>}
            </Dropdown>

            <Button variant="legacy" isLoading={isSubmitting} onClick={onSubmit}>
              Start using Atomi
            </Button>
          </Stack>
        </Container>
        <Container>
          <TermsLine />
        </Container>
      </Stack>
    </NonAuthenticatedLayout>
  );
}

export default withRouter(
  connect(mapStateToProps, { registerAction: register, updateRegistrationDetailsAction: updateRegistrationDetails })(
    RegistrationTeacherStep3
  )
);
