// @flow

export const studentReferralItems = [
  { key: 'facebook', label: 'Facebook' },
  { key: 'google', label: 'Google' },
  { key: 'youtube', label: 'YouTube' },
  { key: 'instagram', label: 'Instagram' },
  { key: 'snapchat', label: 'Snapchat' },
  { key: 'spotify', label: 'Spotify' },
  { key: 'tiktok', label: 'TikTok' },
  { key: 'twitter', label: 'Twitter' },
  { key: 'radio', label: 'Radio' },
  { key: 'word of mouth', label: 'Word of mouth' },
  { key: 'other', label: 'Other' },
];

export const teacherOrParentReferralItems = [
  { key: 'word of mouth', label: 'Word of mouth' },
  { key: 'conference or event', label: 'Conference or event' },
  { key: 'facebook', label: 'Facebook' },
  { key: 'instagram', label: 'Instagram' },
  { key: 'google or search engine', label: 'Google or search engine' },
  { key: 'youtube', label: 'YouTube' },
  { key: 'billboard', label: 'Billboard' },
  { key: 'podcast', label: 'Podcast' },
  { key: 'email', label: 'Email' },
  { key: 'other', label: 'Other' },
];
