// @flow
import {
  type SubjectColors,
  subjectColorsMap,
  subjectColorsGeneric,
  subjectColorsValues,
} from 'src/constants/subjectColors';

// Some subjects such as economics don't use the 'subject_group_code' colour but have a specific one
export default function getSubjectColors(subjectGroupCode: string, subjectColor: string): SubjectColors {
  const groupColorRange = subjectColorsMap[subjectGroupCode];
  const subjectColorLower = subjectColor.toLowerCase();

  if (groupColorRange && groupColorRange.icon !== subjectColorLower) {
    const subjectColorRange = subjectColorsValues.find((colors) => colors.icon === subjectColorLower);
    return subjectColorRange ?? subjectColorsGeneric;
  }

  return groupColorRange ?? subjectColorsGeneric;
}

// TODO: Is this code the same as the above? I.e. subjectGroupCode is redundant?
// export default function getSubjectColors(subjectGroupCode: string, subjectColor: string): SubjectColors {
//   const subjectColorLower = subjectColor.toLowerCase();
//   const subjectColors = subjectColorsValues.find((colors) => colors.icon === subjectColorLower);
//   return subjectColors ?? subjectColorsGeneric;
// }
