// @flow
import { RetryLink } from '@apollo/client/link/retry';

import Logger from 'src/utils/Logger';

const log = new Logger('src/graphql/client');

const retryOperations = ['CompleteLesson', 'CompleteAssessmentLesson', 'CompleteOnboardingItem'];
export const makeRetryLink = () =>
  new RetryLink({
    attempts: {
      max: 2,
      retryIf: (error, _operation) => {
        log.warn(`[RetryLink]: ${_operation.operationName} failed, retrying`, error);
        return retryOperations.includes(_operation.operationName);
      },
    },
    delay: {
      initial: 1000,
      max: Infinity,
      jitter: true,
    },
  });
