// @flow
import { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Stack, Text, TextField, useMediaQuery } from '@getatomi/neon';
import invariant from 'invariant';

import Button from 'src/components/Button/Button';
import type { BoundAction } from 'src/types';
import FormMessage from 'src/components/FormMessage/FormMessage';
import Link from 'src/components/Link/Link';
import useAlertDialog from 'src/hooks/components/useAlertDialog';
import { joinSubscription } from 'src/actions/subscriptions';
import { getSubscriptionUrl } from 'src/utils/routes';

type InjectedProps = {
  joinSubscriptionAction: BoundAction<typeof joinSubscription>,
};

function JoinSubscriptionDialog(props: InjectedProps) {
  const [chargebeeId, setChargebeeId] = useState<?string>(null);
  const [error, setError] = useState<?string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<?boolean>(false);
  const [subscriptionUrl, setSubscriptionUrl] = useState<?string>(null);
  const [successPrompt, showSuccessPrompt] = useAlertDialog({
    heading: 'Subscription joined',
    children: (
      <>
        <p>All done, you’ve been added to this subscription.</p>
        {subscriptionUrl && (
          <>
            <Link href={subscriptionUrl}>Click here</Link> to view this subscription.
          </>
        )}
      </>
    ),
    onConfirmLabel: 'OK thanks',
    variant: 'success',
    isDismissable: false,
    autoFocusButton: 'confirm',
  });
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const onInputChange = (value: string) => {
    setChargebeeId(value.trim());
  };

  const toggleDialog = () => {
    setIsDialogOpen((isOpen) => !isOpen);
    setError(null);
  };

  const onSubmit = async (e: SyntheticEvent<>) => {
    e.preventDefault();
    setIsLoading(true);
    invariant(chargebeeId, 'Chargebee ID should be defined');
    try {
      const res = await props.joinSubscriptionAction(chargebeeId);
      setIsLoading(false);
      setSubscriptionUrl(getSubscriptionUrl(res));
      setIsDialogOpen(false);
      setChargebeeId(null);
      showSuccessPrompt();
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  };

  return (
    <div>
      <Button onClick={toggleDialog}>Join account</Button>
      <Modal
        actions={
          <>
            <Button isFullWidth={isMobile} size="small" variant={isMobile ? 'tertiary' : 'text'} onClick={toggleDialog}>
              Cancel
            </Button>
            <Button
              isDisabled={!chargebeeId}
              isFullWidth={isMobile}
              isLoading={isLoading}
              onClick={onSubmit}
              size="small"
            >
              Join
            </Button>
          </>
        }
        heading="Join account"
        isOpen={isDialogOpen}
        onClose={toggleDialog}
      >
        <form onSubmit={onSubmit}>
          <Stack>
            {error && <FormMessage>{error}</FormMessage>}
            <Text>Enter the Chargebee subscription ID below to join an account.</Text>
            <TextField
              label="Chargebee ID"
              name="chargebeeId"
              isRequired
              onChange={onInputChange}
              autoFocus // eslint-disable-line jsx-a11y/no-autofocus
            />
          </Stack>
        </form>
      </Modal>
      {successPrompt}
    </div>
  );
}

export default (connect(null, { joinSubscriptionAction: joinSubscription })(
  JoinSubscriptionDialog
): React.AbstractComponent<{}>);
