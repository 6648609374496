// @flow
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useToast } from '@getatomi/neon';

import type { SetTaskCompletion, SetTaskCompletionVariables } from 'src/graphql/types/generated/SetTaskCompletion';
import SET_TASK_COMPLETION from 'src/domains/Tasks/StudentTasks/SetTaskCompletion.graphql';
import Logger from 'src/utils/Logger';
import { trackEvent } from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';
import StudentTaskCompletionToggle from 'src/domains/Tasks/components/StudentTaskCompletionToggle/StudentTaskCompletionToggle';
import { type SubjectColors } from 'src/constants/subjectColors';

import type { Task } from './useGetStudentTask';

const log = new Logger('domains/Tasks/Task');

function useTaskCompletion() {
  const toast = useToast();
  const [setTaskCompletion] = useMutation<SetTaskCompletion, SetTaskCompletionVariables>(SET_TASK_COMPLETION);

  const toggleTaskCompletion = useCallback(
    async (id, isCompleted) => {
      trackEvent(trackingEvents.taskUpdateCompletionRequested, { id, isCompleted });
      try {
        await setTaskCompletion({
          variables: ({ input: { id, isCompleted } }: $Exact<SetTaskCompletionVariables>),
          // set expected response to provide faster UI feedback
          // https://www.apollographql.com/docs/react/performance/optimistic-ui
          optimisticResponse: {
            toggleTaskCompletion: {
              __typename: 'StudentTask',
              id,
              progress: {
                __typename: 'StudentTaskProgress',
                status: isCompleted ? 'COMPLETE' : 'PENDING',
              },
            },
          },
        });
      } catch (err) {
        log.error('Error setting the task completion status', err);
        toast.error('There was an error changing the task completion status. Please try again.');
      }
    },
    [setTaskCompletion, toast]
  );

  return toggleTaskCompletion;
}

type StudentTaskProps = {|
  subjectColors: SubjectColors,
  taskData: Task,
|};
function StudentTask(props: StudentTaskProps) {
  const { subjectColors, taskData } = props;
  const { isComplete } = taskData;

  const toggleTaskCompletion = useTaskCompletion();

  return (
    <StudentTaskCompletionToggle
      colors={subjectColors}
      isCompleted={isComplete}
      isDisabled={taskData.autoComplete || taskData.contents.some((content) => content.progress === null)}
      onClick={() => {
        toggleTaskCompletion(taskData.taskId, !isComplete);
      }}
    />
  );
}

export default StudentTask;
