// @flow
import { IconViewGrid, IconViewList, SegmentedControl, SegmentedControlItem, useMediaQuery } from '@getatomi/neon';

export type ViewMode = 'grid' | 'list';

export type ViewModeToggleProps = {|
  onChange: (viewMode: ViewMode) => void,
  selectedMode: ViewMode,
|};

export default function ViewModeToggle(props: ViewModeToggleProps) {
  const { onChange, selectedMode } = props;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  if (isMobile) {
    return null;
  }

  return (
    <SegmentedControl ariaLabel="View mode" value={selectedMode} onChange={onChange}>
      <SegmentedControlItem ariaLabel="Grid" iconBefore={IconViewGrid} id="grid" />
      <SegmentedControlItem ariaLabel="List" iconBefore={IconViewList} id="list" />
    </SegmentedControl>
  );
}
