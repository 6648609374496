// @flow
import invariant from 'invariant';

import postTypes from 'src/constants/postTypes';
import challengeTiers from 'src/constants/challengeTiers';
import type { ClassLessonInfo } from 'src/graphql/types/generated/ClassLessonInfo';
import type { StudentLessonInfo } from 'src/graphql/types/generated/StudentLessonInfo';
import type {
  GetModuleLesson_me_account as GetLessonAccount,
  GetModuleLesson_me_account_class as GetLessonClass,
  GetModuleLesson_me_account_class_lesson as GetLessonClassLesson,
  GetModuleLesson_me_account_class_metrics as GetLessonClassMetrics,
} from 'src/graphql/types/generated/GetModuleLesson';
import type {
  GetTaskLesson_me_account as GetTaskLessonAccount,
  GetTaskLesson_me_account_class as GetTaskLessonClass,
  GetTaskLesson_me_account_class_lesson as GetTaskLessonClassLesson,
  GetTaskLesson_me_account_class_metrics as GetTaskLessonClassMetrics,
} from 'src/graphql/types/generated/GetTaskLesson';
import type { ChallengeTier, PostType } from 'src/types';

type Category = {|
  id: string,
  name: string,
  parentId: ?string,
|};

export type Challenge = {|
  id: string,
  questionsCount: number,
  title: string,
  totalMarks: number,
|};

type BaseLesson = {
  // Base lesson types are needed to match the raw lesson data being provided by consuming query hooks
  +__typename: 'BaseChallengeLesson' | 'BaseTextLesson' | 'BaseVideoLesson',
};

export type GqlLesson = ClassLessonInfo | StudentLessonInfo | BaseLesson;

export type Lesson = {|
  categories: $ReadOnlyArray<Category>,
  challenge?: Challenge,
  completionCount: number,
  /**
   * A description is used by all three lesson types:
   *
   * challenge - serves as a curriculum descriptor
   * text - serves as the main text lesson body
   * video - serves as a video description
   */
  description: ?string,
  duration: number,
  hasTasks?: boolean,
  id: string,
  isLocked: boolean,
  name: string,
  studentStrength?: number,
  tier?: ChallengeTier,
  type: PostType,
  videoId?: string,
|};

export type Subject = {|
  code: string,
  color: string,
  groupCode: string,
  name: string,
|};

export type CurrentClass = {|
  id: string,
  level: ?{
    id: string,
    name: string,
  },
  name: string,
  studentCount: number,
  subject: Subject,
|};

export type Task = { id: string, title: ?string };

type GqlCategory = { +id: string, +name: string, +parentId: ?string };

function getStudentCount(metrics: ?GetLessonClassMetrics | ?GetTaskLessonClassMetrics): number {
  if (metrics && metrics.__typename === 'ClassMetrics') {
    return metrics.studentCount;
  }

  return 0;
}

export function transformClass(rawClass: GetLessonClass | GetTaskLessonClass): CurrentClass {
  return {
    id: rawClass.id,
    level: rawClass.level && {
      id: rawClass.level.id,
      name: rawClass.level.name,
    },
    name: rawClass.name,
    studentCount: getStudentCount(rawClass.metrics),
    subject: {
      code: rawClass.subject.code,
      color: rawClass.subject.color,
      groupCode: rawClass.subject.groupCode,
      name: rawClass.subject.shortName,
    },
  };
}

function transformCategories(gqlCategories: $ReadOnlyArray<GqlCategory>): $ReadOnlyArray<Category> {
  return gqlCategories.map(({ id, name, parentId }: GqlCategory): Category => ({ id, name, parentId }));
}

function getCompletionCount(rawLesson: GqlLesson): number {
  switch (rawLesson.__typename) {
    case 'ClassChallengeLesson':
    case 'ClassTextLesson':
    case 'ClassVideoLesson': {
      return rawLesson.metrics.progress.engagedStudentCount;
    }
    case 'StudentChallengeLesson':
    case 'StudentTextLesson':
    case 'StudentVideoLesson': {
      return rawLesson.metrics.progress.cumulativeCompletionCount;
    }
    case 'ClassLockedChallengeLesson':
    case 'ClassLockedTextLesson':
    case 'ClassLockedVideoLesson':
    case 'StudentLockedChallengeLesson':
    case 'StudentLockedTextLesson':
    case 'StudentLockedVideoLesson': {
      return 0;
    }
    default: {
      throw new Error(`Unexpected lesson type: ${rawLesson.__typename}`);
    }
  }
}

export function transformLesson(rawLesson: GqlLesson): Lesson {
  invariant(
    rawLesson.__typename === 'ClassChallengeLesson' ||
      rawLesson.__typename === 'ClassLockedChallengeLesson' ||
      rawLesson.__typename === 'ClassVideoLesson' ||
      rawLesson.__typename === 'ClassLockedVideoLesson' ||
      rawLesson.__typename === 'ClassTextLesson' ||
      rawLesson.__typename === 'ClassLockedTextLesson' ||
      rawLesson.__typename === 'StudentChallengeLesson' ||
      rawLesson.__typename === 'StudentLockedChallengeLesson' ||
      rawLesson.__typename === 'StudentVideoLesson' ||
      rawLesson.__typename === 'StudentLockedVideoLesson' ||
      rawLesson.__typename === 'StudentTextLesson' ||
      rawLesson.__typename === 'StudentLockedTextLesson',
    `Unexpected lesson type: ${rawLesson.__typename}`
  );

  const categories = transformCategories(rawLesson.categories);

  const completionCount = getCompletionCount(rawLesson);

  const commonLessonFields = {
    id: rawLesson.id,
    categories,
    completionCount,
  };

  switch (rawLesson.__typename) {
    case 'ClassChallengeLesson':
    case 'StudentChallengeLesson': {
      const { questionsCount, totalMarks } = rawLesson.challenge;

      const challenge: Challenge = {
        id: rawLesson.challenge.id,
        title: rawLesson.challenge.title,
        questionsCount,
        totalMarks,
      };

      const tier: ChallengeTier = challengeTiers[rawLesson.tier];

      const studentStrength =
        rawLesson.__typename === 'StudentChallengeLesson' ? rawLesson?.metrics.assessment.strength ?? 0 : undefined;

      return {
        ...commonLessonFields,
        type: postTypes.challenge,
        name: rawLesson.name,
        description: rawLesson.description,
        duration: rawLesson.duration,
        challenge,
        isLocked: false,
        studentStrength,
        tier,
      };
    }

    case 'ClassTextLesson':
    case 'StudentTextLesson': {
      return {
        ...commonLessonFields,
        type: postTypes.text,
        name: rawLesson.name,
        description: rawLesson.description,
        duration: rawLesson.duration,
        isLocked: false,
      };
    }
    case 'ClassVideoLesson':
    case 'StudentVideoLesson': {
      return {
        ...commonLessonFields,
        type: postTypes.video,
        name: rawLesson.name,
        description: rawLesson.description,
        duration: rawLesson.duration,
        isLocked: false,
        videoId: rawLesson.videoId,
      };
    }
    case 'ClassLockedTextLesson':
    case 'StudentLockedTextLesson': {
      return {
        ...commonLessonFields,
        type: postTypes.text,
        name: '',
        description: '',
        duration: 0,
        isLocked: true,
      };
    }
    case 'ClassLockedChallengeLesson':
    case 'StudentLockedChallengeLesson': {
      return {
        ...commonLessonFields,
        type: postTypes.challenge,
        name: '',
        description: '',
        duration: 0,
        isLocked: true,
      };
    }
    case 'ClassLockedVideoLesson':
    case 'StudentLockedVideoLesson': {
      return {
        ...commonLessonFields,
        type: postTypes.video,
        name: '',
        description: '',
        duration: 0,
        isLocked: true,
      };
    }
    default: {
      throw new Error(`Unknown lesson type: ${rawLesson.__typename}`);
    }
  }
}

export function isChallengeResumeFeatureEnabled(account?: GetLessonAccount | GetTaskLessonAccount): boolean {
  const enabledFeatures = account?.enabledFeatures;
  return enabledFeatures ? enabledFeatures.includes('CHALLENGE_RESUME') : false;
}

export function isCrossSubjectLesson(
  rawClass: GetLessonClass | GetTaskLessonClass,
  rawLesson: GetLessonClassLesson | GetTaskLessonClassLesson
): boolean {
  invariant(
    rawLesson.__typename === 'ClassChallengeLesson' ||
      rawLesson.__typename === 'ClassLockedChallengeLesson' ||
      rawLesson.__typename === 'ClassVideoLesson' ||
      rawLesson.__typename === 'ClassLockedVideoLesson' ||
      rawLesson.__typename === 'ClassTextLesson' ||
      rawLesson.__typename === 'ClassLockedTextLesson' ||
      rawLesson.__typename === 'StudentChallengeLesson' ||
      rawLesson.__typename === 'StudentLockedChallengeLesson' ||
      rawLesson.__typename === 'StudentVideoLesson' ||
      rawLesson.__typename === 'StudentLockedVideoLesson' ||
      rawLesson.__typename === 'StudentTextLesson' ||
      rawLesson.__typename === 'StudentLockedTextLesson',
    `Unexpected lesson type: ${rawLesson.__typename}`
  );

  return rawClass.subject.code !== rawLesson.subject.code;
}

export function getChallengeSession(rawLesson: GetLessonClassLesson | GetTaskLessonClassLesson) {
  return rawLesson.__typename === 'ClassChallengeLesson' || rawLesson.__typename === 'StudentChallengeLesson'
    ? rawLesson?.challengeSession
    : null;
}
