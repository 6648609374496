// @flow
import { Box, Container, TextPost, Heading, useId } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import PostDuration from 'src/components/PostDuration/PostDuration';

import PostVideoPlayer from '../PostVideoPlayer/PostVideoPlayer';
import type { Lesson, CurrentClass } from '../useGetModuleLesson';

type VideoLessonProps = {|
  actions: React.Node,
  currentClass: CurrentClass,
  embedWidth: number,
  isLti: boolean,
  isPostEmbedded: boolean,
  lesson: Lesson,
  maximumContainerSize: string | number,
  pagination: React.Node,
  subscriptionId: string,
|};

export default function VideoLesson(props: VideoLessonProps) {
  const {
    actions,
    embedWidth,
    lesson,
    currentClass,
    maximumContainerSize,
    isLti,
    isPostEmbedded,
    pagination,
    subscriptionId,
  } = props;

  const headingId = useId();

  return (
    <>
      <Container
        marginBottom="spacingLarge"
        maxWidth={isPostEmbedded ? embedWidth : undefined}
        paddingInline={{ base: 'spacingNone', tablet: 'spacingRoot' }}
      >
        <PostVideoPlayer
          classId={currentClass.id}
          pagination={pagination}
          lesson={lesson}
          levelId={currentClass.level?.id}
          subject={currentClass.subject}
          isEmbed={isPostEmbedded || isLti}
          subscriptionId={subscriptionId}
        />
      </Container>

      <Container marginBottom="spacingLarge5X" maxWidth={maximumContainerSize}>
        <Heading as="h1" id={headingId} marginBottom="spacingSmall">
          <Box as="span" marginRight="spacingSmall">
            {lesson.name}
          </Box>
          <Box
            as="span"
            display="inline-block"
            fontSize="fontSizeLarge"
            color="colorTextSubtler"
            fontWeight="fontWeightLight"
          >
            <PostDuration duration={lesson.duration} type={lesson.type} />
          </Box>
        </Heading>

        {actions}
      </Container>

      <Container maxWidth={maximumContainerSize} marginBottom="spacingLarge8X">
        <TextPost ariaLabelledBy={headingId} linkComponent={Link} testHook="post-long-form-copy">
          {lesson.description}
        </TextPost>
      </Container>
    </>
  );
}
