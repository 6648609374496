// @flow
import { Box, Container, TextPost, Heading, useId } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import PostDuration from 'src/components/PostDuration/PostDuration';

import TextPostProgress from '../TextPostProgress/TextPostProgress';
import type { Lesson, CurrentClass } from '../useGetModuleLesson';

type TextLessonProps = {|
  actions: React.Node,
  currentClass: CurrentClass,
  isLoggedInAsStudent: boolean,
  lesson: Lesson,
  maximumContainerSize: string | number,
  pagination: React.Node,
  subscriptionId: string,
|};

export default function TextLesson(props: TextLessonProps) {
  const { actions, lesson, currentClass, isLoggedInAsStudent, pagination, maximumContainerSize, subscriptionId } =
    props;

  const headingId = useId();

  return (
    <>
      {isLoggedInAsStudent && (
        // This needs to be a direct child of the enclosing container otherwise position:
        // sticky won't work. The built-in `key` attribute is used to tell React to create a
        // new instance of this component when rendering a different post (e.g. navigating to
        // the next/previous post)
        // https://reactjs.org/docs/reconciliation.html#keys
        //
        <TextPostProgress
          key={lesson.id}
          classId={currentClass.id}
          levelId={currentClass.level?.id}
          lesson={lesson}
          subject={currentClass.subject}
          subscriptionId={subscriptionId}
        />
      )}

      <Container maxWidth={maximumContainerSize} marginBottom="spacingLarge5X">
        <Heading as="h1" id={headingId} marginBottom="spacingSmall">
          <Box as="span" marginRight="spacingSmall">
            {lesson.name}
          </Box>
          <Box
            as="span"
            display="inline-block"
            fontSize="fontSizeLarge"
            color="colorTextSubtler"
            fontWeight="fontWeightLight"
          >
            <PostDuration duration={lesson.duration} type={lesson.type} />
          </Box>
        </Heading>

        {actions}
      </Container>

      <Container maxWidth={maximumContainerSize} marginBottom="spacingLarge8X">
        <TextPost ariaLabelledBy={headingId} linkComponent={Link} testHook="post-long-form-copy">
          {lesson.description}
        </TextPost>
      </Container>

      {pagination && <Container>{pagination}</Container>}
    </>
  );
}
