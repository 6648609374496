// @flow
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Box, Container, Flex, useMediaQuery } from '@getatomi/neon';

import type { Subscription, UserType } from 'src/types';
import {
  isLoggedInAsSuperAdmin as isLoggedInAsSuperAdminSelector,
  isUserLoggedIn as isUserLoggedInSelector,
} from 'src/reducers/auth';
import {
  getActiveSubscription,
  getUserOtherActiveAndPendingSubscriptions,
  getTrialEndDate,
  isFreeFamilyPlan as isFreeFamilyPlanSelector,
  isFreeStudentPlan as isFreeStudentPlanSelector,
  isFreeSchoolPlan as isFreeSchoolPlanSelector,
  isInsightsEnabled as isInsightsEnabledSelector,
  isLoggedInAsAccountManager as isLoggedInAsAccountManagerSelector,
  isLoggedInAsChild as isLoggedInAsChildSelector,
  isLoggedInAsParent as isLoggedInAsParentSelector,
  isLoggedInAsStudent as isLoggedInAsStudentSelector,
  isLoggedInAsTeacher as isLoggedInAsTeacherSelector,
  isRetailPlan as isRetailPlanSelector,
  isPaidSchoolTrialSubscription,
  isPaidSchoolPlan as isPaidSchoolPlanSelector,
  isSubscriptionCancelled as isSubscriptionCancelledSelector,
  isAutoCollectionOn as isAutoCollectionOnSelector,
} from 'src/reducers/subscriptions';
import Auth from 'src/utils/Auth';
import useIntercomWidget from 'src/hooks/useIntercomWidget';
import useBodyClass from 'src/hooks/useBodyClass';
import usePreventScrollObserver from 'src/hooks/usePreventScrollObserver';
import { LoadingBar } from 'src/components/LoadingBar/LoadingBar';

import AccountMenu from './AccountMenu/AccountMenu';
import Alerts from './Alerts/Alerts';
import Logo from './Logo/Logo';
import MobileBackLink from './MobileBackLink/MobileBackLink';
import MobileDialog from './MobileDialog/MobileDialog';
import Nav from './Nav/Nav';
import TrialExpiryBadge from './TrialExpiryBadge/TrialExpiryBadge';
import UpgradeCta from './UpgradeCta/UpgradeCta';
import styles from './Header.module.scss';
import EmbedButton from './EmbedButton/EmbedButton';
import HelpMenu from './HelpMenu/HelpMenu';

const mapStateToProps = (state) => {
  const isFreeStudentPlan = isFreeStudentPlanSelector(state);
  const subscription = _.pick(getActiveSubscription(state), ['id', 'name', 'plan_code', 'plan_customer_type']);
  const isSuperAdmin = isLoggedInAsSuperAdminSelector(state);

  return {
    canViewInsights: isInsightsEnabledSelector(state) || isSuperAdmin,
    isFreeSchoolPlan: isFreeSchoolPlanSelector(state),
    isFreeFamilyPlan: isFreeFamilyPlanSelector(state),
    isFreeStudentPlan,
    isLoggedInAsAccountManager: isLoggedInAsAccountManagerSelector(state),
    isLoggedInAsChild: isLoggedInAsChildSelector(state),
    isLoggedInAsParent: isLoggedInAsParentSelector(state),
    isLoggedInAsStudent: isLoggedInAsStudentSelector(state),
    isLoggedInAsSuperAdmin: isLoggedInAsSuperAdminSelector(state),
    isLoggedInAsTeacher: isLoggedInAsTeacherSelector(state),
    isOnTrial: isPaidSchoolTrialSubscription(state),
    isRetailPlan: isRetailPlanSelector(state),
    isPaidSchoolPlan: isPaidSchoolPlanSelector(state),
    isAutoCollectionOn: isAutoCollectionOnSelector(state),
    isSubscriptionCancelled: isSubscriptionCancelledSelector(state),
    isUserLoggedIn: isUserLoggedInSelector(state),
    otherSubscriptions: getUserOtherActiveAndPendingSubscriptions(state),
    subscription,
    trialEndDate: getTrialEndDate(state),
    user: state.auth.user,
  };
};

type Props = {
  isEmbed?: boolean,
  withMobileBackLink?: boolean,
  withNav?: boolean,
  withTrialExpiry?: boolean,
  withUpgradeCta?: boolean,
  withUserDropdown?: boolean,
};

type InjectedProps = Props & {
  canViewInsights: boolean,
  isAutoCollectionOn: boolean,
  isFreeFamilyPlan: boolean,
  isFreeSchoolPlan: boolean,
  isFreeStudentPlan: boolean,
  isLoggedInAsAccountManager: boolean,
  isLoggedInAsChild: boolean,
  isLoggedInAsParent: boolean,
  isLoggedInAsStudent: boolean,
  isLoggedInAsSuperAdmin: boolean,
  isLoggedInAsTeacher: boolean,
  isOnTrial: boolean,
  isPaidSchoolPlan: boolean,
  isRetailPlan: boolean,
  isSubscriptionCancelled: boolean,
  isUserLoggedIn: boolean,
  otherSubscriptions: Array<Subscription>,
  subscription: Subscription,
  trialEndDate: ?string,
  user: UserType,
};

function Header(props: InjectedProps) {
  const {
    canViewInsights,
    isRetailPlan,
    isFreeSchoolPlan,
    isFreeFamilyPlan,
    isFreeStudentPlan,
    isLoggedInAsAccountManager,
    isAutoCollectionOn,
    isLoggedInAsChild,
    isLoggedInAsParent,
    isLoggedInAsStudent,
    isLoggedInAsSuperAdmin,
    isLoggedInAsTeacher,
    isPaidSchoolPlan,
    isSubscriptionCancelled,
    isOnTrial,
    isUserLoggedIn,
    otherSubscriptions,
    subscription,
    trialEndDate,
    user,
    withMobileBackLink,
    withNav = true,
    withTrialExpiry = true,
    withUpgradeCta = true,
    withUserDropdown = true,
    isEmbed,
  } = props;
  const auth = new Auth();
  const [isMobileDialogOpen, setIsMobileDialogOpen] = useState(false);
  const [showIntercom, hideIntercom] = useIntercomWidget();
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const { scrollbarWidth } = usePreventScrollObserver();
  useBodyClass(styles.isOverlayOpen, isMobileDialogOpen);

  useEffect(() => {
    isMobileDialogOpen ? hideIntercom() : showIntercom();
  }, [hideIntercom, isMobileDialogOpen, showIntercom]);

  if (!isUserLoggedIn) return null;

  const onMobileDialogToggle = () => setIsMobileDialogOpen((isOpen) => !isOpen);
  const onMobileDialogClose = () => setIsMobileDialogOpen(false);

  const withTrialExpiryTag =
    isAutoCollectionOn && isOnTrial && withTrialExpiry && !isSubscriptionCancelled && trialEndDate;
  const isFreePlan = isFreeStudentPlan || isFreeFamilyPlan || isFreeSchoolPlan;

  return (
    <div className={styles.root}>
      <Box as="header" className={styles.header} paddingRight={scrollbarWidth}>
        <LoadingBar />
        <Container maxWidth="sizeFull">
          <div className={styles.headerInner}>
            {/* LEFT container */}
            <Flex alignItems="center">
              {/* Non-mobile logo */}
              <Logo isMobile={false} isEmbed={isEmbed} />

              {/** Mobile back link or mobile logo **/}
              {!withMobileBackLink && !isEmbed && <Logo />}
              {withMobileBackLink && <MobileBackLink />}

              {/** Non-mobile nav **/}
              {(withNav || withUserDropdown) && (
                <Nav
                  isMobile={false}
                  onNavigate={onMobileDialogClose}
                  onLogout={() => auth.logoutAndRedirect()}
                  isRetailPlan={isRetailPlan}
                  isLoggedInAsParent={isLoggedInAsParent}
                  isLoggedInAsStudent={isLoggedInAsStudent}
                  isLoggedInAsTeacher={isLoggedInAsTeacher}
                  isPaidSchoolPlan={isPaidSchoolPlan}
                  canViewInsights={canViewInsights}
                />
              )}

              {/** Trial expiry tag **/}
              {withTrialExpiryTag && trialEndDate && (
                <Box display={{ base: 'none', desktop: 'block' }} marginLeft="spacingLarge2X">
                  <TrialExpiryBadge trialEndDate={trialEndDate} />
                </Box>
              )}
            </Flex>

            {/** RIGHT container **/}
            <Flex alignItems="center" gap="spacingLarge2X">
              {/** Upgrade CTA **/}
              {!isMobile && withUpgradeCta && isFreePlan && (
                <UpgradeCta
                  size="small1X"
                  isChild={isLoggedInAsChild}
                  isRetail={isFreeStudentPlan || isFreeFamilyPlan}
                  onNavigate={onMobileDialogClose}
                  testHook="upgrade-button"
                />
              )}

              {!isMobile && (
                <HelpMenu
                  accountId={String(subscription.id)}
                  isFreeSchoolPlan={isFreeSchoolPlan}
                  isLoggedInAsParent={isLoggedInAsParent}
                  isLoggedInAsStudent={isLoggedInAsStudent}
                  isLoggedInAsTeacher={isLoggedInAsTeacher}
                />
              )}

              {/** Mobile dialog **/}
              {(withNav || withUserDropdown) && (
                <MobileDialog
                  canViewInsights={canViewInsights}
                  isFreeSchoolPlan={isFreeSchoolPlan}
                  isFreeStudentPlan={isFreeStudentPlan}
                  isFreeFamilyPlan={isFreeFamilyPlan}
                  isLoggedInAsAccountManager={isLoggedInAsAccountManager}
                  isLoggedInAsChild={isLoggedInAsChild}
                  isLoggedInAsSuperAdmin={isLoggedInAsSuperAdmin}
                  isLoggedInAsParent={isLoggedInAsParent}
                  isLoggedInAsStudent={isLoggedInAsStudent}
                  isLoggedInAsTeacher={isLoggedInAsTeacher}
                  isOnTrial={isOnTrial}
                  isOpen={isMobileDialogOpen}
                  isPaidSchoolPlan={isPaidSchoolPlan}
                  isRetailPlan={isRetailPlan}
                  isSubscriptionCancelled={isSubscriptionCancelled}
                  onLogout={() => auth.logoutAndRedirect()}
                  onNavigate={onMobileDialogClose}
                  onToggle={onMobileDialogToggle}
                  otherSubscriptions={otherSubscriptions}
                  subscription={subscription}
                  trialEndDate={trialEndDate}
                  user={user}
                  withUpgradeCta={withUpgradeCta}
                />
              )}

              {/** User dropdown (non-mobile only) **/}
              {!isMobile && withUserDropdown && (
                <AccountMenu
                  isFreeFamilyPlan={isFreeFamilyPlan}
                  isFreeSchoolPlan={isFreeSchoolPlan}
                  isFreeStudentPlan={isFreeStudentPlan}
                  isLoggedInAsAccountManager={isLoggedInAsAccountManager}
                  isLoggedInAsChild={isLoggedInAsChild}
                  isLoggedInAsParent={isLoggedInAsParent}
                  isLoggedInAsSuperAdmin={isLoggedInAsSuperAdmin}
                  otherSubscriptions={otherSubscriptions}
                  subscription={subscription}
                  user={user}
                  withUpgradeCta={withUpgradeCta}
                />
              )}

              {/** Open full app in new tab **/}
              {isEmbed && <EmbedButton />}

              {/** Render post embed button via portal **/}
              {/* Avoids the parent Flex from adding a gap when empty */}
              <Box id="embed-portal-destination" _empty={{ display: 'none' }} />
            </Flex>
          </div>
        </Container>
      </Box>

      <Alerts />
    </div>
  );
}

export default (connect(mapStateToProps, {})(Header): React.AbstractComponent<Props>);
