// @flow
import { Badge, Box } from '@getatomi/neon';
import { useMemo } from 'react';

import { TabNav, Item } from 'src/components/TabNav/TabNav';

import { type ClassData } from '../useGetClass';

type ClassTabNavProps = {|
  classData: ?ClassData,
  classUrl: string,
|};

export default function ClassTabNav(props: ClassTabNavProps) {
  const { classData, classUrl } = props;
  const tabNavItems = useMemo(
    () => [
      {
        name: 'Subject',
        href: classUrl,
      },
      {
        name: 'Tasks',
        href: `${classUrl}/tasks`,
        activeMatcher: ({ pathname }) => Boolean(pathname.match('/tasks')),
      },
      {
        name: 'Mark Book',
        href: `${classUrl}/markbook-marks`,
        activeMatcher: ({ pathname }) => Boolean(pathname.match('markbook')),
      },
      {
        name: 'Class Insights',
        href: `${classUrl}/insights`,
        activeMatcher: ({ pathname }) => Boolean(pathname.match('insights')),
      },
      {
        name: 'Students',
        href: `${classUrl}/users/students`,
        count: classData?.studentCount,
        intercomMarker: 'students-tab',
      },
      {
        name: 'Teachers',
        href: `${classUrl}/users/teachers`,
        count: classData?.teacherCount,
      },
    ],
    [classData, classUrl]
  );

  return (
    <TabNav ariaLabel="Class navigation" items={tabNavItems}>
      {(item) => (
        <Item
          key={item.href}
          href={item.href}
          textValue={item.name}
          routerOptions={{ state: { refocus: false, scrollTop: false } }}
          data-intercom={item.intercomMarker ? item.intercomMarker : undefined}
        >
          {typeof item.count !== 'undefined' ? (
            <>
              <Box as="span" marginRight="spacingSmall1X">
                {item.name}
              </Box>
              <Badge variant="counter">{item.count}</Badge>
            </>
          ) : (
            item.name
          )}
        </Item>
      )}
    </TabNav>
  );
}
