// @flow
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  Button,
  Checkbox,
  Flex,
  Modal,
  Stack,
  StatusLight,
  TextField,
  Text,
  useId,
  useMediaQuery,
  useToast,
} from '@getatomi/neon';

import { Combobox, ItemLabel } from 'src/components/Combobox/Combobox';
import { Dropdown, DropdownLoader, Item } from 'src/components/Dropdown/Dropdown';
import FindSubjectHelpLink from 'src/components/FindSubjectHelpLink/FindSubjectHelpLink';
import Link from 'src/components/Link/Link';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import { getClassUrl } from 'src/utils/routes';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import useClassDialogForm, { type SubmitFields } from 'src/hooks/useClassDialogForm';
import useGetLevelSubjects from 'src/domains/Class/useGetLevelSubjects';
import { type SubjectOptions } from 'src/utils/filterSubjectsByLevel';
import useOnboardingItems from 'src/domains/Onboarding/useOnboardingItems/useOnboardingItems';

import useCreateClass from './useCreateClass';

export type AddClassDialogProps = {|
  isFreePlan: boolean,
  onClose: () => mixed,
  showModal: boolean,
  subscriptionId: string,
  userId: string,
|};

export default function AddClassDialog(props: AddClassDialogProps) {
  const { isFreePlan, onClose, showModal, subscriptionId, userId } = props;

  const { getLevelSubjects, levels, loading, error } = useGetLevelSubjects({
    subscriptionId: String(subscriptionId),
  });

  const { completeOnboardingItem } = useOnboardingItems({
    accountId: subscriptionId,
    isSkipped: !isFreePlan,
  });

  const formId = useId();
  const toast = useToast();
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });
  const [levelSubjects, setLevelSubjects] = useState<SubjectOptions>([]);

  const onLevelChange = (value: ?string) => {
    const selectedLevel = (levels && levels.find((level) => level.id === value)) || null;
    const filteredSubjects: SubjectOptions =
      (selectedLevel &&
        selectedLevel.subjects.map((subject) => ({
          color: subject.color,
          label: subject.name,
          value: subject.code,
        }))) ||
      [];
    setLevelSubjects(filteredSubjects);
  };

  const { createClass, isCreatingClass } = useCreateClass();

  const { control, fields, onSubmit, reset, serverError } = useClassDialogForm({
    defaultValues: {
      isTeacherForThisClass: true,
      levelId: null,
      subjectCode: null,
      name: '',
    },
    onSubmitSuccess: async (data: SubmitFields) => {
      const classData = await createClass({
        classData: {
          accountId: subscriptionId,
          addMeAsTeacher: data.isTeacherForThisClass,
          levelId: data.levelId,
          name: data.name,
          subjectCode: data.subjectCode,
        },
        userId,
      });

      if (!classData) {
        return;
      }

      const classId = classData.id;

      const toastId = toast.success(
        <Stack spacing="spacingSmall2X">
          <p>{data.name} has been created successfully.</p>
          <Link to={getClassUrl(subscriptionId, classId)} onClick={() => toast.hide(toastId)}>
            View this class
          </Link>
        </Stack>,
        { duration: Infinity }
      );

      completeOnboardingItem?.({ itemToComplete: 'CREATE_CLASS' });

      onClose();
      reset();
    },
    onSubmitError: () => {
      toast.error('There was an error adding your class. Please try again.');
    },
  });

  useEffect(() => {
    showModal && getLevelSubjects();
  }, [getLevelSubjects, showModal]);

  const onCloseHandler = () => {
    onClose();
    reset();
  };

  if (error) {
    return (
      <Modal heading="Add a class" isOpen={showModal} onClose={onCloseHandler}>
        <GraphQLError error={error} description="There was an error loading the form. Please try again." />
      </Modal>
    );
  }

  return (
    <Modal
      actions={
        <>
          <Button
            isFullWidth={isMobile}
            size="small"
            variant={isMobile ? 'tertiary' : 'text'}
            onClick={onCloseHandler}
            isDisabled={isCreatingClass}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            form={formId}
            onClick={onSubmit}
            isLoading={isCreatingClass}
            isFullWidth={isMobile}
            size="small"
          >
            Create class
          </Button>
        </>
      }
      heading="Add a class"
      isOpen={showModal}
      onClose={onCloseHandler}
    >
      <form id={formId}>
        <Stack spacing="spacingLarge1X">
          <Text as="p" variant="bodyRoot">
            By creating a class you’ll be able to view all the content for that subject. You’ll be the only member of
            the class for now, but can invite others to join you later.
          </Text>
          {serverError && <ValidationErrors errors={serverError.message} />}
          <Controller
            control={control}
            name="levelId"
            render={({ field }) =>
              loading ? (
                <DropdownLoader animation="wave" />
              ) : (
                <Dropdown
                  {...field}
                  {...fields.levelId}
                  items={levels}
                  selectedKey={field.value ? String(field.value) : null}
                  onSelectionChange={(value) => {
                    field.onChange(value);
                    onLevelChange(value);
                  }}
                >
                  {(item) => <Item key={item.value}>{item.name}</Item>}
                </Dropdown>
              )
            }
          />
          <Controller
            control={control}
            name="subjectCode"
            render={({ field }) =>
              loading ? (
                <DropdownLoader animation="wave" />
              ) : (
                <Combobox
                  {...field}
                  {...fields.subjectCode}
                  defaultItems={levelSubjects}
                  selectedKey={field.value ? String(field.value) : null}
                  onSelectionChange={field.onChange}
                  placeholder="Start typing a subject name"
                  helpText={<FindSubjectHelpLink />}
                >
                  {(item) => (
                    <Item key={item.value} textValue={item.label}>
                      <StatusLight color={item.color} />
                      <ItemLabel>{item.label}</ItemLabel>
                    </Item>
                  )}
                </Combobox>
              )
            }
          />
          <Controller control={control} name="name" render={({ field }) => <TextField {...field} {...fields.name} />} />
          <Flex justifyContent="center">
            <Controller
              control={control}
              name="isTeacherForThisClass"
              render={({ field }) => <Checkbox {...field} {...fields.isTeacherForThisClass} isSelected={field.value} />}
            />
          </Flex>
        </Stack>
      </form>
    </Modal>
  );
}
