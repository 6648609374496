// @flow
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import type { BoundAction, ReduxState } from 'src/types';
import { TransitionGroup, FadeTransition } from 'src/components/UITransition/UITransition';
import { resendInvites } from 'src/actions/users';
import {
  isLoggedInAsSuperAdmin as isLoggedInAsSuperAdminSelector,
  isMissingProfileData as isMissingProfileDataSelector,
} from 'src/reducers/auth';
import {
  canToggleSetupMode as canToggleSetupModeSelector,
  getActiveSubscriptionId as getActiveSubscriptionIdSelector,
  getUserRole as getUserRoleSelector,
  hasPaymentSource as hasPaymentSourceSelector,
  isAccountOverdueSince as isAccountOverdueSinceSelector,
  isActiveSubscriptionInSetupMode as isActiveSubscriptionInSetupModeSelector,
  isLoggedInAsParent as isLoggedInAsParentSelector,
  isLoggedInAsStudent as isLoggedInAsStudentSelector,
  isPaidFamilyPlan as isPaidFamilyPlanSelector,
  isPaidStudentPlan as isPaidStudentPlanSelector,
  isPaymentOverdueAlertVisible as isPaymentOverdueAlertVisibleSelector,
  isPaymentSourceRequired as isPaymentSourceRequiredSelector,
  isUsingTooManySeats as isUsingTooManySeatsSelector,
} from 'src/reducers/subscriptions';
import {
  getPendingStudents as getPendingStudentsSelector,
  hasPendingChild as hasPendingChildSelector,
  isProcessingUsers as isProcessingUsersSelector,
} from 'src/reducers/users';

import MissingPaymentSourceAlert from './MissingPaymentSourceAlert/MissingPaymentSourceAlert';
import MissingProfileDataAlert from './MissingProfileDataAlert/MissingProfileDataAlert';
import PaymentOverdueAlert from './PaymentOverdueAlert/PaymentOverdueAlert';
import PendingChildAlert from './PendingChildAlert/PendingChildAlert';
import PlanSeatsAlert from './PlanSeatsAlert/PlanSeatsAlert';
import SetupModeAlert from './SetupModeAlert/SetupModeAlert';

const mapStateToProps = (state) => ({
  canToggleSetupMode: canToggleSetupModeSelector(state),
  hasPaymentSource: hasPaymentSourceSelector(state),
  hasPendingChild: hasPendingChildSelector(state),
  isAccountOverdueSince: isAccountOverdueSinceSelector(state),
  isActiveSubscriptionInSetupMode: isActiveSubscriptionInSetupModeSelector(state),
  isLoggedInAsParent: isLoggedInAsParentSelector(state),
  isLoggedInAsStudent: isLoggedInAsStudentSelector(state),
  isLoggedInAsSuperAdmin: isLoggedInAsSuperAdminSelector(state),
  isMissingProfileData: isMissingProfileDataSelector(state),
  isPaidFamilyPlan: isPaidFamilyPlanSelector(state),
  isPaidStudentPlan: isPaidStudentPlanSelector(state),
  isPaymentOverdue: isPaymentOverdueAlertVisibleSelector(state),
  isPaymentSourceRequired: isPaymentSourceRequiredSelector(state),
  isProcessingUsers: isProcessingUsersSelector(state),
  isUsingTooManySeats: isUsingTooManySeatsSelector(state),
  pendingStudents: getPendingStudentsSelector(state),
  subscriptionId: getActiveSubscriptionIdSelector(state),
  userRole: getUserRoleSelector(state),
});

type Props = {
  ...$Call<typeof mapStateToProps, ReduxState>,
  resendInvitesAction: BoundAction<typeof resendInvites>,
};

function Alerts(props: Props) {
  const {
    canToggleSetupMode,
    hasPaymentSource,
    hasPendingChild,
    isAccountOverdueSince,
    isActiveSubscriptionInSetupMode,
    isLoggedInAsParent,
    isLoggedInAsStudent,
    isLoggedInAsSuperAdmin,
    isMissingProfileData,
    isPaidFamilyPlan,
    isPaidStudentPlan,
    isPaymentOverdue,
    isPaymentSourceRequired,
    isProcessingUsers,
    isUsingTooManySeats,
    pendingStudents,
    subscriptionId,
    userRole,
    resendInvitesAction,
  } = props;

  const isPaidParent = isPaidFamilyPlan && isLoggedInAsParent;
  const isPaidRetailOwner = isPaidParent || isPaidStudentPlan;
  const isEligibleForPlanSeatsAlert = isLoggedInAsSuperAdmin && isUsingTooManySeats;

  const [showPendingChildAlert, setShowPendingChildAlert] = useState<boolean>(hasPendingChild);
  const [showPlanSeatsAlert, setShowPlanSeatsAlert] = useState<boolean>(isEligibleForPlanSeatsAlert);
  const showSetupModeAlert = !isLoggedInAsStudent && isActiveSubscriptionInSetupMode;
  const showPaymentOverdueAlert = isPaymentOverdue;
  const showMissingPaymentSourceAlert = isPaymentSourceRequired && !hasPaymentSource && isPaidRetailOwner;
  const showMissingProfileData = isMissingProfileData;

  // These alerts can be hidden as a result of being dismissed _or_ the isUsingTooManySeats prop
  // changing.
  useEffect(() => {
    setShowPlanSeatsAlert(isEligibleForPlanSeatsAlert);
  }, [isEligibleForPlanSeatsAlert]);

  return (
    <>
      {showSetupModeAlert && <SetupModeAlert subscriptionId={subscriptionId} canToggleSetupMode={canToggleSetupMode} />}
      {showPaymentOverdueAlert && (
        <PaymentOverdueAlert
          userRole={userRole}
          isAccountOverdueSince={isAccountOverdueSince}
          subscriptionId={subscriptionId}
        />
      )}
      {showMissingPaymentSourceAlert && <MissingPaymentSourceAlert subscriptionId={subscriptionId} />}
      {showMissingProfileData && <MissingProfileDataAlert subscriptionId={subscriptionId} />}
      <TransitionGroup>
        {showPendingChildAlert && (
          <FadeTransition>
            <PendingChildAlert
              onDismiss={() => setShowPendingChildAlert(false)}
              pendingStudents={pendingStudents}
              resendInvitesAction={resendInvitesAction}
              isLoading={isProcessingUsers}
            />
          </FadeTransition>
        )}
        {showPlanSeatsAlert && (
          <FadeTransition>
            <PlanSeatsAlert subscriptionId={subscriptionId} onDismiss={() => setShowPlanSeatsAlert(false)} />
          </FadeTransition>
        )}
      </TransitionGroup>
    </>
  );
}

export default (connect(mapStateToProps, {
  resendInvitesAction: resendInvites,
})(Alerts): React.AbstractComponent<{}>);
