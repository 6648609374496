// @flow
import invariant from 'invariant';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Container, Flex, Skeleton, Stack } from '@getatomi/neon';

import type { ReduxState } from 'src/types';
import useTaskDialog from 'src/components/useTaskDialog/useTaskDialog';
import { getUserId } from 'src/reducers/auth';
import { isLoggedInAsStudent as isLoggedInAsStudentSelector } from 'src/reducers/subscriptions';
import { subscribeToOwnProgress } from 'src/utils/pusher';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';

import SubjectMeta from './SubjectMeta/SubjectMeta';
import SubjectModules from './SubjectModules/SubjectModules';
import SubjectRevision from './SubjectRevision/SubjectRevision';
import useGetClassSubject from './useGetClassSubject/useGetClassSubject';

type Props = {
  params: {
    classId: string,
    subscriptionId: string,
  },
};

type InjectedProps = Props & {
  isLoggedInAsStudent: boolean,
  userId: string,
};

const mapStateToProps = (state: ReduxState) => {
  const isLoggedInAsStudent = isLoggedInAsStudentSelector(state);

  return {
    isLoggedInAsStudent,
    userId: getUserId(state),
  };
};

function ClassSubjectLoader(props: { isLoggedInAsStudent: boolean }) {
  return (
    <Box testHook="class-subject-loader">
      {props.isLoggedInAsStudent && (
        <Container maxWidth="sizeContainerRoot" marginBottom={{ root: 'spacingRoot', mobile: 'spacingLarge1X' }}>
          <Skeleton height={110} animation="wave" />
        </Container>
      )}

      <Container maxWidth="sizeContainerSmall" marginBottom={{ root: 'spacingRoot', mobile: 'spacingLarge1X' }}>
        <Skeleton height={42} animation="wave" />
      </Container>

      <Container maxWidth="sizeContainerSmall">
        <Stack spacing="spacingLarge2X">
          {Array.from({ length: 5 }).map((_value, index) => (
            <Skeleton key={index} height={150} animation="wave" />
          ))}
        </Stack>
      </Container>
    </Box>
  );
}

function ClassSubject(props: InjectedProps) {
  const { params, isLoggedInAsStudent, userId } = props;
  const { classId, subscriptionId } = params;

  const { data, loading, error, refetch } = useGetClassSubject({
    classId,
    subscriptionId,
  });

  useEffect(() => {
    if (isLoggedInAsStudent) {
      return subscribeToOwnProgress(userId, () => refetch());
    }
  }, [isLoggedInAsStudent, refetch, userId]);

  const [taskDialog, { openTaskDialog }] = useTaskDialog({
    classId,
    subscriptionId,
  });

  if (error) {
    return <GraphQLError error={error} description="We couldn’t load your modules." />;
  }

  if (loading) {
    return <ClassSubjectLoader isLoggedInAsStudent={isLoggedInAsStudent} />;
  }

  invariant(data, 'Class subject data should be defined');

  return (
    <>
      {isLoggedInAsStudent && (
        <Container maxWidth="sizeContainerRoot" marginBottom={{ root: 'spacingRoot', mobile: 'spacingLarge1X' }}>
          <SubjectRevision revision={data.revision} />
        </Container>
      )}
      <Container maxWidth="sizeContainerSmall">
        <Flex direction="column" gap="spacingLarge5X" marginBottom={{ root: 'spacingRoot', mobile: 'spacingLarge1X' }}>
          <SubjectMeta
            classId={classId}
            openTaskDialog={openTaskDialog}
            subject={data.subject}
            subscriptionId={subscriptionId}
          />
        </Flex>
      </Container>

      <Container maxWidth="sizeContainerSmall">
        <SubjectModules
          classId={classId}
          modules={data.modules}
          subject={data.subject}
          subscriptionId={subscriptionId}
        />
      </Container>

      {taskDialog}
    </>
  );
}

export default (connect(mapStateToProps)(ClassSubject): React.AbstractComponent<Props>);
