// @flow
import { useState } from 'react';
import { Flex, AlertDialog } from '@getatomi/neon';

import Button from 'src/components/Button/Button';

export type ChallengeStartSessionActionsProps = {|
  challengeFirstItemUrl: string,
  onRestartChallenge: () => void,
|};

export function ChallengeStartSessionActions(props: ChallengeStartSessionActionsProps) {
  const { challengeFirstItemUrl, onRestartChallenge } = props;
  const [showRestartDialog, setShowRestartDialog] = useState<boolean>(false);

  return (
    <>
      <Flex gap="spacingRoot" justifyContent="center">
        <Button as="a" href={challengeFirstItemUrl}>
          Resume
        </Button>
        <Button variant="tertiary" onClick={() => setShowRestartDialog(true)}>
          Restart
        </Button>
      </Flex>
      <AlertDialog
        isOpen={showRestartDialog}
        onConfirm={() => onRestartChallenge()}
        onDismiss={() => setShowRestartDialog(false)}
        onConfirmLabel="Continue"
        heading="You currently have an attempt in progress, by restarting this lesson you’ll lose your progress."
      />
    </>
  );
}
