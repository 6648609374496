// @flow
import { useCallback, useRef } from 'react';
import {
  Box,
  Button,
  Flex,
  HeadingField,
  IconButton,
  IconFolder,
  IconPlus,
  Skeleton,
  Stack,
  Text,
  useMediaQuery,
} from '@getatomi/neon';

import { Menu, Item } from 'src/components/Menu/Menu';
import SubjectBadge from 'src/components/SubjectBadge/SubjectBadge';

import type { Subject } from './TaskDialog';

export function EditorLoader() {
  return (
    <Box marginTop="spacingRoot" marginInline="spacingLarge2X" marginBottom="spacingLarge8X">
      <Skeleton animation="wave" width={343} height={36} />
      <Flex alignItems="center" marginTop="spacingLarge">
        <Skeleton animation="wave" width={51} height={20} borderRadius="borderRadiusLarge" />
        <Skeleton animation="wave" width={114} height={10} borderRadius="borderRadiusLarge" marginLeft="spacingSmall" />
      </Flex>
      <Skeleton animation="wave" width={210} height={20} borderRadius="borderRadiusLarge" marginTop="spacingLarge4X" />
      <Skeleton animation="wave" width={343} height={10} borderRadius="borderRadiusLarge" marginTop="spacingLarge6X" />
    </Box>
  );
}

export function EditorMenu(props: {
  defaultMenu: React.Node,
  dismissDuplicateContentToast: () => void,
  fileUploadRef: $Call<typeof useRef>,
  onAddAtomiContent: () => void,
  onAddRevision: () => void,
  onInsertLink: () => void,
}) {
  const { defaultMenu, dismissDuplicateContentToast, fileUploadRef, onAddRevision, onInsertLink, onAddAtomiContent } =
    props;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const handleMenuAction = useCallback(
    (key: string) => {
      dismissDuplicateContentToast();

      if (key === 'content') {
        onAddAtomiContent();
      } else if (key === 'link') {
        onInsertLink();
      } else if (key === 'file') {
        // $FlowIgnore (Flow does not yet support method calls in optional chains)
        fileUploadRef.current?.click();
      } else if (key === 'revision') {
        onAddRevision();
      }
    },
    [dismissDuplicateContentToast, fileUploadRef, onAddAtomiContent, onAddRevision, onInsertLink]
  );

  return (
    <Box
      position="sticky"
      top="0"
      zIndex="zIndex3"
      display="flex"
      flexWrap={{ base: 'wrap', tablet: 'noWrap' }}
      backgroundColor="colorBackground"
      paddingBlock="spacingRoot"
    >
      {defaultMenu}
      <Flex alignItems="center" gap="spacingSmall">
        {!isMobile && (
          <Button
            iconBefore={<IconFolder size="sizeIconSmall1X" />}
            size="small"
            variant="tertiary"
            onClick={() => {
              dismissDuplicateContentToast();
              onAddAtomiContent();
            }}
            flexShrink={0}
          >
            Add Atomi Content
          </Button>
        )}
        <Menu
          renderTrigger={({ triggerProps, ref }) => (
            <IconButton ref={ref} {...triggerProps} size="small" assistiveText="Insert" variant="tertiary">
              <IconPlus />
            </IconButton>
          )}
          onAction={handleMenuAction}
        >
          {isMobile && <Item key="content">Add Atomi Content</Item>}
          <Item key="link">Insert link</Item>
          <Item key="file">Attach file</Item>
          <Item key="revision">Add new revision</Item>
        </Menu>
      </Flex>
    </Box>
  );
}

export function MarkdownEditor(props: {
  currentClassName: string,
  editor: React.Node,
  onTitleChange: (value: string) => void,
  subject: Subject,
  title: string,
  titleValidationError: ?string,
}) {
  const { currentClassName, editor, onTitleChange, subject, title, titleValidationError } = props;

  return (
    <Box display="flex" flexDirection="column" height="sizeFull" paddingBottom="spacingLarge2X">
      <Stack spacing="spacingLarge2X">
        <Flex gap="spacingSmall1X" alignItems="baseline">
          <SubjectBadge color={subject.color} groupCode={subject.groupCode} variant="filled">
            {subject.shortName}
          </SubjectBadge>
          <Text variant="bodySmall1X" color="colorTextSubtler" fontFamily="fontFamilyRoot">
            {currentClassName}
          </Text>
        </Flex>
        <Box paddingBottom="spacingLarge2X">
          <HeadingField
            ariaLabel="Task title"
            placeholder="Task title"
            onChange={onTitleChange}
            errorVariant={titleValidationError ? 'error' : undefined}
            validationText={titleValidationError}
            value={title}
          />
        </Box>
      </Stack>
      <Box display="grid" flexGrow="1">
        {editor}
      </Box>
    </Box>
  );
}
