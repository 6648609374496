// @flow
import { Flex, Text } from '@getatomi/neon';

import type { RegistrationRegionCode } from 'src/types';
import Button from 'src/components/Button/Button';
import { registrationRegions } from 'src/constants/regions';

type Props = {
  isRegistration?: boolean,
  regionCode?: RegistrationRegionCode,
  trackingData: Object,
};

export default function LoginRegistrationSwitcher(props: Props) {
  const { trackingData, isRegistration = true, regionCode = registrationRegions.nsw.code } = props;

  if (!trackingData) return null;

  return (
    <Flex alignItems="center" gap="spacingSmall">
      <Text
        as="span"
        display={{ base: 'none', tablet: 'inline' }}
        fontSize="fontSizeSmall1X"
        color={!isRegistration ? 'colorTextInverted' : 'colorTextSubtler'}
      >
        {isRegistration ? 'Already have an account?' : `Don’t have an account?`}
      </Text>
      <Button
        to={isRegistration ? '/login' : `/register/${regionCode}`}
        size="small"
        testHook="header-signup-cta"
        variant={isRegistration ? 'tag' : 'ghost'}
        trackingData={trackingData}
        // This is a workaround to overwrite the Button text colour. The `color` prop is not
        // officially supported by the Button component.
        {...((isRegistration ? { color: 'colorTextLink' } : {}): $FlowSuppressAny)}
      >
        {isRegistration ? 'Log in' : 'Sign up now'}
      </Button>
    </Flex>
  );
}
