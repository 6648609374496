// @flow
import { connect } from 'react-redux';
import type { ContextRouter, LocationShape } from 'react-router';
import { Container } from '@getatomi/neon';
import invariant from 'invariant';

import type { ReduxState } from 'src/types';
import { getUserId } from 'src/reducers/auth';
import {
  isFreePlan as isFreePlanSelector,
  isRetailPlan as isRetailPlanSelector,
  isSchoolPlan as isSchoolPlanSelector,
  isLoggedInAsChild as isLoggedInAsChildSelector,
  isLoggedInAsStudent as isLoggedInAsStudentSelector,
} from 'src/reducers/subscriptions';
import { getClassModuleUrl, isLtiUrl } from 'src/utils/routes';
import ClassBreadcrumbs, { BreadcrumbsItem } from 'src/domains/Class/ClassBreadcrumbs/ClassBreadcrumbs';
import useOnboardingItems from 'src/domains/Onboarding/useOnboardingItems/useOnboardingItems';

import LessonError from '../LessonError';
import LessonLoader from '../LessonLoader';
import LockedLesson from '../LockedLesson';
import LessonContainer from '../LessonContainer/LessonContainer';
import useGetModuleLesson from '../useGetModuleLesson';

type Params = {|
  classId: string,
  moduleId: string,
  postId: string,
  splat?: string,
  subscriptionId: string,
|};

export type ModuleLessonProps = {|
  isFreePlan: boolean,
  isLoggedInAsChild: boolean,
  isLoggedInAsStudent: boolean,
  isRetailPlan: boolean,
  isSchoolPlan: boolean,
  location: LocationShape,
  params: Params,
  router: ContextRouter,
|};

export const mapStateToProps = (state: ReduxState) => {
  const isLoggedInAsStudent = isLoggedInAsStudentSelector(state);
  const isLoggedInAsChild = isLoggedInAsChildSelector(state);
  const isRetailPlan = isRetailPlanSelector(state);

  return {
    isFreePlan: isFreePlanSelector(state),
    isLoggedInAsChild,
    isLoggedInAsStudent,
    isRetailPlan,
    isSchoolPlan: isSchoolPlanSelector(state),
    userId: getUserId(state),
  };
};

function ModuleLesson(props: ModuleLessonProps) {
  const { isLoggedInAsStudent, isRetailPlan, isSchoolPlan, location, params, isFreePlan, isLoggedInAsChild, router } =
    props;
  const { classId, moduleId, postId, splat, subscriptionId } = params;

  const {
    currentClass,
    error,
    isCrossSubject,
    lesson,
    loading,
    nextLesson,
    previousLesson,
    refetch,
    region,
    isChallengeResumeEnabled,
    isChallengeInProgress,
  } = useGetModuleLesson({
    classId,
    isStudent: isLoggedInAsStudent,
    lessonId: postId,
    subscriptionId,
  });

  const isPostEmbedded = Boolean(location.query.isEmbed);
  const isLti = isLtiUrl(location.pathname);
  const isOnboardingAvailable = !isLoggedInAsStudent && isSchoolPlan && !isLti && !isPostEmbedded;

  const {
    completeOnboardingItem,
    data: onboardingData,
    loading: onboardingLoading,
    error: onboardingError,
  } = useOnboardingItems({ accountId: subscriptionId, isSkipped: !isOnboardingAvailable });

  const errorValue = error ?? onboardingError;
  if (errorValue) {
    return <LessonError error={errorValue} />;
  }

  if (loading || onboardingLoading) {
    return <LessonLoader />;
  }

  const hasOnboardingDataIfEnabled = isOnboardingAvailable ? onboardingData && completeOnboardingItem : true;
  invariant(lesson && currentClass && region && hasOnboardingDataIfEnabled, 'Failed to load lesson data');

  const showNavigation = !isPostEmbedded && !isLti && !isCrossSubject;

  const hasResultsSplat = splat === 'results';

  return (
    <>
      {showNavigation && (
        <Container marginBottom={{ base: 'spacingLarge3X', tablet: 'spacingRoot' }}>
          <ClassBreadcrumbs
            currentClass={currentClass}
            isRetailPlan={isRetailPlan}
            subject={currentClass.subject}
            subscriptionId={subscriptionId}
          >
            <BreadcrumbsItem href={getClassModuleUrl(subscriptionId, classId, lesson.categories[0].id)}>
              {lesson.categories[0].name}
            </BreadcrumbsItem>
          </ClassBreadcrumbs>
        </Container>
      )}

      {lesson.isLocked ? (
        <LockedLesson />
      ) : (
        <LessonContainer
          completeOnboardingItem={completeOnboardingItem}
          currentClass={currentClass}
          hasResultsSplat={hasResultsSplat}
          isChallengeInProgress={isChallengeInProgress}
          isChallengeResumeEnabled={isChallengeResumeEnabled}
          isCrossSubject={isCrossSubject}
          isFreePlan={isFreePlan}
          isLoggedInAsChild={isLoggedInAsChild}
          isLoggedInAsStudent={isLoggedInAsStudent}
          isSchoolPlan={isSchoolPlan}
          isLti={isLti}
          isOnboardingAvailable={isOnboardingAvailable}
          isPostEmbedded={isPostEmbedded}
          lesson={lesson}
          moduleId={moduleId}
          nextLesson={nextLesson}
          previousLesson={previousLesson}
          refetch={refetch}
          region={region}
          router={router}
          showNavigation={showNavigation}
          subscriptionId={subscriptionId}
        />
      )}
    </>
  );
}

export default (connect(mapStateToProps, {})(ModuleLesson): React.AbstractComponent<ModuleLessonProps>);
