// @flow
import { Box, Button, Flex, IconCalendar, IconPlus, IconRevision, Text } from '@getatomi/neon';

import { Menu, Item } from 'src/components/Menu/Menu';
import { trackingCtas, trackingEvents } from 'src/constants/tracking';
import { trackEvent } from 'src/utils/tracking';

type Props = {|
  classId: string,
  openTaskDialog: () => void,
  openTeacherRevisionDialog: () => void,
|};

type Items = $ReadOnlyArray<{|
  description: string,
  icon: React.Node,
  key: 'task' | 'revision',
  label: string,
|}>;

export default function UniversalCreateButton(props: Props) {
  const { classId, openTaskDialog, openTeacherRevisionDialog } = props;

  const handleAction = (key: string) => {
    if (key === 'task') {
      openTaskDialog();
      trackEvent(trackingEvents.ctaClicked, { classId, cta: trackingCtas.globalCreateTask });
    } else if (key === 'revision') {
      openTeacherRevisionDialog();
      trackEvent(trackingEvents.ctaClicked, { classId, cta: trackingCtas.globalCreateRevision });
    }
  };

  const onOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      trackEvent(trackingEvents.ctaClicked, { classId, cta: trackingCtas.globalCreate });
    }
  };

  const items: Items = [
    {
      key: 'task',
      icon: <IconCalendar size="sizeIconSmall1X" />,
      label: 'Task',
      description: 'Assign work to your students, including Atomi lessons or your own content.',
    },
    {
      key: 'revision',
      icon: <IconRevision size="sizeIconSmall1X" />,
      label: 'Revision session',
      description: 'Compile a set of questions to revise a topic or prepare for exams.',
    },
  ];

  return (
    <Menu
      onAction={handleAction}
      onOpenChange={onOpenChange}
      placement="bottom end"
      renderTrigger={({ ref, triggerProps }) => (
        <Button
          variant="primary"
          iconBefore={<IconPlus color="colorIconInverted" size="sizeIconSmall2X" strokeWidth={1.5} />}
          ref={ref}
          {...triggerProps}
        >
          Create
        </Button>
      )}
      width={300}
    >
      {items.map((item) => (
        <Item key={item.key} textValue={item.label}>
          <Flex gap="spacingSmall">
            <Box marginTop="spacingSmall2X">{item.icon}</Box>
            <Box>
              <Text variant="bodySmall" fontWeight="fontWeightMedium">
                {item.label}
              </Text>
              <Text variant="bodySmall2X" color="colorTextSubtle">
                {item.description}
              </Text>
            </Box>
          </Flex>
        </Item>
      ))}
    </Menu>
  );
}
