// @flow
import { useState } from 'react';
import {
  Box,
  Container,
  Divider,
  EmptyState,
  Flex,
  Heading,
  Illustration,
  ProgressCircle,
  Skeleton,
  Stack,
  StatusLight,
} from '@getatomi/neon';
import _ from 'lodash';

import Button from 'src/components/Button/Button';
import ClassBreadcrumbs, { BreadcrumbsItem } from 'src/domains/Class/ClassBreadcrumbs/ClassBreadcrumbs';
import type { UserStatusOnTask } from 'src/domains/Tasks/types';
import userStatusOnTask from 'src/constants/userStatusOnTask';
import taskProgressStatuses from 'src/constants/taskProgressStatuses';
import { Dropdown, DropdownLoader, Item, ItemLabel } from 'src/components/Dropdown/Dropdown';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import useSortedStudents from 'src/hooks/useSortedStudents';
import { taskProgressStatusMap } from 'src/components/TaskProgressStatus/TaskProgressStatus';
import useProgressReportDialog from 'src/hooks/components/useProgressReportDialog';
import { getClassTasksUrl, getTaskUrl } from 'src/utils/routes';

import useGetTaskProgress from './useGetTaskProgress';
import TaskProgressTable from './TaskProgressTable';

const statusFilters = {
  [userStatusOnTask.COMPLETE]: taskProgressStatuses.done,
  [userStatusOnTask.IN_PROGRESS]: taskProgressStatuses.inProgress,
  [userStatusOnTask.PENDING]: taskProgressStatuses.toDo,
};

const calculateProgressSummaryData = (students) => {
  const studentCount = students.length;
  const countComplete = _.filter(students, { status: { displayValue: userStatusOnTask.COMPLETE } }).length;
  const countInProgress = _.filter(students, { status: { displayValue: userStatusOnTask.IN_PROGRESS } }).length;
  const countPending = _.filter(students, { status: { displayValue: userStatusOnTask.PENDING } }).length;
  return [
    {
      label: 'Overall progress',
      // avoid NaN% when the task is assigned to nobody
      children: `${Math.round((countComplete / studentCount || 0) * 100)}%`,
      value: countComplete / studentCount,
    },
    {
      label: taskProgressStatusMap[taskProgressStatuses.done].label,
      children: `${countComplete}/${studentCount}`,
      value: countComplete / studentCount,
    },
    {
      label: taskProgressStatusMap[taskProgressStatuses.inProgress].label,
      children: `${countInProgress}/${studentCount}`,
      value: countInProgress / studentCount,
    },
    {
      label: taskProgressStatusMap[taskProgressStatuses.toDo].label,
      children: `${countPending}/${studentCount}`,
      value: countPending / studentCount,
    },
  ];
};

type Params = {
  classId: string,
  subscriptionId: string,
  taskId: string,
};

type Props = {
  location: {
    query: {
      status?: UserStatusOnTask,
    },
  },
  params: Params,
};

export default function TaskProgressReport(props: Props) {
  const {
    params: { classId, subscriptionId, taskId },
    location: {
      query: { status: initialStatus = '' },
    },
  } = props;
  const [status, setStatus] = useState(initialStatus);

  const { data, error, loading } = useGetTaskProgress({
    classId,
    subscriptionId,
    taskId,
  });
  const { currentClass, students, task, contents, isFreePlan, region } = data;

  const [progressReportDialog, { openProgressReportDialog }] = useProgressReportDialog({
    classId,
    isFreePlan,
    region,
    subscriptionId,
  });

  const studentsFiltered = status !== '' ? _.filter(students, { status: { displayValue: status } }) : students;
  const { sortedData, setSortBy } = useSortedStudents(studentsFiltered);

  if (error) {
    return <GraphQLError error={error} description="We couldn’t load the progress for this task." />;
  }

  const loadingRows = _.times(10, _.constant({}));

  const isTableEmpty = !loading && studentsFiltered?.length === 0;

  return (
    <>
      <Container marginBottom="spacingRoot">
        {loading ? (
          <Skeleton animation="wave" width={200} height={28} />
        ) : (
          <ClassBreadcrumbs
            currentClass={currentClass}
            isRetailPlan={false}
            subject={currentClass.subject}
            subscriptionId={subscriptionId}
          >
            <BreadcrumbsItem href={getClassTasksUrl(subscriptionId, currentClass.id)}>Tasks</BreadcrumbsItem>
            <BreadcrumbsItem href={getTaskUrl(subscriptionId, currentClass.id, task.id)}>{task.title}</BreadcrumbsItem>
          </ClassBreadcrumbs>
        )}
      </Container>
      <>
        <Container marginBottom="spacingLarge1X" paddingInline="spacingNone" textAlign="center" maxWidth="sizeFull">
          <Stack spacing="spacingLarge1X">
            <Container>
              <Heading as="h1">How’s everyone going with this task?</Heading>
            </Container>
            {!loading && (
              <Box
                display="grid"
                gridAutoFlow={{ base: 'row', tablet: 'column' }}
                gridTemplateColumns={{ base: 'repeat(3, 1fr)', tablet: 'repeat(4, 140px)' }}
                gap={{ base: 'spacingLarge1X', tablet: 'spacingLarge3X' }}
                marginInline={{ base: 'spacingRoot', tablet: 'spacingLarge1X' }}
                alignItems="start"
                justifyContent="center"
                testHook="progress-summary"
              >
                {calculateProgressSummaryData(data.students).map((summary, index) => (
                  <ProgressCircle key={index} as="meter" {...summary} />
                ))}
              </Box>
            )}
            <Stack spacing="spacingLarge3X">
              <Divider />
              <Container maxWidth="sizeFull" paddingInline={{ base: 'spacingRoot', tablet: 'spacingLarge3X' }}>
                <Flex justifyContent="end">
                  {loading ? (
                    <DropdownLoader variant="filter" />
                  ) : (
                    <Dropdown
                      ariaLabel="Filter by status"
                      variant="filter"
                      items={[
                        { key: '', color: null, label: 'All statuses' },
                        ...Object.keys(statusFilters).map((key) => {
                          const filter = statusFilters[key];
                          return {
                            key,
                            color: taskProgressStatusMap[filter].colorCode,
                            label: taskProgressStatusMap[filter].label,
                          };
                        }),
                      ]}
                      selectedKey={status}
                      onSelectionChange={setStatus}
                    >
                      {(item) => (
                        <Item textValue={item.label}>
                          {item.color && <StatusLight color={item.color} />}
                          <ItemLabel>{item.label}</ItemLabel>
                        </Item>
                      )}
                    </Dropdown>
                  )}
                </Flex>
              </Container>
              {isTableEmpty ? (
                <EmptyState
                  media={<Illustration name="emptystate-tasks" />}
                  description="There are no results for your selection."
                  primaryAction={
                    <Button variant="text" onClick={() => setStatus('')}>
                      Clear all filters
                    </Button>
                  }
                />
              ) : (
                <TaskProgressTable
                  contents={contents}
                  data={loading ? loadingRows : sortedData}
                  loading={loading}
                  openProgressReportDialog={openProgressReportDialog}
                  toggleSortBy={setSortBy}
                  taskId={taskId}
                />
              )}
            </Stack>
          </Stack>
        </Container>
        {progressReportDialog}
      </>
    </>
  );
}
