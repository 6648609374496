// @flow
import { Box, Button, Card, Flex, HideVisually, IconArrowFront, Text, Truncate, useMediaQuery } from '@getatomi/neon';

import Link, { UnstyledLink } from 'src/components/Link/Link';

import styles from './RevisionCallout.module.scss';

type RevisionCalloutProps = {|
  buttonProps: {|
    isDisabled?: true,
    label: string,
    to?: string,
  |},
  linkProps?: {|
    label: string,
    to: string,
  |},
  text: string,
|};

type RevisionCalloutLayoutProps = {|
  button: React.Node,
  content: React.Node,
|};

function RevisionCalloutLayout(props: RevisionCalloutLayoutProps) {
  const { button, content } = props;

  return (
    <Card
      as="aside"
      paddingBlock={{
        base: 'spacingSmall',
        tablet: 'spacingLarge2X',
      }}
      paddingInline={{
        base: 'spacingRoot',
        tablet: 'spacingLarge3X',
      }}
      variant="callout"
      withHover={false}
    >
      <Flex alignItems="center">
        <Box
          backgroundColor="colorBackgroundBrand"
          borderRadius="borderRadiusCircle"
          className={styles.pulsatingDot}
          flexShrink={0}
          size={{ base: 'sizeIconSmall2X', tablet: 'sizeIconRoot' }}
        />

        <Box
          flexGrow={1}
          marginLeft={{ base: 'spacingLarge', tablet: 'spacingLarge3X' }}
          marginRight="spacingLarge"
          minWidth={0}
        >
          {content}
        </Box>

        <Box flexShrink={0}>{button}</Box>
      </Flex>
    </Card>
  );
}

function RevisionCallout(props: RevisionCalloutProps) {
  const { buttonProps, linkProps, text } = props;
  const isDisabled = buttonProps.isDisabled ?? false;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  return (
    <RevisionCalloutLayout
      button={
        // eslint-disable-next-line no-nested-ternary
        isMobile ? (
          buttonProps.to ? (
            <Link to={buttonProps.to}>
              <HideVisually>{buttonProps.label}</HideVisually>
              <IconArrowFront strokeWidth="2" />
            </Link>
          ) : null
        ) : (
          <Button
            as={isDisabled ? undefined : UnstyledLink}
            isDisabled={isDisabled}
            size="small"
            to={buttonProps.to ?? undefined}
            variant="tertiary"
          >
            {buttonProps.label}
          </Button>
        )
      }
      content={
        <>
          <Text color="colorTextSubtler" fontWeight="fontWeightMedium" variant="bodySmall2X">
            {text}
          </Text>
          {linkProps && (
            <Link to={linkProps.to}>
              <Truncate>{linkProps.label}</Truncate>
            </Link>
          )}
        </>
      }
    />
  );
}

export default RevisionCallout;
