// @flow
import { Box, IconCircleCheck, IconCircleSolidCheck, Text, Tooltip, useFocusRing, useHover } from '@getatomi/neon';

import { type SubjectColors } from 'src/constants/subjectColors';

export type StudentTaskCompletionToggleProps = {|
  colors: SubjectColors,
  isCompleted?: boolean,
  isDisabled?: boolean,
  onClick: () => void,
|};

export default function StudentTaskCompletionToggle(props: StudentTaskCompletionToggleProps) {
  const { isCompleted = false, isDisabled, onClick, colors } = props;
  const { hoverProps, isHovered } = useHover({ isDisabled });
  const { isFocused, focusProps } = useFocusRing();
  const isCircleFilled = isHovered || (!isDisabled && isFocused);

  const iconSize = { base: 'sizeIconSmall', tablet: 'sizeIconRoot' };
  let iconColor = 'colorIcon';

  if (isDisabled && !isCompleted) {
    iconColor = 'colorIconDisabled';
  } else if (isCompleted || isHovered || isFocused) {
    iconColor = colors.icon;
  }

  const button = (
    <Box
      as="button"
      type="button"
      display="flex"
      alignItems="center"
      gap={{ base: 'spacingSmall', tablet: 'spacingRoot' }}
      cursor={isDisabled ? 'not-allowed' : undefined}
      onClick={isDisabled ? undefined : onClick}
      aria-disabled={isDisabled}
      aria-pressed={isCompleted}
      {...hoverProps}
      {...focusProps}
    >
      <Box
        as="span"
        backgroundColor={isCircleFilled ? colors.backgroundSubtle : undefined}
        borderRadius="borderRadiusCircle"
        display="flex"
        alignItems="center"
        size={iconSize}
        transition="transitionRoot"
      >
        {isCompleted ? (
          <IconCircleSolidCheck color={iconColor} size={iconSize} />
        ) : (
          <IconCircleCheck color={iconColor} size={iconSize} />
        )}
      </Box>
      <Text fontFamily="fontFamilyRoot" fontSize="fontSizeSmall" color={isCompleted ? 'colorTextSubtle' : 'colorText'}>
        Task done
      </Text>
    </Box>
  );

  return isDisabled ? (
    <Tooltip
      content={
        isCompleted
          ? 'This task is set to be auto-marked as done when you complete the Atomi lessons so you can’t undo it.'
          : 'Complete all the steps first!'
      }
    >
      {button}
    </Tooltip>
  ) : (
    button
  );
}
