// @flow
import { useEffect, useState } from 'react';
import { type ContextRouter, withRouter } from 'react-router';
import { useCookies } from 'react-cookie';
import _ from 'lodash';
import { Box, Container, Flex, Heading, useMediaQuery } from '@getatomi/neon';

import EmptyStateSearch from 'src/components/EmptyStateSearch/EmptyStateSearch';
import SearchInput from 'src/components/SearchInput/SearchInput';
import { getDashboardUrl } from 'src/utils/routes';
import { CLASSES_VIEW_MODE_COOKIE } from 'src/constants/cookies';
import { getCookieOptions } from 'src/utils/cookies';
import ButtonAdd from 'src/components/ButtonAdd/ButtonAdd';
import { trackEvent } from 'src/utils/tracking';
import { trackingCtas, trackingEvents } from 'src/constants/tracking';

import AddClassDialog from '../AddClassDialog/AddClassDialog';
import ClassCards from '../ClassCards/ClassCards';
import ClassCardsLoader from '../ClassCards/ClassCardsLoader';
import ClassList from '../ClassList/ClassList';
import ClassListLoader from '../ClassList/ClassListLoader';
import EmptyStateClass from '../EmptyStateClass/EmptyStateClass';
import ViewModeToggle, { type ViewMode } from '../ViewModeToggle/ViewModeToggle';
import type { ClassData } from '../useGetClasses/useGetClasses';
import DashboardTabNav from '../DashboardTabNav/DashboardTabNav';

type Props = {
  classes: ?$ReadOnlyArray<ClassData>,
  isAdminOrAbove: boolean,
  isFreePlan: boolean,
  isLoading: boolean,
  searchKeywords: string,
  subscriptionId: string,
  userId: string,
};

type InjectedProps = Props & {
  router: ContextRouter,
};

function DashboardNonStudent(props: InjectedProps) {
  const {
    classes,
    isLoading,
    isAdminOrAbove = false,
    isFreePlan,
    router,
    searchKeywords = '',
    subscriptionId,
    userId,
  } = props;
  const [cookies, setCookie] = useCookies([CLASSES_VIEW_MODE_COOKIE]);
  const defaultViewMode = cookies[CLASSES_VIEW_MODE_COOKIE] || 'grid';
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });
  const [viewMode, setViewMode] = useState<'list' | 'grid'>(defaultViewMode);
  const [isAddClassDialogVisible, setIsAddClassDialogVisible] = useState(false);
  const { source } = router.location.query;

  const hasClasses = Boolean(classes?.length);
  const hasNoSearchResults = searchKeywords && !hasClasses;
  const hasNoClasses = !hasClasses && !hasNoSearchResults && !isLoading;
  const canAddClass = isAdminOrAbove;
  const canDeleteClass = isAdminOrAbove;

  const onSwitchViewMode = (newViewMode: ViewMode) => {
    trackEvent(trackingEvents.ctaClicked, {
      cta: trackingCtas.classListChangeViewType,
      data: newViewMode,
    });
    setCookie(CLASSES_VIEW_MODE_COOKIE, newViewMode, getCookieOptions());
    setViewMode(newViewMode);
  };

  const onToggleAddClassDialog = (e) => {
    if (e) e.preventDefault();
    setIsAddClassDialogVisible((prevState) => !prevState);
  };

  const onSearch = _.debounce((keywords) => {
    router.push({
      pathname: getDashboardUrl(subscriptionId),
      query: { source, keywords },
      state: {
        refocus: false, // prevent App from throwing focus to top of document
      },
    });
  }, 500);

  const resetSearchFilter = () => {
    onSearch('');
  };

  useEffect(() => {
    setViewMode(isMobile ? 'list' : defaultViewMode);
  }, [defaultViewMode, isMobile]);

  const renderGridOrList = () => {
    if (isLoading || classes == null) {
      if (viewMode === 'grid') {
        return <ClassCardsLoader />;
      }
      return <ClassListLoader />;
    }

    if (viewMode === 'grid') {
      return <ClassCards classes={classes} withStudentsCount withSubjectTag />;
    }
    return (
      <ClassList
        canDeleteClass={canDeleteClass}
        classes={classes}
        subscriptionId={subscriptionId}
        withStudentsCount
        withSubjectTag
      />
    );
  };

  return (
    <>
      <Container marginBottom={{ base: 'spacingRoot', tablet: 'spacingLarge2X' }}>
        <Flex justifyContent="space-between" marginBottom="spacingRoot">
          <Heading as="h1" variant="large">
            Classes
          </Heading>
          {canAddClass && (
            <ButtonAdd
              assistiveText="Add a class"
              mobileVariant="secondary"
              onClick={onToggleAddClassDialog}
              testHook="add-class-button"
              trackingData={{ cta: trackingCtas.classCreateOpenDialog }}
              data-intercom="add-class"
            >
              Add a class
            </ButtonAdd>
          )}
        </Flex>
        <DashboardTabNav subscriptionId={subscriptionId} />
      </Container>

      {hasNoClasses ? (
        <EmptyStateClass isStudent={false} onAddClassClick={canAddClass ? onToggleAddClassDialog : undefined} />
      ) : (
        <Box>
          <Container marginBottom={{ tablet: 'spacingLarge' }}>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              marginTop={{
                base: 'spacingLarge2X',
                desktop: 'spacingNone',
              }}
              marginBottom={{
                base: 'spacingSmall1X',
                desktop: 'spacingNone',
              }}
            >
              <SearchInput
                placeholder="Search classes"
                onChange={onSearch}
                onReset={resetSearchFilter}
                initialValue={searchKeywords}
                testHook="dashboard-search-input"
              />
              <ViewModeToggle selectedMode={viewMode} onChange={onSwitchViewMode} />
            </Flex>
          </Container>
          {hasNoSearchResults && !isLoading ? (
            <EmptyStateSearch contentType="classes" searchTerm={searchKeywords} />
          ) : (
            renderGridOrList()
          )}
        </Box>
      )}

      {canAddClass && (
        <AddClassDialog
          showModal={isAddClassDialogVisible}
          subscriptionId={subscriptionId}
          onClose={onToggleAddClassDialog}
          userId={userId}
          isFreePlan={isFreePlan}
        />
      )}
    </>
  );
}

export default withRouter(DashboardNonStudent);
