// @flow

import { type ContextRouter, withRouter } from 'react-router';

import { getRevisionFirstItemUrl, getTaskRevisionFirstItemUrl } from 'src/utils/routes';
import { strengthThresholds } from 'src/utils/config';
import { ChallengeResults } from 'src/components/ChallengeResults/ChallengeResults';
import { parseChallengeResults, type ChallengeResults as ChallengeResultsType } from 'src/utils/parseChallengeResults';

import type { Task, UserClass } from '../useGetRevision';
import { RevisionChallengeLayout } from '../RevisionChallengeLayout';
import { makeContinueLink } from './makeContinueLink';

export type Props = {|
  accountId: string,
  challengeId: string,
  isStudent: boolean,
  isStudentRevision: boolean,
  router: ContextRouter,
  task: Task,
  userClass: UserClass,
|};

export function RevisionChallengeResults({
  accountId,
  challengeId,
  isStudent,
  isStudentRevision,
  router,
  task,
  userClass,
}: Props) {
  const { query } = router.location;

  const challengeResults = query.data ? parseChallengeResults(query.data) : null;

  const marks: ChallengeResultsType = challengeResults ?? { scored: 0, missed: 0, skipped: 0, strength: 0 };
  const classId = userClass.id;
  const taskId = task ? task.id : null;

  const contineLink = makeContinueLink({
    accountId,
    classId,
    isStudent,
    taskId,
  });

  let challengeFirstItemUrl;
  if (taskId) {
    challengeFirstItemUrl = getTaskRevisionFirstItemUrl({
      accountId,
      classId,
      challengeId,
      taskId,
      restartChallenge: false,
    });
  } else {
    challengeFirstItemUrl = getRevisionFirstItemUrl({
      accountId,
      classId,
      challengeId,
      restartChallenge: false,
    });
  }

  const onContinue = (): void => {
    router.push(contineLink);
  };

  return (
    <RevisionChallengeLayout
      accountId={accountId}
      isStudent={isStudent}
      task={task}
      userClass={userClass}
      navigationType={isStudentRevision ? 'backLink' : 'breadcrumbs'}
    >
      <ChallengeResults
        challengeFirstItemUrl={challengeFirstItemUrl}
        scoredMarks={marks.scored}
        missedMarks={marks.missed}
        skippedMarks={marks.skipped}
        strengthPercentage={marks.strength}
        strengthThresholds={strengthThresholds}
        onContinue={onContinue}
      />
    </RevisionChallengeLayout>
  );
}

export default withRouter(RevisionChallengeResults);
