// @flow
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router';
import { Breadcrumbs, Container } from '@getatomi/neon';
import invariant from 'invariant';

import type { ReduxState } from 'src/types';
import { getUserId } from 'src/reducers/auth';
import {
  isFreePlan as isFreePlanSelector,
  isRetailPlan as isRetailPlanSelector,
  isSchoolPlan as isSchoolPlanSelector,
  isLoggedInAsChild as isLoggedInAsChildSelector,
  isLoggedInAsStudent as isLoggedInAsStudentSelector,
} from 'src/reducers/subscriptions';
import { getClassTasksUrl, getTasksUrl, getTaskUrl } from 'src/utils/routes';
import ClassBreadcrumbs, { BreadcrumbsItem } from 'src/domains/Class/ClassBreadcrumbs/ClassBreadcrumbs';

import LessonError from '../LessonError';
import LessonContainer from '../LessonContainer/LessonContainer';
import LessonLoader from '../LessonLoader';
import useGetTaskLesson from '../useGetTaskLesson';

type Params = {|
  classId: string,
  postId: string,
  splat?: string,
  subscriptionId: string,
  taskId: string,
|};

export type TaskLessonProps = {|
  isFreePlan: boolean,
  isLoggedInAsChild: boolean,
  isLoggedInAsStudent: boolean,
  isRetailPlan: boolean,
  isSchoolPlan: boolean,
  params: Params,
  router: ContextRouter,
|};

export const mapStateToProps = (state: ReduxState) => {
  const isLoggedInAsStudent = isLoggedInAsStudentSelector(state);
  const isLoggedInAsChild = isLoggedInAsChildSelector(state);
  const isRetailPlan = isRetailPlanSelector(state);

  return {
    isFreePlan: isFreePlanSelector(state),
    isLoggedInAsChild,
    isLoggedInAsStudent,
    isRetailPlan,
    isSchoolPlan: isSchoolPlanSelector(state),
    userId: getUserId(state),
  };
};

function TaskLesson(props: TaskLessonProps) {
  const { isLoggedInAsStudent, isRetailPlan, isSchoolPlan, params, isFreePlan, isLoggedInAsChild, router } = props;
  const { classId, postId, splat, subscriptionId, taskId } = params;

  const {
    currentClass,
    error,
    isChallengeInProgress,
    isChallengeResumeEnabled,
    isCrossSubject,
    lesson,
    loading,
    moduleId,
    nextLesson,
    previousLesson,
    refetch,
    region,
    task,
  } = useGetTaskLesson({
    classId,
    isStudent: isLoggedInAsStudent,
    lessonId: postId,
    subscriptionId,
    taskId,
  });

  if (error) {
    return <LessonError error={error} />;
  }

  if (loading) {
    return <LessonLoader />;
  }

  invariant(lesson && currentClass && region && moduleId, 'Failed to load lesson data');

  const hasResultsSplat = splat === 'results';

  return (
    <>
      <Container marginBottom={{ base: 'spacingLarge3X', tablet: 'spacingRoot' }}>
        {isLoggedInAsStudent ? (
          <Breadcrumbs>
            {task && <BreadcrumbsItem href={getTasksUrl(subscriptionId)}>Tasks</BreadcrumbsItem>}
            {task && (
              <BreadcrumbsItem href={getTaskUrl(subscriptionId, currentClass.id, task.id)}>
                {task.title}
              </BreadcrumbsItem>
            )}
          </Breadcrumbs>
        ) : (
          <ClassBreadcrumbs
            currentClass={currentClass}
            isRetailPlan={isRetailPlan}
            subject={currentClass.subject}
            subscriptionId={subscriptionId}
          >
            {task && <BreadcrumbsItem href={getClassTasksUrl(subscriptionId, currentClass.id)}>Tasks</BreadcrumbsItem>}
            {task && (
              <BreadcrumbsItem href={getTaskUrl(subscriptionId, currentClass.id, task.id)}>
                {task.title}
              </BreadcrumbsItem>
            )}
          </ClassBreadcrumbs>
        )}
      </Container>

      <LessonContainer
        completeOnboardingItem={null}
        currentClass={currentClass}
        hasResultsSplat={hasResultsSplat}
        isChallengeInProgress={isChallengeInProgress}
        isChallengeResumeEnabled={isChallengeResumeEnabled}
        isCrossSubject={isCrossSubject}
        isFreePlan={isFreePlan}
        isLoggedInAsChild={isLoggedInAsChild}
        isLoggedInAsStudent={isLoggedInAsStudent}
        isSchoolPlan={isSchoolPlan}
        lesson={lesson}
        moduleId={moduleId}
        nextLesson={nextLesson}
        previousLesson={previousLesson}
        refetch={refetch}
        region={region}
        router={router}
        showNavigation
        subscriptionId={subscriptionId}
        taskId={taskId}
      />
    </>
  );
}

export default (connect(mapStateToProps, {})(TaskLesson): React.AbstractComponent<TaskLessonProps>);
