// @flow
import Logger from 'src/utils/Logger';
import errorMessageVariants from 'src/constants/errorMessageVariants';

const log = new Logger('utils/validateEmail');

export type ValidationResult = {
  isServiceAvailable: boolean,
  level: $Keys<typeof errorMessageVariants> | null,
  message: string | null,
};

export default async function validateEmail(email: string): Promise<ValidationResult> {
  try {
    const response = await fetch(
      `https://api.mailgun.net/v2/address/validate?api_key=${
        process.env.NEXT_PUBLIC_MAILGUN_API_KEY
      }&address=${encodeURIComponent(email)}`
    );

    // handle non-200 responses
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.message);
    }

    const {
      did_you_mean: didYouMean,
      is_disposable_address: isDisposable,
      is_role_address: isRoleAddress,
      is_valid: isValid,
    } = await response.json();

    let message = null;
    let level = null;

    if (!isValid) {
      level = 'error';
      message = `Hmm. It looks like this isn’t a valid email address.`;
    } else if (isDisposable) {
      level = 'warning';
      message = `This email looks like it might be fake. Double check you’ve entered a valid address.`;
    } else if (isRoleAddress) {
      level = 'warning';
      message =
        'This looks like it might be a shared email address. For security reasons we recommend using an email that only you have access to.';
    }

    if (didYouMean) {
      message = `${message ?? ''} Did you mean: ${didYouMean}?`.trim();
    }

    return {
      isServiceAvailable: true,
      level,
      message,
    };
  } catch (err) {
    log.warn('Validating the email with Mailgun failed', err);
    return {
      isServiceAvailable: false,
      level: null,
      message: null,
    };
  }
}

export function isBlocking(level: string) {
  return [errorMessageVariants.error].includes(level);
}
