// @flow
import Helmet from 'react-helmet';
import { type ContextRouter, withRouter } from 'react-router';
import { Container, Heading, Text } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout/LogoOnlyLayout';

type UnsubscribeProps = {|
  router: ContextRouter,
|};

function Unsubscribe(props: UnsubscribeProps) {
  const { router } = props;
  const { category, success } = router.location.query;

  if (!category || !success) {
    router.replace('/');
    return null;
  }
  const heading =
    success === 'true'
      ? `You’ve successfully unsubscribed from ${category} emails.`
      : `There was a problem unsubscribing from ${category} emails.  Please try again later.`;

  return (
    <LogoOnlyLayout>
      <Helmet>
        <title>Unsubscribe</title>
      </Helmet>
      <Container maxWidth="sizeContainerSmall">
        <Heading as="h1" variant="large" color="colorTextInverted" marginBottom="spacingLarge4X">
          {heading}
        </Heading>
        <Text as="p" variant="bodySmall" color="colorTextInverted">
          Didn’t mean to?{' '}
          <Link href="mailto:support@getatomi.com" variant="inverted">
            Contact support
          </Link>
          .
        </Text>
      </Container>
    </LogoOnlyLayout>
  );
}

export default withRouter(Unsubscribe);
