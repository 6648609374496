// @flow
import jwtDecodeUnsafe from 'jwt-decode';

export type AtomiJWTSubscription = {
  id: number,
  is_free: boolean,
  role: string,
};

export type AtomiJWT = {
  aud: string,
  exp: number,
  iat: number,
  is_admin: boolean,
  jti: string,
  nbf: number,
  scopes: string[],
  sub: string, // This is the userId
  subscriptions?: AtomiJWTSubscription[],
};

export function decodeAtomiAccessToken(token: string): AtomiJWT {
  // `jwtDecode` is not type-safe and is currently an `any` type
  // so we provide a type-safe wrapper around it here.
  return jwtDecodeUnsafe(token);
}
