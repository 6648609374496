// @flow
import {
  getChallengeFirstItemUrl,
  getTaskChallengeFirstItemUrl,
  type GetChallengeFirstItemUrlArgs,
  type GetTaskChallengeFirstItemUrlArgs,
} from 'src/utils/routes';

import type { Lesson } from '../useGetModuleLesson';
import PostChallengeStartContainer from '../PostChallengeStart/PostChallengeStartContainer';
import PostChallengeResultsContainer from '../PostChallengeResults/PostChallengeResultsContainer';

export type NextLessonProps = {|
  isLocked: boolean,
  url: string,
|};

export type PostChallengeProps = {|
  accountId: string,
  actions: React.Node,
  classId: string,
  isChallengeInProgress: boolean,
  isChallengeResumeEnabled: boolean,
  isEmbedded: boolean,
  isLoggedInAsChild: boolean,
  isLti: boolean,
  isSchoolPlan: boolean,
  labels: React.Node,
  lesson: Lesson,
  moduleId: string,
  nextLessonProps: NextLessonProps | null,
  taskId?: string,
  view: 'Start' | 'Results',
|};

export default function PostChallenge({
  accountId,
  classId,
  moduleId,
  actions,
  isChallengeInProgress,
  isLoggedInAsChild,
  isSchoolPlan,
  lesson,
  labels,
  nextLessonProps,
  view,
  isEmbedded,
  isLti,
  isChallengeResumeEnabled,
  taskId,
}: PostChallengeProps): React.Node {
  let challengeFirstItemUrl;
  let restartChallengeUrl;
  if (taskId) {
    const baseUrlArgs: GetTaskChallengeFirstItemUrlArgs = {
      accountId,
      classId,
      taskId,
      postId: lesson.id,
      restartChallenge: false,
    };
    challengeFirstItemUrl = getTaskChallengeFirstItemUrl(baseUrlArgs);
    restartChallengeUrl = getTaskChallengeFirstItemUrl({
      ...baseUrlArgs,
      restartChallenge: true,
    });
  } else {
    const baseUrlArgs: GetChallengeFirstItemUrlArgs = {
      accountId,
      classId,
      moduleId,
      postId: lesson.id,
      isLti,
      isEmbedded,
      restartChallenge: false,
    };
    challengeFirstItemUrl = getChallengeFirstItemUrl(baseUrlArgs);
    restartChallengeUrl = getChallengeFirstItemUrl({
      ...baseUrlArgs,
      restartChallenge: true,
    });
  }

  function onRestartChallenge() {
    window.location.assign(restartChallengeUrl);
  }

  if (view === 'Start') {
    return (
      <PostChallengeStartContainer
        secondaryActions={actions}
        challengeFirstItemUrl={challengeFirstItemUrl}
        badges={labels}
        lesson={lesson}
        isChallengeResumeEnabled={isChallengeResumeEnabled}
        isChallengeInProgress={isChallengeInProgress}
        onRestartChallenge={onRestartChallenge}
      />
    );
  }

  return (
    <PostChallengeResultsContainer
      accountId={accountId}
      challengeFirstItemUrl={challengeFirstItemUrl}
      isLoggedInAsChild={isLoggedInAsChild}
      isSchoolPlan={isSchoolPlan}
      nextLessonProps={nextLessonProps}
    />
  );
}
