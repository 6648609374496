//@flow
import urlRegex from 'url-regex-safe';

export function isValidURL(url: string) {
  // Disable re2 to avoid devependency vulnerabilities
  // See https://github.com/hschub/carbon/pull/2983
  return urlRegex({ exact: true, re2: false, strict: false }).test(url);
}

export function sanitiseURL(url: string) {
  return !/^https?:\/\//i.test(url) ? `http://${url}` : url;
}
