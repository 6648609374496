// @flow

import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Container } from '@getatomi/neon';
import invariant from 'invariant';

import { ChallengeStartLoader } from 'src/components/ChallengeStart/ChallengeStart';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import { isLoggedInAsStudent } from 'src/reducers/subscriptions';
import type { ReduxState } from 'src/types';

import RevisionChallenge from './RevisionChallenge/RevisionChallenge';
import { useGetRevision } from './useGetRevision';

export type Params = {|
  classId: string,
  revisionId: string,
  splat?: string,
  subscriptionId: string,
  taskId?: string,
|};

export type Props = {|
  params: Params,
|};
export type InjectedProps = {|
  ...Props,
  isStudent: boolean,
|};

export function RevisionQuery(props: InjectedProps) {
  const { isStudent, params } = props;
  const { classId, revisionId, subscriptionId, taskId } = params;

  const { data, loading, error } = useGetRevision({
    accountId: subscriptionId,
    classId,
    isStudent,
    revisionId,
    taskId,
  });

  if (loading) {
    return <ChallengeStartLoader />;
  }

  if (error) {
    return <GraphQLError error={error} description="We couldn’t load your revision." />;
  }

  invariant(data, 'Revision data should be defined.');

  const {
    title,
    userClass,
    duration,
    questionsCount,
    totalMarks,
    timeLimitInSeconds,
    isChallengeResumeEnabled,
    isChallengeInProgress,
    isStudentRevision,
    task,
  } = data;

  // Our current route structure includes an optional "splat" parameter on the end:
  // classes/:classId/revisions/:revisionId(/**)
  // When viewing a revision we use a splat value of "results" to indicate
  // that we want to show the revision results page rather than the revision start page.
  const hasResultsSplat = params.splat === 'results';

  return (
    <Container>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <RevisionChallenge
        accountId={subscriptionId}
        challengeId={revisionId}
        class={userClass}
        duration={duration}
        isStudent={isStudent}
        isStudentRevision={isStudentRevision}
        questionsCount={questionsCount}
        timeLimitInSeconds={timeLimitInSeconds}
        title={title}
        totalMarks={totalMarks}
        view={hasResultsSplat ? 'Results' : 'Start'}
        isChallengeResumeEnabled={isChallengeResumeEnabled}
        isChallengeInProgress={isChallengeInProgress}
        task={task}
      />
    </Container>
  );
}

export function RevisionContainer(props: InjectedProps) {
  return (
    <>
      <Helmet>
        <title>Revision</title>
      </Helmet>
      <RevisionQuery {...props} />
    </>
  );
}

const mapStateToProps = (state: ReduxState) => {
  return {
    isStudent: isLoggedInAsStudent(state),
  };
};

export default (connect(mapStateToProps, {})(RevisionContainer): React.AbstractComponent<InjectedProps>);
