// @flow
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '@getatomi/neon';

import useIsClientLoaded, { clientRequiredAttribute, loadingMessage } from 'src/hooks/useIsClientLoaded';
import { parseValidationErrors } from 'src/api/validation';
import scrollToActiveElement from 'src/utils/scrollToActiveElement';
import { formValidation } from 'src/constants/formValidation';

type OutboundFields = {|
  newPassword: string,
  newPasswordConfirmation: string,
  password: string,
|};

const labels = {
  password: 'Current password',
  newPassword: 'New password',
  newPasswordConfirmation: 'Confirm new password',
};

const resolver = yupResolver(
  yup.object().shape({
    password: yup.string().required().label(labels.password),
    newPassword: yup
      .string()
      .label(labels.newPassword)
      .required()
      .min(formValidation.password.minLength)
      .test('strength', '', function test() {
        if (this.parent.strength === null) return true;
        return this.parent.strength >= formValidation.password.minStrength
          ? true
          : this.createError({
              message:
                'The password you’ve submitted is too easy to guess and would risk the security of your account. Try creating a longer password and/or avoid using common words or sequences.',
            });
      }),
    newPasswordConfirmation: yup
      .string()
      .required()
      .oneOf([yup.ref('newPassword')], 'Your confirmation password must match your new password')
      .label(labels.newPasswordConfirmation),
  })
);

export default function useChangePasswordForm({
  onSubmitSuccess,
}: {
  onSubmitSuccess: (data: OutboundFields) => void | Promise<void>,
}) {
  const isClientLoaded = useIsClientLoaded();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { control, formState, handleSubmit, reset, setError, setValue, trigger } = useForm({
    resolver,
    defaultValues: {
      password: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
  });
  const { isSubmitted } = formState;

  const handleStrengthChange = useCallback(
    (strength: number) => {
      setValue('strength', strength, { shouldValidate: isSubmitted });
      // retrigger password validation if the form has already been submitted
      if (isSubmitted && strength) trigger('password');
    },
    [isSubmitted, setValue, trigger]
  );

  const onSubmit = async (event: Event) => {
    event.preventDefault();
    setIsLoading(true);

    handleSubmit(async (data) => {
      try {
        await onSubmitSuccess(data);
        toast.success('Your password has been updated successfully.');
      } catch (error) {
        const validationErrors = parseValidationErrors(error);
        setError('serverError', { type: 'manual', message: validationErrors });
        if (validationErrors.length === 0) {
          toast.error('There was an error updating your password. Please try again.');
        } else {
          scrollToActiveElement();
        }
      }
    })(event);
    setIsLoading(false);
  };

  // reset the form if the submission was successful
  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  const passwordMessage = formState.errors.password?.message ?? null;
  const newPasswordMessage = formState.errors.newPassword?.message ?? null;
  const newPasswordConfirmationMessage = formState.errors.newPasswordConfirmation?.message ?? null;
  const { serverError } = formState.errors;

  return {
    control,
    fields: {
      password: {
        label: labels.password,
        validationText: passwordMessage,
        errorVariant: passwordMessage && 'error',
        type: 'password',
        isRequired: true,
        isReadOnly: !isClientLoaded,
      },
      newPassword: {
        label: labels.newPassword,
        onStrengthChange: handleStrengthChange,
        helpText: `Must contain at least ${formValidation.password.minLength} characters.`,
        validationText: newPasswordMessage,
        errorVariant: newPasswordMessage && 'error',
        isRequired: true,
        isReadOnly: !isClientLoaded,
      },
      newPasswordConfirmation: {
        label: labels.newPasswordConfirmation,
        validationText: newPasswordConfirmationMessage,
        errorVariant: newPasswordConfirmationMessage && 'error',
        type: 'password',
        isRequired: true,
        isReadOnly: !isClientLoaded,
      },
    },
    form: {
      ...clientRequiredAttribute,
      method: 'post',
      onSubmit,
    },
    submitButton: {
      type: 'submit',
      isDisabled: !isClientLoaded,
      title: isClientLoaded ? undefined : loadingMessage,
    },
    isLoading,
    serverError,
  };
}
