// @flow
import { Container, Modal, useId } from '@getatomi/neon';

import EmptyStateUpgradeStudentAccount from 'src/components/EmptyStateUpgradeStudentAccount/EmptyStateUpgradeStudentAccount';

export type UpgradeStudentDialogProps = {|
  isOpen: boolean,
  onClose: () => mixed,
  subscriptionId: string,
|};

export default function UpgradeStudentDialog(props: UpgradeStudentDialogProps) {
  const { isOpen, onClose, subscriptionId } = props;

  const headingId = useId();

  return (
    <Modal ariaLabelledBy={headingId} isOpen={isOpen} onClose={onClose} size="fullscreen" variant="view">
      <Container>
        <EmptyStateUpgradeStudentAccount headingId={headingId} subscriptionId={subscriptionId} />
      </Container>
    </Modal>
  );
}
