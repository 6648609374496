// @flow
import keyMirror from 'keymirror';

const onboardingIds = keyMirror({
  ACTIVATE_ACCOUNT: null,
  ADD_STUDENTS_TO_CLASS: null,
  CREATE_CLASS: null,
  DISCOVER_LESSON: null,
  SHARE_LESSON: null,
});

export type OnboardingId = $Keys<typeof onboardingIds>;

export default onboardingIds;
