// @flow

import {
  getTaskRevisionFirstItemUrl,
  getRevisionFirstItemUrl,
  type GetRevisionFirstItemUrlArgs,
  type GetTaskRevisionFirstItemUrlArgs,
} from 'src/utils/routes';
import { ChallengeStart } from 'src/components/ChallengeStart/ChallengeStart';

import type { Task, UserClass } from '../useGetRevision';
import { RevisionChallengeLayout } from '../RevisionChallengeLayout';
import { RevisionChallengeBadges } from '../RevisionChallengeBadges';

export type Props = {|
  accountId: string,
  challengeId: string,
  duration: number,
  isChallengeInProgress: boolean,
  isChallengeResumeEnabled: boolean,
  isStudent: boolean,
  isStudentRevision: boolean,
  questionsCount: number,
  task: Task,
  timeLimitInSeconds?: number,
  title: string,
  totalMarks: number,
  userClass: UserClass,
|};

export default function RevisionChallengeStart({
  accountId,
  challengeId,
  duration,
  isChallengeInProgress,
  isChallengeResumeEnabled,
  isStudent,
  isStudentRevision,
  questionsCount,
  task,
  timeLimitInSeconds,
  title,
  totalMarks,
  userClass,
}: Props): React.Node {
  let challengeFirstItemUrl;
  let restartRevisionUrl;

  if (task) {
    const baseUrlArgs: GetTaskRevisionFirstItemUrlArgs = {
      accountId,
      classId: userClass.id,
      challengeId,
      restartChallenge: false,
      taskId: task.id,
    };
    challengeFirstItemUrl = getTaskRevisionFirstItemUrl(baseUrlArgs);
    restartRevisionUrl = getTaskRevisionFirstItemUrl({
      ...baseUrlArgs,
      restartChallenge: true,
    });
  } else {
    const baseUrlArgs: GetRevisionFirstItemUrlArgs = {
      accountId,
      classId: userClass.id,
      challengeId,
      restartChallenge: false,
    };
    challengeFirstItemUrl = getRevisionFirstItemUrl(baseUrlArgs);
    restartRevisionUrl = getRevisionFirstItemUrl({
      ...baseUrlArgs,
      restartChallenge: true,
    });
  }

  function onRestartChallenge() {
    window.location.assign(restartRevisionUrl);
  }

  return (
    <RevisionChallengeLayout
      accountId={accountId}
      isStudent={isStudent}
      task={task}
      userClass={userClass}
      navigationType={isStudentRevision ? 'backLink' : 'breadcrumbs'}
    >
      <ChallengeStart
        secondaryActions={null}
        challengeFirstItemUrl={challengeFirstItemUrl}
        duration={duration}
        badges={<RevisionChallengeBadges subject={userClass.subject} />}
        questionsCount={questionsCount}
        tier="TIER_4_REVISION"
        timeLimitInSeconds={timeLimitInSeconds}
        title={title}
        totalMarks={totalMarks}
        isChallengeResumeEnabled={isChallengeResumeEnabled}
        isChallengeInProgress={isChallengeInProgress}
        onRestartChallenge={onRestartChallenge}
      />
    </RevisionChallengeLayout>
  );
}
