// @flow

import type { NewAttachedContent } from 'src/domains/Tasks/types';
import useTaskDialog from 'src/components/useTaskDialog/useTaskDialog';
import useTeacherRevisionDialog from 'src/components/useTeacherRevisionDialog/useTeacherRevisionDialog';

type Props = {|
  accountId: string,
  classId: string,
  isFreePlan: boolean,
  region: string,
|};

type Output = {|
  openTaskDialog: () => void,
  openTeacherRevisionDialog: () => void,
  taskDialog: React.Node,
  teacherRevisionDialog: React.Node,
|};

export default function useUniversalCreate(props: Props): Output {
  const { classId, accountId, isFreePlan, region } = props;

  const [taskDialog, { openTaskDialog }] = useTaskDialog({
    classId,
    subscriptionId: accountId,
  });

  const { dialog: teacherRevisionDialog, open: openTeacherRevisionDialog } = useTeacherRevisionDialog({
    isFreePlan,
    onCreate: (newRevision: NewAttachedContent) =>
      openTaskDialog({
        contents: [newRevision],
      }),
    region,
  });

  return {
    openTaskDialog,
    openTeacherRevisionDialog,
    taskDialog,
    teacherRevisionDialog,
  };
}
