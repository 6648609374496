// @flow
import type { Revision } from '../useGetClassSubject/useGetClassSubject';
import RevisionCallout from '../RevisionCallout/RevisionCallout';

type Props = {|
  revision: Revision | null,
|};

export default function SubjectRevision(props: Props) {
  const { revision } = props;

  if (!revision) {
    return (
      <RevisionCallout
        buttonProps={{
          label: 'Revise now',
          isDisabled: true,
        }}
        text="Once you complete some quizzes recommendations will show up here!"
      />
    );
  }

  return (
    <RevisionCallout
      text="It looks like it’s time to revise:"
      buttonProps={{
        label: 'Revise now',
        to: revision.url,
      }}
      linkProps={{
        label: revision.name,
        to: revision.url,
      }}
    />
  );
}
