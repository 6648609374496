// @flow
import type { ButtonSizesType } from 'src/types';
import RequestCallback from 'src/components/RequestCallback/RequestCallback';
import SubscriptionLink from 'src/components/SubscriptionLink/SubscriptionLink';
import UpgradeCtaComponent from 'src/components/UpgradeCta/UpgradeCta';

import styles from './UpgradeCta.module.scss';

export type UpgradeCtaProps = {|
  isChild: boolean,
  isRetail: boolean,
  onNavigate: () => mixed,
  size?: ButtonSizesType,
  testHook?: string,
|};

export default function UpgradeCta(props: UpgradeCtaProps) {
  const { isRetail, isChild, onNavigate, size, testHook } = props;

  return isRetail && !isChild ? (
    <SubscriptionLink upgrade>
      {(to) => (
        <UpgradeCtaComponent to={to} onClick={onNavigate} buttonLabel="Upgrade" buttonSize={size} testHook={testHook} />
      )}
    </SubscriptionLink>
  ) : (
    <RequestCallback className={styles.root}>
      {(onClick) => (
        <UpgradeCtaComponent
          onClick={onClick}
          buttonLabel={isChild ? 'Ask to upgrade' : 'Upgrade'}
          buttonSize={size}
          testHook={testHook}
        />
      )}
    </RequestCallback>
  );
}
