// @flow

import RevisionChallengeStart from '../RevisionChallengeStart/RevisionChallengeStart';
import RevisionChallengeResults from '../RevisionChallengeResults/RevisionChallengeResults';
import type { Task, UserClass } from '../useGetRevision';

export type Props = {|
  accountId: string,
  challengeId: string,
  class: UserClass,
  duration: number,
  isChallengeInProgress: boolean,
  isChallengeResumeEnabled: boolean,
  isStudent: boolean,
  isStudentRevision: boolean,
  questionsCount: number,
  task: Task,
  timeLimitInSeconds?: number,
  title: string,
  totalMarks: number,
  view: 'Start' | 'Results',
|};

export default function RevisionChallenge({
  accountId,
  challengeId,
  class: userClass,
  duration,
  isChallengeInProgress,
  isChallengeResumeEnabled,
  isStudent,
  isStudentRevision,
  timeLimitInSeconds,
  title,
  questionsCount,
  task,
  totalMarks,
  view,
}: Props) {
  if (view === 'Start') {
    return (
      <RevisionChallengeStart
        accountId={accountId}
        challengeId={challengeId}
        userClass={userClass}
        duration={duration}
        questionsCount={questionsCount}
        timeLimitInSeconds={timeLimitInSeconds}
        title={title}
        totalMarks={totalMarks}
        isChallengeResumeEnabled={isChallengeResumeEnabled}
        isChallengeInProgress={isChallengeInProgress}
        isStudent={isStudent}
        isStudentRevision={isStudentRevision}
        task={task}
      />
    );
  }

  return (
    <RevisionChallengeResults
      accountId={accountId}
      challengeId={challengeId}
      userClass={userClass}
      task={task}
      isStudent={isStudent}
      isStudentRevision={isStudentRevision}
    />
  );
}
