// @flow
import validateEmail, { isBlocking } from 'src/utils/validateEmail';
import type { BoundAction } from 'src/types';
import { checkExistingUser as checkExistingUserAction } from 'src/actions/auth';
import validators from 'src/utils/validators';

export default async function emailValidator({
  email,
  setEmailWarning,
  checkExistingUser,
}: {
  checkExistingUser?: BoundAction<typeof checkExistingUserAction>,
  email: string,
  setEmailWarning: (message: string | null) => void,
}) {
  // There are two async checks performed when validating an email. We only trigger the second if
  // the first passes to minimise requests.
  // 1. legitimate email according to Mailgun
  // 2. new email in Nucleus (`checkExistingUser`, from our Redux store)
  const { isServiceAvailable, level, message } = await validateEmail(email);

  // fallback to regex email validation if the API failed
  if (!isServiceAvailable) {
    const isValid = validators.email.pattern.test(email);
    if (!isValid) {
      return validators.email.patternMismatchErrorMessage;
    }
  }

  if (level && isBlocking(level)) {
    return message;
  }

  // warnings shouldn't block form submission but displayed below the email field
  setEmailWarning(message);

  if (!checkExistingUser) {
    return null;
  }

  const { errorMessage, hasInvite, isValid } = await checkExistingUser(email);

  if (!isValid) {
    return errorMessage;
  }
  if (hasInvite) {
    setEmailWarning(
      'We’ve already invited you to join an existing account. Please check your email and accept the invitation.'
    );
  }

  return null;
}
