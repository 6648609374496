// @flow
import { getDashboardUrl } from 'src/utils/routes';
import type { OnboardingId } from 'src/constants/onboardingIds';
import links from 'src/constants/links';

type StaticOnboardingAction = {|
  href?: string,
  isExternal?: boolean,
  label: string,
  to?: string | ((subscriptionId: string) => string),
|};

export type StaticOnboardingItem = {|
  actions: $ReadOnlyArray<StaticOnboardingAction> | null,
  body: string | null,
  id: OnboardingId,
  title: string,
|};

export const ONBOARDING_ITEMS: $ReadOnlyArray<StaticOnboardingItem> = [
  {
    id: 'ACTIVATE_ACCOUNT',
    title: 'Activate your account',
    body: null,
    actions: [],
  },
  {
    id: 'ADD_STUDENTS_TO_CLASS',
    title: 'Add students',
    body: 'Pressed for time? Contact our support team and we’ll do the heavy lifting for you.',
    actions: [
      {
        label: 'Add students',
        to: (subscriptionId) => getDashboardUrl(subscriptionId, { queryParams: { intercomAddStudents: 'true' } }),
      },
      {
        label: 'Learn more about adding students',
        href: links.support.addUsersToAccount,
        isExternal: true,
      },
      {
        label: 'Contact support',
        href: links.contact,
        isExternal: true,
      },
    ],
  },
  {
    id: 'CREATE_CLASS',
    title: 'Add a class',
    body: 'Once you’ve added a class you’ll see the content available for each subject.',
    actions: [
      {
        label: 'Add a class',
        to: (subscriptionId) => getDashboardUrl(subscriptionId, { queryParams: { intercomAddClass: 'true' } }),
      },
      {
        label: 'Learn more about adding classes',
        href: links.support.creatingClass,
        isExternal: true,
      },
    ],
  },
  {
    id: 'DISCOVER_LESSON',
    title: 'Discover a lesson',
    body: 'We enable active learning through short, impactful lessons. Let’s take a look.',
    actions: [
      {
        label: 'Start discovering',
        to: (subscriptionId) => getDashboardUrl(subscriptionId, { queryParams: { intercomDiscoverLesson: 'true' } }),
      },
    ],
  },
  {
    id: 'SHARE_LESSON',
    title: 'Share a lesson',
    body: 'Let’s learn how you can share Atomi lessons with your students and then get practising. We recommend using an Atomi task, giving you direct access to student progress and insights.',
    actions: [
      {
        label: 'Learn about sharing lessons',
        href: links.support.assignLessonAsTask,
        isExternal: true,
      },
    ],
  },
];
