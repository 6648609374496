// @flow

import useUpgradePrompt from 'src/hooks/components/useUpgradePrompt';

export type PostUpgradePromptProps = {|
  children?: React.Node,
  isLoggedInAsChild: boolean,
  isSchoolPlan: boolean,
  subscriptionId: string,
|};

export default function PostUpgradePrompt(props: PostUpgradePromptProps) {
  const { isLoggedInAsChild, isSchoolPlan, subscriptionId, children } = props;
  const [modal, createHandler] = useUpgradePrompt({
    shouldRequestUpgrade: isSchoolPlan || isLoggedInAsChild,
    subscriptionId,
  });

  const child = React.Children.only(children);
  const onClick = createHandler();

  return (
    <>
      {React.cloneElement(child, { onClick })}
      {modal}
    </>
  );
}
