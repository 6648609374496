// @flow

import { v4 as uuid } from 'uuid';

// The `header` argument format is expected to be "x-atomi-trace-id".
// This function converts it to the New Relic attribute name format,
// e.g. "request.headers.xAtomiTraceId"
export function toNewRelicAttributeName(header: string): string {
  const headerCamel = header.toLowerCase().replace(/-([a-z])/g, (_ignore, letter) => letter.toUpperCase());
  return `request.headers.${headerCamel}`;
}

export const ATOMI_HEADER_TRACE_ID = 'x-atomi-trace-id';
export const ATOMI_HEADER_USER_ID = 'x-atomi-user-id';

// I.e. "request.headers.xAtomiTraceId"
export const NEW_RELIC_ATTRIBUTE_TRACE_ID = toNewRelicAttributeName(ATOMI_HEADER_TRACE_ID);

export function makeTraceId(): string {
  return uuid();
}

export function isAtomiHeader(header: string): boolean {
  const headerLower = header.toLowerCase();
  return headerLower.startsWith('x-atomi-');
}

export function isAtomiUserIdHeader(header: string): boolean {
  const headerLower = header.toLowerCase();
  return headerLower === ATOMI_HEADER_USER_ID;
}

export type TracingClient = '@app/carbon/server' | '@app/carbon/client';

export type TracingHeaders = {|
  'x-atomi-account-id': string,
  'x-atomi-client': string,
  'x-atomi-path': string,
  'x-atomi-time': string,
  'x-atomi-trace-id': string,
  'x-atomi-user-id': string,
|};

export type MakeTracingHeadersArgs = {|
  accountId: string,
  client: TracingClient,
  path: string,
  traceId: string,
  userId: string,
|};

export function makeTracingHeaders(args: MakeTracingHeadersArgs): TracingHeaders {
  const { traceId, client, accountId, userId, path } = args;

  // This time header is included as potentially useful data when
  // investigating clock drift and refresh token issues.
  // Consider removing this header once those issues are resolved.
  const time = new Date().toISOString();

  const headers: TracingHeaders = {
    'x-atomi-account-id': accountId,
    'x-atomi-client': client,
    'x-atomi-path': path,
    'x-atomi-time': time,
    'x-atomi-trace-id': traceId,
    'x-atomi-user-id': userId,
  };

  return headers;
}
