// @flow
import { Box, Container, Text } from '@getatomi/neon';

import SubscriptionName from 'src/components/SubscriptionName/SubscriptionName';
import authCustomerTypes from 'src/constants/authCustomerTypes';
import useUpgradePrompt from 'src/hooks/components/useUpgradePrompt';
import Choice from 'src/components/Choice/Choice';
import getSubjectColors from 'src/utils/getSubjectColors';

import type { LessonLocation } from './useLessonLocations';

type Props = {
  data: $ReadOnlyArray<LessonLocation>,
};

function mapLegacyCustomerType(customerType): string {
  switch (customerType) {
    case 'SCHOOL':
      return authCustomerTypes.school;
    case 'RETAIL_FAMILY':
      return authCustomerTypes.family;
    default:
      return authCustomerTypes.student;
  }
}

export default function LessonLocationList(props: Props) {
  const { data } = props;
  const [modal, createHandler] = useUpgradePrompt();

  return (
    <>
      <Box marginBottom="spacingLarge4X">
        <Text as="p" variant="lead">
          Looks like you have more than one location for that lesson.
        </Text>
        <Text as="p" variant="lead">
          Which one would you like to access?
        </Text>
      </Box>
      <Container maxWidth="sizeContainerSmall">
        <Box as="ul" testHook="post-location-list">
          {data.map((location, i) => {
            const { class: locationClass, account, subject } = location;

            // only child can access post on family plans
            const isFreeFamilyChild = account.isFree && account.isFamilyPlan;
            const subjectColorRange = getSubjectColors(subject.groupCode, subject.color);

            return (
              <Choice
                key={locationClass.id}
                avatar={
                  <Box
                    backgroundColor={subjectColorRange.backgroundBold}
                    borderRadius="borderRadiusCircle"
                    flexShrink="0"
                    size="sizeAvatarSmall1X"
                  />
                }
                title={locationClass.name}
                description={
                  <SubscriptionName name={account.name} planType={mapLegacyCustomerType(account.customerType)} />
                }
                tags={[
                  ...subject.levels.map((level) => ({ children: level })),
                  account.isSchoolPlan ? { children: subject.name, color: subjectColorRange.text } : undefined,
                ].filter(Boolean)}
                // Using `href` instead of `to` as we want a full page refresh since the user might be
                // switching between subscriptions and we need the activeSubscriptionId (in the Redux
                // state) to be set correctly.
                href={!location.isLocked ? location.url : undefined}
                onClick={
                  location.isLocked
                    ? createHandler({
                        shouldRequestUpgrade: account.isSchoolPlan || isFreeFamilyChild,
                        subscriptionId: account.id,
                      })
                    : undefined
                }
                testHook={`post-location-list-item-${i}`}
              />
            );
          })}
        </Box>
      </Container>
      {modal}
    </>
  );
}
