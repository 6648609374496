// @flow
import { HideVisually, Skeleton, Stack } from '@getatomi/neon';

export default function OnboardingLoader() {
  return (
    <Stack spacing="spacingLarge3X">
      <HideVisually>Loading the onboarding items.</HideVisually>
      <Skeleton animation="wave" height={10} />
      <Stack spacing="spacingSmall">
        <Skeleton animation="wave" height={74} />
        <Skeleton animation="wave" height={182} />
        <Skeleton animation="wave" height={182} />
      </Stack>
    </Stack>
  );
}
