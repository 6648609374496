// @flow
import { Button } from '@getatomi/neon';

import { UnstyledButton } from 'src/components/Button/Button';
import { UnstyledLink } from 'src/components/Link/Link';
import type { ButtonSizesType } from 'src/types';

export type UpgradeCtaProps = {|
  buttonLabel: React.Node,
  buttonSize?: ButtonSizesType,
  onClick?: Function,
  testHook?: string,
  to?: string,
|};

export default function UpgradeCta(props: UpgradeCtaProps) {
  const { buttonLabel, buttonSize, onClick, testHook, to } = props;

  const isLink = Boolean(to);
  const Component = isLink ? UnstyledLink : UnstyledButton;

  return (
    <Component to={to} onClick={onClick} testHook={testHook}>
      <Button as="span" size={buttonSize}>
        {buttonLabel}
      </Button>{' '}
    </Component>
  );
}
