// @flow
import { Box, Container } from '@getatomi/neon';

import challengeTiers from 'src/constants/challengeTiers';

import CurriculumDescriptorTrigger from '../PostChallenge/CurriculumDescriptorTrigger';
import PostChallenge from '../PostChallenge/PostChallenge';
import type { Lesson } from '../useGetModuleLesson';

type ChallengeLessonProps = {|
  actions: React.Node,
  classId: string,
  getSiblingLessonUrl: (targetLesson: Lesson) => string,
  /**
   * Our current route structure includes an optional "splat" parameter on the end:
   * classes/:classId/modules/:moduleId/posts/:postId(/**)
   * When viewing a challenge post we use a splat value of "results" to indicate
   * that we want to show the challenge results page rather than the challenge start page.
   **/
  hasResultsSplat: boolean,
  isChallengeInProgress: boolean,
  isChallengeResumeEnabled: boolean,
  isLoggedInAsChild: boolean,
  isLoggedInAsStudent: boolean,
  isLti: boolean,
  isPostEmbedded: boolean,
  isSchoolPlan: boolean,
  lesson: Lesson,
  moduleId: string,
  nextLesson: ?Lesson,
  pagination: React.Node,
  subscriptionId: string,
  taskId?: string,
|};

export default function ChallengeLesson(props: ChallengeLessonProps) {
  const {
    actions,
    classId,
    lesson,
    moduleId,
    nextLesson,
    getSiblingLessonUrl,
    hasResultsSplat,
    isSchoolPlan,
    isLoggedInAsChild,
    isLoggedInAsStudent,
    pagination,
    isLti,
    isPostEmbedded,
    isChallengeResumeEnabled,
    isChallengeInProgress,
    subscriptionId,
    taskId,
  } = props;

  return (
    <>
      <Container textAlign="center" marginBottom="spacingLarge5X" marginTop="spacingLarge3X">
        <Box marginBottom="spacingLarge1X">
          <PostChallenge
            accountId={subscriptionId}
            actions={actions}
            classId={classId}
            isEmbedded={isPostEmbedded}
            isLoggedInAsChild={isLoggedInAsChild}
            isLti={isLti}
            isSchoolPlan={isSchoolPlan}
            lesson={lesson}
            labels={null}
            moduleId={moduleId}
            taskId={taskId}
            nextLessonProps={
              nextLesson ? { isLocked: nextLesson.isLocked, url: getSiblingLessonUrl(nextLesson) } : null
            }
            view={hasResultsSplat ? 'Results' : 'Start'}
            isChallengeResumeEnabled={isChallengeResumeEnabled}
            isChallengeInProgress={isChallengeInProgress}
          />
        </Box>
        <CurriculumDescriptorTrigger
          description={lesson.description}
          isInteractiveLesson={lesson.tier === challengeTiers.TIER_5_INTERACTIVE_LESSON}
          isLoggedInAsStudent={isLoggedInAsStudent}
        />
      </Container>

      {pagination && <Container>{pagination}</Container>}
    </>
  );
}
