// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import invariant from 'invariant';

import type {
  GetModuleLesson,
  GetModuleLesson_me_account_class_lesson as GetModuleLessonClassLesson,
  GetModuleLessonVariables,
} from 'src/graphql/types/generated/GetModuleLesson';

import {
  getChallengeSession,
  isChallengeResumeFeatureEnabled,
  isCrossSubjectLesson,
  transformClass,
  transformLesson,
  type CurrentClass,
  type Lesson,
} from './transformers';
import GET_MODULE_LESSON from './GetModuleLesson.graphql';

export type Challenge = {|
  id: string,
  questionsCount: number,
  title: string,
  totalMarks: number,
|};

type Props = {|
  classId: string,
  isStudent: boolean,
  lessonId: string,
  subscriptionId: string,
|};

export type Output = {|
  currentClass: ?CurrentClass,
  error: ?ApolloError,
  isChallengeInProgress: boolean,
  isChallengeResumeEnabled: boolean,
  isCrossSubject: boolean,
  lesson: ?Lesson,
  loading: boolean,
  nextLesson: ?Lesson,
  previousLesson: ?Lesson,
  refetch: () => Promise<any>,
  region: ?string,
|};

export function getPreviousAndNextLessons(rawLesson: GetModuleLessonClassLesson): {
  nextLesson: ?Lesson,
  previousLesson: ?Lesson,
} {
  // BaseLesson types don't have `previousLesson` and `nextLesson` fields.
  invariant(
    rawLesson.__typename === 'ClassChallengeLesson' ||
      rawLesson.__typename === 'ClassLockedChallengeLesson' ||
      rawLesson.__typename === 'ClassVideoLesson' ||
      rawLesson.__typename === 'ClassLockedVideoLesson' ||
      rawLesson.__typename === 'ClassTextLesson' ||
      rawLesson.__typename === 'ClassLockedTextLesson' ||
      rawLesson.__typename === 'StudentChallengeLesson' ||
      rawLesson.__typename === 'StudentLockedChallengeLesson' ||
      rawLesson.__typename === 'StudentVideoLesson' ||
      rawLesson.__typename === 'StudentLockedVideoLesson' ||
      rawLesson.__typename === 'StudentTextLesson' ||
      rawLesson.__typename === 'StudentLockedTextLesson',
    `Unexpected lesson type: ${rawLesson.__typename}`
  );

  const rawNextLesson = rawLesson.nextLesson;
  const nextLesson = rawNextLesson && transformLesson(rawNextLesson);

  const rawPreviousLesson = rawLesson.previousLesson;
  const previousLesson = rawPreviousLesson && transformLesson(rawPreviousLesson);

  return { nextLesson, previousLesson };
}

export default function useGetModuleLesson(props: Props): Output {
  const { classId, isStudent, lessonId, subscriptionId } = props;

  const { data, error, loading, refetch } = useQuery<GetModuleLesson, GetModuleLessonVariables>(GET_MODULE_LESSON, {
    variables: {
      accountId: subscriptionId,
      classId,
      isStudent,
      lessonId,
    },
  });

  if (error) {
    return {
      currentClass: null,
      error,
      isChallengeInProgress: false,
      isChallengeResumeEnabled: false,
      isCrossSubject: false,
      lesson: null,
      loading: false,
      nextLesson: null,
      previousLesson: null,
      refetch,
      region: null,
    };
  }

  if (loading && !data) {
    return {
      currentClass: null,
      error: null,
      isChallengeInProgress: false,
      isChallengeResumeEnabled: false,
      isCrossSubject: false,
      lesson: null,
      loading: true,
      nextLesson: null,
      previousLesson: null,
      refetch,
      region: null,
    };
  }

  const rawClass = data?.me?.account?.class;
  const rawLesson = rawClass?.lesson;
  invariant(rawClass && rawLesson, 'Lesson data is missing');

  const lesson = {
    ...transformLesson(rawLesson),
    hasTasks: Boolean(rawClass?.tasks?.edges.length),
  };
  const currentClass = transformClass(rawClass);

  const { nextLesson, previousLesson } = getPreviousAndNextLessons(rawLesson);
  const isCrossSubject = isCrossSubjectLesson(rawClass, rawLesson);
  const isChallengeResumeEnabled = isChallengeResumeFeatureEnabled(data?.me?.account);
  const challengeSession = getChallengeSession(rawLesson);
  const isChallengeInProgress = Boolean(challengeSession);

  return {
    currentClass,
    error: null,
    isChallengeInProgress,
    isChallengeResumeEnabled,
    isCrossSubject,
    lesson,
    loading: false,
    nextLesson,
    previousLesson,
    refetch,
    region: data?.me?.account?.region.code,
  };
}

export type { CurrentClass, Lesson, Subject } from './transformers';
