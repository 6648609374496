// @flow
import { Container, Skeleton } from '@getatomi/neon';
import Helmet from 'react-helmet';
import invariant from 'invariant';

import {
  getClassTasksUrl,
  getRevisionUrl,
  getRevisionMarksUrl,
  getRevisionProgressReportUrl,
  getTaskUrl,
} from 'src/utils/routes';
import challengeTiers from 'src/constants/challengeTiers';
import ClassBreadcrumbs, { BreadcrumbsItem } from 'src/domains/Class/ClassBreadcrumbs/ClassBreadcrumbs';

import OverviewLoader from './Overview/OverviewLoader/OverviewLoader';
import Overview from './Overview/Overview';
import { useGetRevisionOverview } from './useGetRevisionOverview';

export type RevisionOverviewContainerProps = {|
  params: {|
    classId: string,
    revisionId: string,
    subscriptionId: string,
    taskId?: string,
  |},
|};

export function RevisionOverviewContainer(props: RevisionOverviewContainerProps) {
  const { params } = props;
  const { classId, revisionId, subscriptionId: accountId, taskId } = params;

  const revisionUrl = getRevisionUrl(accountId, classId, revisionId, taskId);
  const marksUrl = getRevisionMarksUrl(accountId, classId, revisionId, taskId);
  const overviewUrl = getRevisionProgressReportUrl(accountId, classId, revisionId, taskId);

  const { data, loading } = useGetRevisionOverview({ accountId, classId, revisionId, taskId });

  if (loading && !data) {
    return (
      <>
        <Container marginBottom="spacingLarge3X">
          <Skeleton height={28} width={300} />
        </Container>
        <OverviewLoader
          heading="How’s everyone going with this revision?"
          marksUrl={marksUrl}
          overviewUrl={overviewUrl}
        />
      </>
    );
  }

  invariant(data, 'VQR overview data should be defined');
  const { challengingQuestions, classData, revision, students } = data;

  return (
    <>
      <Helmet>
        <title>
          {revision.title} | {classData.subject.name}
        </title>
      </Helmet>
      <Container marginBottom="spacingLarge3X">
        <ClassBreadcrumbs
          currentClass={classData}
          // Retail plans don't access VQR
          isRetailPlan={false}
          subject={classData.subject}
          subscriptionId={accountId}
        >
          {/* If we wrap these in a fragment, the breadcrumb miscounts them as a single item, not two */}
          {taskId && <BreadcrumbsItem href={getClassTasksUrl(accountId, classId)}>Tasks</BreadcrumbsItem>}
          {taskId && (
            <BreadcrumbsItem href={getTaskUrl(accountId, classId, taskId)}>{data.task?.title}</BreadcrumbsItem>
          )}
          <BreadcrumbsItem href={revisionUrl}>{revision.title}</BreadcrumbsItem>
        </ClassBreadcrumbs>
      </Container>
      <Overview
        averageMark={revision.averageMark}
        challengeTier={challengeTiers.TIER_4_REVISION}
        challengingQuestions={challengingQuestions}
        completionRate={revision.completionRate}
        marksUrl={marksUrl}
        overviewUrl={overviewUrl}
        shouldHideRevisionPanel
        students={students}
      />
    </>
  );
}

export default RevisionOverviewContainer;
