// @flow
import invariant from 'invariant';
import { useQuery, ApolloError } from '@apollo/client';

import type { GetChallengeMarks, GetChallengeMarksVariables } from 'src/graphql/types/generated/GetChallengeMarks';
import postTypes from 'src/constants/postTypes';
import { getPostUrl } from 'src/utils/routes';
import type { MarksStudent } from 'src/domains/ViewingQuizResults/types';
import type { LessonMetrics } from 'src/domains/ViewingQuizResults/Marks/StudentStrength/StudentStrength';
import type { NewAttachedContent } from 'src/domains/Tasks/types';

import GET_CHALLENGE_MARKS from './GetChallengeMarks.graphql';
import { getChallengeQuestionUrlFactory } from './utilities/getQuestionUrlFactory';
import { prepareChallengeStudents } from './utilities/prepareMarksData/prepareMarksData';

type Props = {|
  accountId: string,
  classId: string,
  lessonId: string,
  taskId?: string,
|};

export type PreparedMarksData = {|
  classData: {|
    id: string,
    name: string,
    subject: {|
      color: string,
      groupCode: string,
      name: string,
    |},
  |},
  lesson: {|
    ...$Exact<NewAttachedContent>,
    averageMarkMetrics: LessonMetrics,
    moduleName: string,
  |},
  sequence: $ReadOnlyArray<string>,
  students: $ReadOnlyArray<MarksStudent>,
  task: {|
    id: string,
    title: string,
  |} | null,
|};

type Output = {|
  data: ?PreparedMarksData,
  error: ?ApolloError,
  loading: boolean,
|};

export function useGetChallengeMarks(props: Props): Output {
  const { accountId, classId, lessonId, taskId } = props;

  const { data, loading, error } = useQuery<GetChallengeMarks, GetChallengeMarksVariables>(GET_CHALLENGE_MARKS, {
    variables: {
      accountId,
      classId,
      lessonId,
      includeTask: Boolean(taskId),
      taskIds: taskId ? [taskId] : [],
    },
  });

  if (error || (loading && !data)) {
    return { data: undefined, error, loading };
  }

  invariant(data, 'Query data should be defined');

  const accountData = data.me?.account;
  const classData = accountData?.class;
  invariant(
    accountData && classData && classData.lesson && classData.lesson.__typename === 'ClassChallengeLesson',
    'Class challenge lesson data should be defined'
  );

  const taskData = classData.tasks?.edges[0]?.node;
  invariant(taskId ? taskData : true, 'Task data should be defined');

  const { lesson, subject } = classData;
  const { classAverageMark, regionAverageMark, schoolAverageMark } = lesson.metrics.assessment;

  const moduleId = lesson.categories[0].id;
  const getChallengeQuestionUrl = getChallengeQuestionUrlFactory({
    accountId,
    classId,
    moduleId,
    postId: lessonId,
    returnTo: `${window.location.pathname}${window.location.search}`,
  });

  return {
    data: {
      lesson: {
        id: lesson.id,
        duration: lesson.duration,
        name: lesson.name,
        moduleName: lesson.categories[0].name,
        subjectCode: subject.code,
        tier: lesson.tier,
        type: postTypes.challenge,
        moduleId: lesson.categories[0].id,
        url: getPostUrl(accountId, classId, moduleId, lessonId),
        averageMarkMetrics: {
          classAverageMark,
          regionAverageMark,
          schoolAverageMark,
        },
      },
      classData: {
        id: classData.id,
        name: classData.name,
        subject: {
          color: subject.color,
          groupCode: subject.groupCode,
          name: subject.name,
        },
      },
      sequence: lesson.challenge.items.map<string>((item) => item.id),
      students: prepareChallengeStudents(
        classData.students,
        lesson.latestAttempts,
        lesson.metrics,
        lesson.challenge.items,
        getChallengeQuestionUrl
      ),
      task: taskData ? { id: taskData.id, title: taskData.title ?? '' } : null,
    },
    error,
    loading,
  };
}
