// @flow
import moment from 'moment';
import { Button, IconDownload, ButtonLoader, useMediaQuery, IconButton, Skeleton } from '@getatomi/neon';

import { trackingCtaTypes, trackingEvents, trackingCtas } from 'src/constants/tracking';
import { trackEvent } from 'src/utils/tracking';

import type { MarkbookLinks } from './MarkbookSubNav';
import type { TableData, ColumnList } from './MarkbookControls';
import useDownloadUrl from '../hooks/useDownloadUrl';
import sanitizeFilename from '../utils/sanitizeFilename';

type Props = {
  activeLink: MarkbookLinks,
  className: string,
  columnList?: ColumnList,
  isErrorState?: boolean,
  isLoading?: boolean,
  tableData?: TableData,
};

export default function MarkbookDownloadButton({
  isLoading,
  isErrorState,
  tableData,
  columnList,
  className,
  activeLink,
}: Props) {
  const url = useDownloadUrl(tableData, columnList);

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const sanitizedClassName = sanitizeFilename(className);

  if (isErrorState) return null;

  if (isLoading)
    return isMobile ? <Skeleton height={40} width={40} /> : <ButtonLoader testHook="export-button-loader" />;

  const downloadProps = {
    onClick: () => {
      trackEvent(trackingEvents.ctaClicked, {
        cta: trackingCtas.markbookDownload,
        type: trackingCtaTypes.button,
        route: activeLink,
      });
    },
    as: 'a',
    download: `${sanitizedClassName} mark book ${activeLink} ${moment().format('YYYY-MM-DD')}.csv`,
    href: url,
    type: 'Download',
    variant: 'secondary',
  };

  return !isMobile ? (
    <Button iconBefore={<IconDownload size="sizeIconSmall1X" />} {...downloadProps}>
      Download
    </Button>
  ) : (
    <IconButton assistiveText="Download mark book data" name="download" {...downloadProps}>
      <IconDownload />
    </IconButton>
  );
}
