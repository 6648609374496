// @flow

import { getClassTasksUrl, getTaskUrl, getRevisionPageUrl } from 'src/utils/routes';

export function makeContinueLink({
  accountId,
  classId,
  isStudent,
  taskId,
}: {|
  accountId: string,
  classId: string,
  isStudent: boolean,
  taskId: string | null,
|}): string {
  if (taskId) {
    return getTaskUrl(accountId, classId, taskId);
  }

  if (isStudent) {
    return getRevisionPageUrl(accountId);
  }

  return getClassTasksUrl(accountId, classId);
}
