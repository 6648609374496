// @flow
import pluralize from 'pluralize';
import { Text } from '@getatomi/neon';

import type { DateString } from 'src/types';
import Date from 'src/components/Date/Date';

export type PlanDescriptionProps = {|
  description: string,
  expiryDate: DateString,
  isFamilyPlan: boolean,
  isFreeFamilyPlan: boolean,
  isPaidSchool: boolean,
  isTrialSubscription: boolean,
  numSeats: number,
  trialEndDate: DateString,
|};

function makePaidSchoolPlanText(isTrialSubscription: boolean, trialEndDate: DateString, expiryDate: DateString) {
  return (
    <>
      Your {isTrialSubscription ? 'trial' : 'contract'} will {isTrialSubscription ? 'end' : 'expire'} on{' '}
      <strong>
        <Date value={isTrialSubscription ? trialEndDate : expiryDate} />
      </strong>
      .
    </>
  );
}

function makeFamilyPlanText(isFreeFamilyPlan: boolean, seatCount: number) {
  const studentCount = isFreeFamilyPlan || seatCount === 1 ? 'one' : seatCount;

  return isFreeFamilyPlan ? (
    <>
      Limited access to content and features for <strong>you</strong> and{' '}
      <strong>
        {studentCount} {pluralize('student', seatCount)}
      </strong>
      .
    </>
  ) : (
    <>
      That’s full access to all content and features for <strong>you</strong> and{' '}
      <strong>
        {studentCount} {pluralize('student', seatCount)}
      </strong>
      , anytime, on any device.
    </>
  );
}

export default function PlanDescription(props: PlanDescriptionProps) {
  const {
    description,
    expiryDate,
    isFamilyPlan,
    isFreeFamilyPlan,
    isPaidSchool,
    isTrialSubscription,
    numSeats,
    trialEndDate,
  } = props;

  const seatCount = numSeats === 0 ? 1 : numSeats;

  let text = description;
  if (isPaidSchool) {
    text = makePaidSchoolPlanText(isTrialSubscription, trialEndDate, expiryDate);
  } else if (isFamilyPlan) {
    text = makeFamilyPlanText(isFreeFamilyPlan, seatCount);
  }

  return (
    <Text as="p" variant="bodySmall1X" color="colorTextSubtler" testHook="hide-in-percy">
      {text}
    </Text>
  );
}
