// @flow
import { connect } from 'react-redux';
import { Container, EmptyState, Illustration } from '@getatomi/neon';
import invariant from 'invariant';

import type { ReduxState, TrackingDataType } from 'src/types';
import Button from 'src/components/Button/Button';
import Link from 'src/components/Link/Link';
import VideoPlayer from 'src/components/VideoPlayer/VideoPlayer';
import useUpgradePrompt from 'src/hooks/components/useUpgradePrompt';
import {
  getActiveSubscriptionId,
  isSchoolPlan as isSchoolPlanSelector,
  isFamilyPlan as isFamilyPlanSelector,
  isLoggedInAsChild as isLoggedInAsChildSelector,
} from 'src/reducers/subscriptions';
import links from 'src/constants/links';
import { trackingEvents, trackingCtas, trackingCtaTypes } from 'src/constants/tracking';
import { trackEvent } from 'src/utils/tracking';

type Props = {
  description: React.Node,
  descriptionLink?: React.Node,
  heading: React.Node,
  headingId?: string,
  illustrationName?: $PropertyType<React.ElementProps<typeof Illustration>, 'name'>,
  trackingData?: TrackingDataType,
  videoId?: string,
};
type InjectedProps = Props & {
  isFamilyPlan: boolean,
  isLoggedInAsChild: boolean,
  isSchoolPlan: boolean,
  subscriptionId: string,
};

function UpgradeAccountPanel(props: InjectedProps) {
  const {
    description,
    descriptionLink,
    heading,
    headingId,
    illustrationName,
    isFamilyPlan,
    isLoggedInAsChild,
    isSchoolPlan,
    subscriptionId,
    trackingData,
    videoId,
  } = props;
  const [modal, createHandler, upgradeUrl] = useUpgradePrompt({
    shouldRequestUpgrade: isSchoolPlan || isLoggedInAsChild,
    subscriptionId,
  });

  const trackEventHandler = (handler) => () => {
    trackEvent(trackingEvents.ctaClicked, {
      ...trackingData,
      cta: trackingCtas.requestUpgradeOpenDialog,
      type: trackingCtaTypes.button,
    });
    handler?.();
  };

  invariant(illustrationName || videoId, 'illustrationName or videoId prop should be provided');

  return (
    <Container>
      <EmptyState
        media={
          illustrationName ? (
            <Illustration name={illustrationName} variant="brandColor" />
          ) : (
            videoId && (
              <Container marginBottom="spacingLarge4X" paddingInline="spacingNone" maxWidth="sizeContainerSmall1X">
                <VideoPlayer videoId={videoId} testHook="hide-in-percy" />
              </Container>
            )
          )
        }
        heading={heading}
        headingProps={{ id: headingId }}
        description={description}
        descriptionLink={descriptionLink}
        primaryAction={
          <Button
            as={upgradeUrl ? Link : undefined}
            to={upgradeUrl}
            onClick={trackEventHandler(isSchoolPlan || isLoggedInAsChild ? createHandler() : undefined)}
            testHook="upgrade-account-panel-button"
          >
            {
              // eslint-disable-next-line no-nested-ternary
              isSchoolPlan ? 'Request an upgrade' : isLoggedInAsChild ? 'Ask to upgrade' : 'Upgrade'
            }
          </Button>
        }
        helpInfo={
          <Link
            href={
              isSchoolPlan // eslint-disable-line no-nested-ternary
                ? links.support.upgrading.school
                : isFamilyPlan
                ? links.support.upgrading.parent
                : links.support.upgrading.student
            }
            isExternal
            variant="monochrome"
          >
            Learn more about upgrading in the Help Centre.
          </Link>
        }
        variant="highlighted"
        testHook="upgrade-account-panel"
      />
      {(isSchoolPlan || isLoggedInAsChild) && modal}
    </Container>
  );
}

export default (connect((state: ReduxState) => ({
  isFamilyPlan: isFamilyPlanSelector(state),
  isSchoolPlan: isSchoolPlanSelector(state),
  isLoggedInAsChild: isLoggedInAsChildSelector(state),
  subscriptionId: getActiveSubscriptionId(state),
}))(UpgradeAccountPanel): React.AbstractComponent<Props>);
