// @flow
import invariant from 'invariant';
import { useQuery, ApolloError } from '@apollo/client';

import type {
  GetRevisionOverview,
  GetRevisionOverviewVariables,
} from 'src/graphql/types/generated/GetRevisionOverview';
import type { ChallengeAverageMark } from 'src/domains/ViewingQuizResults/Overview/AverageMarkMetric/AverageMarkMetric';
import type { ChallengingQuestion, OverviewStudent } from 'src/domains/ViewingQuizResults/types';

import GET_REVISION_OVERVIEW from './GetRevisionOverview.graphql';
import { getRevisionQuestionUrlFactory } from './utilities/getQuestionUrlFactory';
import { prepareAttempts, prepareStudents } from './utilities/prepareOverviewData/prepareOverviewData';
import calculateChallengingQuestions from './utilities/prepareOverviewData/calculateChallengingQuestions';

type Props = {|
  accountId: string,
  classId: string,
  revisionId: string,
  taskId?: string,
|};

export type PreparedOverviewData = {|
  challengingQuestions: $ReadOnlyArray<ChallengingQuestion>,
  classData: {|
    id: string,
    name: string,
    subject: {|
      color: string,
      groupCode: string,
      name: string,
    |},
  |},
  revision: {|
    averageMark: ChallengeAverageMark,
    completionRate: {|
      total: number,
      value: number,
    |},
    title: string,
  |},
  students: $ReadOnlyArray<OverviewStudent>,
  task?: {|
    id: string,
    title: string,
  |},
|};

type Output = {|
  data: ?PreparedOverviewData,
  error: ?ApolloError,
  loading: boolean,
|};

export function useGetRevisionOverview(props: Props): Output {
  const { accountId, classId, revisionId, taskId } = props;

  const { data, loading, error } = useQuery<GetRevisionOverview, GetRevisionOverviewVariables>(GET_REVISION_OVERVIEW, {
    variables: {
      accountId,
      classId,
      includeTask: Boolean(taskId),
      revisionId,
      taskIds: taskId ? [taskId] : [],
    },
  });

  if (error || (loading && !data)) {
    return { data: undefined, error, loading };
  }

  const getChallengeQuestionUrl = getRevisionQuestionUrlFactory({
    accountId,
    classId,
    challengeId: revisionId,
    returnTo: `${window.location.pathname}${window.location.search}`,
  });

  invariant(data, 'Query data should be defined');

  const classData = data.me?.account?.class;
  invariant(
    classData && classData.revision && classData.revision.__typename === 'ClassRevision',
    'Class challenge revision should be defined'
  );
  const { revision, subject, tasks } = classData;
  const taskData = tasks?.edges[0]?.node;
  if (taskId) {
    invariant(taskData, `Revision's task data should be defined`);
  }

  const students = prepareStudents(classData.students, revision.metrics.assessment.students);
  const attempts = prepareAttempts(revision.latestAttempts);

  return {
    data: {
      challengingQuestions: calculateChallengingQuestions(revision.items, attempts, getChallengeQuestionUrl),
      classData: {
        id: classData.id,
        name: classData.name,
        subject: {
          color: subject.color,
          groupCode: subject.groupCode,
          name: subject.name,
        },
      },
      revision: {
        title: revision.title,
        completionRate: {
          total: students.length,
          value: revision.metrics.progress.engagedStudentCount,
        },
        averageMark: {
          value: revision.metrics.assessment.classAverageMark ?? 0,
        },
      },
      students,
      task: taskId
        ? {
            id: taskId,
            title: taskData.title ?? 'Untitled',
          }
        : undefined,
    },
    loading,
    error,
  };
}
