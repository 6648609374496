// @flow
import cx from 'classnames';
import { Container, IconText, ProgressCircle, useMediaQuery } from '@getatomi/neon';
import { useState, useEffect, useRef, useCallback } from 'react';

import lessonTypes from 'src/constants/lessonTypes';
import { trackingEvents } from 'src/constants/tracking';
import { trackEvent, getLessonTrackingData } from 'src/utils/tracking';
import useCompletion from 'src/hooks/completion/useCompletion';

import type { Lesson, Subject } from '../useGetModuleLesson';
import styles from './TextPostProgress.module.scss';

const PROGRESS_THRESHOLD = 0.75;
const SCROLL_OFFSET = 100; // approx. height of header

export type TextPostProgressProps = {|
  classId: string,
  lesson: Lesson,
  levelId: ?string,
  subject: Subject,
  subscriptionId: string,
|};

export default function TextPostProgress(props: TextPostProgressProps) {
  const { lesson, levelId, classId, subject, subscriptionId } = props;
  const [progress, setProgress] = useState<number>(0);
  const isTrackingDataCalled = useRef<boolean>(false);
  const { start: startLesson, completeLesson } = useCompletion({
    accountId: subscriptionId,
    classId,
    lessonId: lesson.id,
    lessonType: lessonTypes.TEXT,
    subjectCode: subject.code,
  });

  const onComplete = useCallback(async () => {
    trackEvent(
      trackingEvents.textPostCompleted,
      { data: getLessonTrackingData(lesson, subject, { classId, levelId }) },
      { verifyUser: true }
    );

    await completeLesson();
  }, [classId, completeLesson, levelId, lesson, subject]);

  const measureProgress = useCallback(async () => {
    // $FlowIgnore document.body is not null
    const scrollHeight = document.body.offsetHeight - SCROLL_OFFSET;
    // $FlowIgnore document.documentElement is not null
    const { clientHeight } = document.documentElement;
    const currentProgress = clientHeight >= scrollHeight ? 1 : window.scrollY / (scrollHeight - clientHeight);
    const isCompleted = currentProgress >= PROGRESS_THRESHOLD;
    const isAlreadyCompleted = Boolean(isTrackingDataCalled.current);

    if (isCompleted && !isAlreadyCompleted) {
      isTrackingDataCalled.current = true;
      onComplete();
    }

    setProgress((prevProgress) => Math.max(currentProgress, prevProgress));
  }, [onComplete]);

  useEffect(() => {
    // wait for viewport to settle (scroll to top on route change) before measuring progress
    setTimeout(measureProgress, 500);
    document.addEventListener('scroll', measureProgress);
    startLesson();

    return () => {
      document.removeEventListener('scroll', measureProgress);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  return (
    <div className={cx(styles.root, progress >= 0.01 && styles.withThreshold)}>
      <Container maxWidth="sizeContainerRoot">
        <div className={styles.anchorPoint}>
          <div className={styles.progressCircle}>
            <ProgressCircle
              ariaLabel="Your progress"
              icon={<IconText size="sizeIconSmall1X" />}
              value={progress}
              withCompletedState
              size={isMobile ? 'small1X' : 'small'}
              isOverlaid
            />
          </div>
        </div>
      </Container>
    </div>
  );
}
