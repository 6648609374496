// @flow
import _ from 'lodash';
import { Box, Flex, InfoTip, ProgressCircle } from '@getatomi/neon';

import postTypes from 'src/constants/postTypes';

import type { StudentWithProgress } from '../transformLessonProgress';

type LessonTypes = typeof postTypes.video | typeof postTypes.text;

type ProgressSummaryCircleProps = {|
  children: React.Node,
  isDisabled?: boolean,
  label: string,
  testHook: string,
  tooltip?: string,
  value: number,
|};

const tooltips = {
  [postTypes.video]: 'This is the number of students in your class that have completed this video.',
  [postTypes.text]: 'This is the number of students in your class that have completed this lesson.',
};

const getStudentsWithProgressCount = (students: Array<StudentWithProgress>) =>
  students.filter((student) => student.cumulativeCompletionCount).length;

function ProgressSummaryCircle(props: ProgressSummaryCircleProps) {
  const { children, isDisabled, label, testHook, tooltip, value } = props;

  return (
    <ProgressCircle
      as="meter"
      label={
        <Flex alignItems="center">
          <span>{label}</span>
          {tooltip && <InfoTip content={tooltip} iconSize="sizeIconSmall2X" />}
        </Flex>
      }
      isDisabled={isDisabled}
      value={value}
      testHook={testHook}
    >
      {children}
    </ProgressCircle>
  );
}

type ProgressSummaryProps = {|
  postType: LessonTypes,
  students: Array<StudentWithProgress>,
|};

export default function ProgressSummary(props: ProgressSummaryProps) {
  const { postType, students } = props;

  if (students.length === 0) {
    return null;
  }

  const studentsCount = students.length;
  const studentsWithProgress = getStudentsWithProgressCount(students);
  const completionRate = studentsCount && studentsWithProgress / studentsCount;
  const completionRateText = `${studentsWithProgress}/${studentsCount}`;
  const totalViews = _.sumBy(students, 'cumulativeCompletionCount') || 0;

  return (
    <Box
      display="grid"
      gridAutoFlow="column"
      gridTemplateColumns={{ base: 'repeat(2, 1fr)', tablet: 'repeat(2, 140px)' }}
      gap={{ base: 'spacingLarge1X', tablet: 'spacingLarge3X' }}
      marginInline="spacingLarge1X"
      alignItems="start"
      justifyContent={{ tablet: 'center' }}
      testHook="post-progress-summary"
    >
      <ProgressSummaryCircle
        label="Completion rate"
        value={completionRate}
        tooltip={tooltips[postType]}
        testHook="post-progress-summary-completion-rate"
      >
        {completionRateText}
      </ProgressSummaryCircle>
      <ProgressSummaryCircle label="Total views" value={0} isDisabled testHook="post-progress-summary-total-views">
        {totalViews}
      </ProgressSummaryCircle>
    </Box>
  );
}
