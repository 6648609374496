// @flow
import { Helmet } from 'react-helmet';
import type { ApolloError } from '@apollo/client';

import GraphQLError from 'src/components/GraphQLError/GraphQLError';

type LessonErrorProps = {|
  error: ApolloError,
|};

export default function LessonError(props: LessonErrorProps) {
  const { error } = props;

  return (
    <>
      <Helmet>
        <title>Oops, sorry!</title>
      </Helmet>
      <GraphQLError
        error={error}
        description="We couldn’t load the lesson page."
        customMessages={{
          NOT_FOUND: {
            description: 'We can’t seem to find the page you’re looking for.',
            heading: 'Oops, sorry!',
          },
        }}
      />
    </>
  );
}
