// @flow
import { useState } from 'react';
import {
  Box,
  Card,
  Flex,
  Heading,
  HideVisually,
  IconCircleCheck,
  IconCircleSolidCheck,
  IconChevron,
  Stack,
  Text,
  Tooltip,
  useId,
} from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import { UnstyledLink } from 'src/components/Link/Link';

import type { OnboardingItem as OnboardingItemType } from './useOnboardingItems/useOnboardingItems';

type OnboardingListItemProps = {|
  isExpandable: boolean,
  item: OnboardingItemType,
  shouldOpenByDefault: boolean,
|};

function makeTitleColor(props: { isCompleted: boolean, isDisabled: boolean }) {
  const { isCompleted, isDisabled } = props;
  if (isCompleted) return 'colorTextSubtler';
  if (isDisabled) return 'colorTextDisabled';
  return 'colorText';
}

function makeButtonVariant(props: { isCompleted: boolean, isSecondaryAction: boolean }) {
  const { isCompleted, isSecondaryAction } = props;

  if (isCompleted) return 'tertiary';
  if (isSecondaryAction) return 'secondary';

  return 'primary';
}

function makeChevronStyles(props: { isDisabled: boolean, isOpen: boolean }) {
  const { isDisabled, isOpen } = props;

  let color = 'colorIcon';

  if (isDisabled) {
    color = 'colorIconDisabled';
  } else if (isOpen) {
    color = 'colorIconSelected';
  }

  return {
    color,
    _hover: isDisabled ? undefined : { color: 'colorIconSelected' },
    marginLeft: 'auto',
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
    padding: 'spacingSmall1X',
    marginRight: 'spacingNegativeSmall1X',
  };
}

function useDisclosure(props: { isDisabled?: boolean, shouldOpenByDefault?: boolean }) {
  const { isDisabled = false, shouldOpenByDefault = false } = props;

  const [isOpen, setIsOpen] = useState<boolean>(shouldOpenByDefault);
  const buttonId = useId();
  const detailsId = useId();

  const handleToggle = () => {
    if (isDisabled) {
      return;
    }
    setIsOpen((state) => !state);
  };

  const sharedProps = {
    as: 'button',
    type: 'button',
    onClick: handleToggle,
    'aria-controls': detailsId,
    'aria-expanded': isOpen,
  };
  const titleProps = {
    ...sharedProps,
    id: buttonId,
    disabled: isDisabled,
    cursor: isDisabled ? 'default !important' : undefined,
  };
  const chevronProps = {
    ...sharedProps,
    'aria-labelledby': buttonId,
    'aria-disabled': isDisabled,
    // hide the chevron from screen readers when the item isn't disabled to avoid redundancy with the main button
    'aria-hidden': !isDisabled,
    // improve keyboard navigation by skipping the chevron button when the item isn't disabled
    tabIndex: isDisabled ? undefined : -1,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  };

  return {
    titleProps,
    chevronProps,
    panelProps: {
      id: detailsId,
      display: isOpen ? 'block' : 'none',
    },
    state: {
      isOpen,
    },
  };
}

function OnboardingItemIcon(props: { isCompleted: boolean }) {
  const { isCompleted } = props;

  return (
    <>
      <HideVisually>Status: {isCompleted ? 'completed' : 'incomplete'}</HideVisually>
      {isCompleted ? (
        <IconCircleSolidCheck color="colorIconSuccess" size="sizeIconRoot" />
      ) : (
        <IconCircleCheck color="colorIconDisabled" size="sizeIconRoot" />
      )}
    </>
  );
}

function OnboardingListItem(props: OnboardingListItemProps) {
  const { isExpandable, item, shouldOpenByDefault } = props;
  const { actions, body, isCompleted, isDisabled, title } = item;

  const { titleProps, chevronProps, panelProps, state } = useDisclosure({ isDisabled, shouldOpenByDefault });

  const itemTitle = (
    <Box display="grid" gridTemplateColumns="1fr auto" width="sizeFull" alignItems="center">
      <Box {...(isExpandable ? titleProps : {})} display="flex" alignItems="center" gap="spacingSmall">
        <OnboardingItemIcon isCompleted={isCompleted} />
        <Heading as="h2" variant="small" color={makeTitleColor({ isCompleted, isDisabled })}>
          <Box as={isCompleted ? 's' : 'span'}>{title}</Box>
        </Heading>
      </Box>
      {isExpandable && (
        <Tooltip isDisabled={!isDisabled} content="Add a class before continuing.">
          <Box {...chevronProps} {...makeChevronStyles({ isDisabled, isOpen: state.isOpen })}>
            <IconChevron size="sizeIconSmall2X" strokeWidth="2" />
          </Box>
        </Tooltip>
      )}
    </Box>
  );

  return (
    <Card padding="spacingLarge1X" variant="rounded" withHover={false}>
      {itemTitle}
      {isExpandable && (
        <Box {...panelProps}>
          {body && (
            <Text as="p" variant="bodySmall" color="colorTextSubtle" marginTop="spacingSmall">
              {body}
            </Text>
          )}
          {actions && actions.length > 0 && (
            <Flex wrap gap="spacingSmall1X" marginTop="spacingLarge1X">
              {actions.map(({ label, ...otherProps }, index) => (
                <Button
                  key={label}
                  as={UnstyledLink}
                  size="small1X"
                  variant={makeButtonVariant({ isCompleted, isSecondaryAction: actions.length > 1 && index > 0 })}
                  {...otherProps}
                >
                  {label}
                </Button>
              ))}
            </Flex>
          )}
        </Box>
      )}
    </Card>
  );
}

export type OnboardingListProps = {|
  items: $ReadOnlyArray<OnboardingItemType>,
|};

export default function OnboardingList(props: OnboardingListProps) {
  const { items } = props;

  if (items.length === 0) return null;

  const firstIncompleteItem = items.find((item) => !item.isCompleted);

  return (
    <Stack as="ol" spacing="spacingSmall">
      {items.map((item) => (
        <OnboardingListItem
          key={item.title}
          isExpandable={item.id !== 'ACTIVATE_ACCOUNT'}
          item={item}
          shouldOpenByDefault={!item.isDisabled && item.id === firstIncompleteItem?.id}
        />
      ))}
    </Stack>
  );
}
