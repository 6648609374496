// @flow
import Helmet from 'react-helmet';
import { EmptyState, Illustration, Text } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import links from 'src/constants/links';
import Auth from 'src/utils/Auth';

export default function LockedLesson() {
  const auth = new Auth();
  return (
    <>
      <Helmet>
        <title>Access forbidden!</title>
      </Helmet>
      <EmptyState
        media={<Illustration name="main-browser" />}
        heading="Access forbidden!"
        headingProps={{ as: 'h1' }}
        description="We’re sorry but you don’t have permission to access this page."
        primaryAction={
          <Text as="p" variant="bodyRoot">
            <Link href="/">Take me back to the home page</Link> or{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link as="button" variant="text" size="large" onClick={() => auth.logoutAndRedirect()}>
              log out
            </Link>
            .
          </Text>
        }
        helpInfo={
          <>
            Having trouble?{' '}
            <Link href={links.support.home} isExternal variant="monochrome">
              Find some useful information in our Help Centre
            </Link>
            .
          </>
        }
        testHook="error-page"
      />
    </>
  );
}
