// @flow
import { get, omit, pick } from 'lodash';
import type { LocationShape } from 'react-router';

import connectData from 'src/decorators/connectData';
import { NavigationContextProvider } from 'src/hooks/useNavigationContext';
import { loadPlans } from 'src/actions/plans';
import { loadCurrentSubscriptionUsers } from 'src/actions/users';
import { isPlansLoaded } from 'src/reducers/plans';
import { isLoggedInAsParent, isPaidSchoolPlan } from 'src/reducers/subscriptions';
import { isAllUsersLoaded } from 'src/reducers/users';
import type { MasterLayoutSpacingBetweenHeaderAndMainContent, GetState, Dispatch } from 'src/types';

import Header from './Header/Header';
import MasterLayout from '../shared/MasterLayout/MasterLayout';

type Props = {
  children: React.Node,
  location: LocationShape,
  route?: {
    layout: {
      isMainContentContained?: boolean,
      isMainContentFlexContainer?: boolean,
      isMainContentVerticallyCentered?: boolean,
      removeSpacingBetweenHeaderAndMainContent?: MasterLayoutSpacingBetweenHeaderAndMainContent,
      withFooter?: boolean,
      withNav?: boolean,
      withSecondaryBackgroundColor?: boolean,
      withUpgradeCta?: boolean,
      withUserDropdown?: boolean,
    },
  },
};

export const fetchData = (getState: GetState, dispatch: Dispatch) => {
  const promises = [];
  const state = getState();

  if (isPaidSchoolPlan(state) && !isPlansLoaded(state)) {
    promises.push(dispatch(loadPlans()));
  }

  // loadCurrentSubscriptionUsers is required for parents to show the global inactive child alert
  if (isLoggedInAsParent(state) && !isAllUsersLoaded(state)) {
    promises.push(dispatch(loadCurrentSubscriptionUsers()));
  }

  return Promise.all(promises);
};

export default
@connectData(fetchData)
class AuthenticatedLayout extends React.Component<Props> {
  render() {
    let routeProps = get((this.props.children: $FlowSuppressAny), 'props.route.layout', {});

    if (this.props.location.query?.isEmbed) {
      routeProps = {
        ...routeProps,
        withMobileBackLink: false,
        withNav: false,
        withUserDropdown: false,
        isEmbed: true,
        withFooter: false,
      };
    }

    const headerProps = [
      'withMobileBackLink',
      'withNav',
      'withTrialExpiry',
      'withUpgradeCta',
      'withUserDropdown',
      'isEmbed',
    ];

    return (
      <NavigationContextProvider>
        <MasterLayout header={<Header {...pick(routeProps, headerProps)} />} {...omit(routeProps, headerProps)}>
          {this.props.children}
        </MasterLayout>
      </NavigationContextProvider>
    );
  }
}
