// @flow
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Container, Flex, HideVisually, Skeleton, Table } from '@getatomi/neon';
import invariant from 'invariant';

import type { ReduxState, UserGroupType } from 'src/types';
import { isAdminOrAbove, isFreePlan as isFreePlanSelector } from 'src/reducers/subscriptions';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import { generatePlaceholderRows, generatePlaceholderColumns } from 'src/utils/generateTableLoadingData';

import UsersTableWithActions from './UsersTableWithActions/UsersTableWithActions';
import useGetClassUsers from './useGetClassUsers';

type Props = {
  params: {
    classId: string,
    subscriptionId: string,
    userType: UserGroupType,
  },
};

type InjectedProps = Props & {
  canManageUsers: boolean,
  isFreePlan: boolean,
};

const mapStateToProps = (state: ReduxState) => {
  return {
    canManageUsers: isAdminOrAbove(state),
    isFreePlan: isFreePlanSelector(state),
  };
};

function ClassUsers(props: InjectedProps) {
  const {
    canManageUsers,
    isFreePlan,
    params: { classId, subscriptionId, userType },
  } = props;

  const { data, error, loading } = useGetClassUsers({
    classId,
    subscriptionId,
    userType,
  });

  if (loading) {
    return (
      <>
        <Helmet title={_.upperFirst(userType)} />
        <Container>
          <Flex
            alignItems={{ base: 'center', tablet: 'baseline' }}
            justifyContent="space-between"
            marginBottom={{ base: 'spacingSmall', tablet: 'spacingLarge5X' }}
            gap="spacingLarge3X"
          >
            <Skeleton height={33} width={350} />
            <Skeleton height={37} width={104} />
          </Flex>

          <HideVisually>Loading the class {userType} table.</HideVisually>
          <Table
            isLoading
            columns={generatePlaceholderColumns({
              columnCount: 4,
            })}
            data={generatePlaceholderRows(5)}
          >
            <colgroup>
              <col key="select" width="50" />
            </colgroup>
          </Table>
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Helmet title={_.upperFirst(userType)} />
        <GraphQLError error={error} description={`We couldn’t load the class ${userType}.`} />
      </>
    );
  }

  invariant(data, 'Class name and users should be defined');

  return (
    <>
      <Helmet title={_.upperFirst(userType)} />
      <UsersTableWithActions
        key={userType}
        canManageUsers={canManageUsers}
        classId={classId}
        className={data.className}
        isSetupMode={data.isSetupMode}
        subscriptionId={subscriptionId}
        users={data.users}
        userType={userType}
        isFreePlan={isFreePlan}
      />
    </>
  );
}

export default (connect(mapStateToProps)(ClassUsers): React.AbstractComponent<Props>);
